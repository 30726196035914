/**
 * BaseField
 *  name : name field
 *  label: title
 *  value: value
 * "visibleOn": {
 *  "customer_type as field.name": [ 2  as field.value]
 *  }
 * */


interface BaseField {
    name: string;
    value: string;
    label: string;
    required?: boolean;
    visibleOn?: {
        [key: string]: number | string[]
    };
}


export interface UserAddressTextField extends BaseField {
    type: "TextField";
    options: { type: "text" };
}


export interface UserAddressLookupField extends BaseField {
    type: "LookupField";
    options: {
        model: string,
        filters?: string[],
        option?: { [key: string]: any }
    };
}


export interface UserAddressDropdownField extends BaseField {
    type: "DropdownField";
    options: {
        option: {
            [key: string]: string;
        }
    }
}

// Юнион для всех типов полей
export type UserAddressFieldsType = UserAddressTextField | UserAddressDropdownField | UserAddressLookupField;
export type UserAddressFormType = UserAddressFieldConfig<UserAddressFieldsType>;
// Универсальный интерфейс для массива полей
export type UserAddressFieldConfig<T extends UserAddressFieldsType> = T[];

//User-Defined Type Guards
export const isAddressTextField = (field: UserAddressFieldsType): field is UserAddressTextField => field.type === "TextField";
export const isAddressDropdownField = (field: UserAddressFieldsType): field is UserAddressDropdownField => field.type === "DropdownField";
export const isAddressLookupField = (field: UserAddressFieldsType): field is UserAddressLookupField => field.type === "LookupField";


// Forms

// Base form for user address, used for both creation and editing
export interface UserAddressBaseForm {
    name: string; // Name of the address
    class: string;   // Class associated with the address
    plugin: string;   // Plugin associated with the address
    form: UserAddressFormType // Type of the form for address details
}

// Form for an existing user address with an ID and default status
export interface IUserAddress extends UserAddressBaseForm {
    id: number;  // Unique identifier for the address
    default: number  // Indicates whether the address is the default (1 for yes, 0 for no)
}


// Form for existing address delivery variants, containing user and plugin information
export interface IAddressDeliveriesVariants {
    order: number;
    // type: "AddressDeliveriesVariants";  // Type identifier for address delivery variants
    client: number;                      // ID of the user associated with the delivery variant
    plugin: {
        id: string;                    // Unique identifier for the plugin
        name: string;                  // Name of the plugin
        description: string;           // Description of the plugin functionality
        class: string;                 // Class or category of the plugin
        is_default: boolean;           //Flag for open method by default when not is clientAddress.default = 1
    };
    form: UserAddressFormType;         // The form structure for address details
    clientAddresses: IUserAddressDelivery[]; // List of user addresses for this delivery variant
    name: string;
    description: string;
}

// Form for an existing user address, extending it with short info (e.g., address as text)
export interface IUserAddressDelivery extends IUserAddress {
    asText: string;  // Short textual representation of the address
    order: number;
}


export type UserAddressFormFieldConfigType = UserAddressBaseForm | IUserAddress | IAddressDeliveriesVariants;

//User-Defined Type Guards

export const isUserAddressTypeGuards = (form: UserAddressFormFieldConfigType): form is IUserAddress=>{
    return typeof (form as IUserAddress)?.class === "string" && typeof (form as IUserAddress)?.plugin === "string";
}

export const isEditUserAddressFormGuards = (form: UserAddressFormFieldConfigType): form is IUserAddress => {
    return (form as IUserAddress)?.id !== undefined && (form as IUserAddress)?.default !== undefined;
};
export const isAddressDeliveryVariantsFormGuards = (form: UserAddressFormFieldConfigType): form is IAddressDeliveriesVariants => {
    return (form as IAddressDeliveriesVariants)?.client !== undefined && (form as IAddressDeliveriesVariants)?.order !== undefined;
};
export const isIUserAddressDeliveryItemGuards = (form: any): form is IUserAddressDelivery => {
    return (form as IUserAddressDelivery)?.asText !== undefined && (form as IUserAddressDelivery)?.order !== undefined;
};
