import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import Modal from "../BasicModal";
import {useLang} from "../../../context/langProvider";
import {modalToggleFrezErrorsProjectModalAc} from "../../../store/actions/modal-action";
import {getFrezErrorsProjectModal} from "../../../selectors/modal-selector";
import ErrorBoundary from "../../ErrorBoundary";
import FrezNotificationWrapper from "./components/FrezNotificationWrapper";
import {openFrezDetailWithErrorThunk} from "../../../store/thunks/operations-detailing-thunk";

const FrezErrorsModal = () => {
    const {getT} = useLang();
    const dispatch = useDispatch();

    const modal = useSelector(getFrezErrorsProjectModal);
    if (!modal.isOpen) return null;

    const onClose = () => {
        dispatch(modalToggleFrezErrorsProjectModalAc(false, null))
    }
    const onOpenOperationModalByPartId = (partId: number, code: string) => {
        console.log(partId, code);
        dispatch(openFrezDetailWithErrorThunk(partId, code))
    }

    return (
        <Modal title={getT("Фрезерование: ошибки и предупреждения")} open={modal.isOpen} key={'modal-alerts'}
               onClose={onClose}>
            <ErrorBoundary>
                <div className="modal-body">
                    <FrezNotificationWrapper
                        key={'frez-notification-wrapper'}
                        getT={getT}
                        notifications={modal.errors}
                        onOpenOperation={onOpenOperationModalByPartId}
                    />
                </div>
            </ErrorBoundary>
        </Modal>
    );
};

export default FrezErrorsModal;