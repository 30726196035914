import React, {useEffect, useMemo} from "react";
import {changeConfirmOrderModalValue, changeShopingCardModalValue} from "../../../store/reducers/ModalReducer";
import GoBack from "../../../Icons/Actions/GoBack";
import EditOrderTitle from "../EditOrderTitle/EditOrderTitle";
import {useDispatch, useSelector} from "react-redux";
import {confirmOrderDataThunk} from "../../../store/reducers/OrderReducer";
import GoBackArrow from "../../../Icons/Actions/GoBackArrow";
import {
    getProductionNecessary,
    getProductionValueOfKey,
    getVatAddedTaxShow,
    getVatAddPriceIfMoreThan
} from "../../../selectors/production-selectors";
import {useLang} from "../../../context/langProvider";
import {actionsCuttingChart} from "../../../store/reducers/CuttingChartReducer";
import ReactGA from "react-ga4";
import {gaEvents} from "../../../gaEvents";
import {getOrderPack} from "../../../selectors/order-selector";
import Form from "./Form";
import {getCurrencies} from "../../../selectors/common-selector";
import {getCalculateAmountAndVat, getCalculateVat} from "../../ShopingCard/shoping_card_helper";
import ManageDeliveryAddressesWidget
    from "../../../widgets/manageOrderDeliveryAddressesOrder/ManageDeliveryAddressesWidget";
import {confirmAddressDeliveryFieldSelector} from "../../../selectors/order-confirm-selector";
import {utilsTypeof} from "../../../helpers/utils-typeof";

const TYPE_PACKAGING_ID = 99;

const OrderForm = () => {
    const {getT} = useLang();
    const dispatch = useDispatch();


    const store_order = useSelector((state) => state.order);
    const order = store_order.order;
    const form = store_order.confirm;


    const fieldAddressDelivery = useSelector(confirmAddressDeliveryFieldSelector);

    const setting_production_rus_hide = useSelector(getProductionValueOfKey('production.rus_hide'))
    const setting_production_phone_code = useSelector(getProductionValueOfKey('react.phone_code_one'))
    const setting_production_necessary = useSelector(getProductionNecessary);

    // const stores = useSelector(getCommonsStores);
    // const setting_production_calc_confirm_only_one_phone = useSelector(getProductionCalcConfirmOnlyOnePhoneIsNeeded)
    // const setting_production_hide_point_sales_confirmation = useSelector(getProductionHidePointsOfSalesInConfirmation)

    const production_vat = useSelector(getVatAddPriceIfMoreThan)
    const production_vat_display_short = useSelector(getVatAddedTaxShow) && !!Number(production_vat);

    const currencies = useSelector(getCurrencies);
    const currency = store_order.order.currency;

    const handlers_edge_calculate = order?.calculate?.handlers_edge ?? [];
    const paints_edge_calculate = order?.calculate?.paints_edge ?? [];
    const faska_edge_calculate = order?.calculate?.faska_edge ?? [];
    const srez_edge_calculate = order?.calculate?.srez_edge ?? [];
    const round_edge_calculate = order?.calculate?.round_edge ?? [];

    const goods = store_order.order.calculate?.goods ?? [];
    const services = store_order.order.calculate?.service ?? [];
    const materials = store_order.order.calculate?.material?.filter(e => Number(e.count) > 0) ?? [];
    const extra = store_order.order.extra;
    const pack = store_order.pack;
    const packing = store_order.order.packing;
    const order_pack = useSelector(getOrderPack);
    const goods_service_added_part_firm = order.goods_service_added_part_firm
    const goods_service_added_order_firm = order.goods_service_added_order_firm
    const packSamo = (Number(setting_production_necessary) !== 1) ?
        pack['samo'] :
        pack['samo'].filter(p => p.service_id !== 'no');

    const currencyInfo = useMemo(() => {
        const current = currencies.find(e => Number(e.id) === Number(currency));
        return {
            code: current.name
        }
    }, [currency, currencies]);


    useEffect(() => {
        gaEvents.beginCheckout(order);
    }, [])



    const getTotal = () => (pack) => {
        let packingTotal = 0;
        const pack_find_type = pack?.length && Array.isArray(pack) ? pack?.find(item => item?.service_id === TYPE_PACKAGING_ID) : null;

        if (order_pack.summ_custom_pack && !isNaN(order_pack.summ_custom_pack) && pack_find_type) {
            packingTotal = order_pack.summ_custom_pack
        }
        if (!order_pack.summ_custom_pack && pack_find_type && (packing && Array.isArray(packing))) {
            let data_result_array = packing?.map((pack,) => {
                return pack?.packs?.reduce((acc, item) => {
                    return Number(acc) + Number(item.fullSumm)
                }, 0)
            });
            if (data_result_array?.length) {
                packingTotal = data_result_array?.reduce((acc, item) => Number(acc) + Number(item)).toFixed(2);
            }
        }

        const goodsTotal = goods.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.cost;
        }, 0).toFixed(2);
        const packTotal = form.pack.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.cost;
        }, 0).toFixed(2);

        const servicesTotal = services.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.price;
        }, 0).toFixed(2);

        const materialsTotal = materials.reduce((accumulator, currentValue) => {
            return accumulator + currentValue.cost;
        }, 0).toFixed(2);

        const extraTotal = extra.reduce((accumulator, currentValue) => {
            return accumulator + Number(currentValue.summ)
        }, 0).toFixed(2);

        const goodsPartsTotal = goods_service_added_part_firm && goods_service_added_part_firm.reduce((accumulator, currentValue) => {
            let value = `${Number(currentValue.count) * Number(currentValue.price)}`
            return accumulator + parseFloat(value.replace(',', '.'));
        }, 0).toFixed(2);
        const goodsOrderTotal = goods_service_added_order_firm && goods_service_added_order_firm.reduce((accumulator, currentValue) => {
            let value = `${Number(currentValue.count) * Number(currentValue.price)}`
            return accumulator + parseFloat(value.replace(',', '.'));
        }, 0).toFixed(2);
        return Number(Number(faska_edge_calculate?.summ ?? 0) + Number(srez_edge_calculate?.summ ?? 0) + Number(round_edge_calculate?.summ ?? 0) + Number(paints_edge_calculate?.summ ?? 0) + Number(handlers_edge_calculate?.summ ?? 0) + Number(packingTotal) + Number(goodsPartsTotal) + Number(goodsOrderTotal) + Number(goodsTotal) + Number(servicesTotal) + Number(materialsTotal) + Number(extraTotal) + Number(packTotal)).toFixed(2);

    };

    const handlerClose = () => {
        dispatch(changeShopingCardModalValue("isOpen", true));
        dispatch(changeConfirmOrderModalValue("isOpen", false));
        dispatch(actionsCuttingChart.onToggleCuttingPage(false));
        // dispatch(clearALLFieldsCalculateValsDataAC());
    }

    const isInvalidDeliveryAddressField = useMemo(() => {
        const hasError = utilsTypeof.isObject(fieldAddressDelivery.externalErrors) && Object.keys(fieldAddressDelivery?.externalErrors ?? {}).length > 0;
        const isEmpty = !utilsTypeof.isObject(fieldAddressDelivery.valueForm) || Object.keys(fieldAddressDelivery?.valueForm ?? {}).length === 0;

        return isEmpty || hasError; // true, если поле невалидное
    }, [fieldAddressDelivery])

    const handlerSubmit = (values) => {
        // проверка что адрес доствавки выбран и не содержет ошибки
        if (isInvalidDeliveryAddressField) {
            return false
        }
        let total_value = getTotal()(values.pack);
        let total_vat = getCalculateVat(total_value, production_vat);
        let total = !!production_vat ? getCalculateAmountAndVat(total_value, total_vat) : total_value;

        // console.log(fieldAddressDelivery.valueForm)
        dispatch(confirmOrderDataThunk(total, values, fieldAddressDelivery.valueForm));
        ReactGA.gtag('event', 'form_end', {
            form_name: 'order form'
        })
    }
    return <div className="ordering-wrapper">
        <div className="ordering-header">
            <div onClick={handlerClose}
                 className="tab-goback">
                <GoBackArrow/>
            </div>
            <div onClick={handlerClose} className="goback">
                <GoBack/> <span className="goback-text">{getT('Назад')}</span>
            </div>
            <EditOrderTitle/>
        </div>

        <Form
            // stores={stores}
              pack={pack}
              packSamo={packSamo}
              isInvalidDeliveryAddressField={isInvalidDeliveryAddressField}
              getT={getT} initialForm={form}
              setting_phone_code_country={setting_production_phone_code}
              setting_hide_rus={Number(setting_production_rus_hide)}
              getTotal={getTotal()}
              currency_code={currencyInfo.code}
              handlerSubmit={handlerSubmit}
              handlerConfirmPack
              // setting_production_calc_confirm_only_one_phone={setting_production_calc_confirm_only_one_phone}
              // setting_production_hide_point_sales_confirmation={setting_production_hide_point_sales_confirmation}
              production_vat={production_vat}
              production_vat_display_short={production_vat_display_short}
              addressDeliverySlot={<ManageDeliveryAddressesWidget/>}
              deliveryPluginType={fieldAddressDelivery?.valueForm?.plugin ?? null}
        />

    </div>;
};


export default OrderForm;