export const getOpeningDetailsFromPartDT = (state)=> state.operations.openingDetailsFromPart || {partId: null, goodsId: null};

export const getOperationPazForm = state => state.operations.paz
export const getOperationFrezNewgo = state => state.operations.frezNewgo

export const getOperationSide = state => state.operations.operationSide;
export const getOperationsEntrySide = state => state?.operations?.operationSides || null;
export const getOperationsSelected = state => state?.operations?.operationsSelected || {}
export const getOperationsFilter = state => state?.operations?.operationsFilter || {}
export const getOperationsPartsError = state => state?.operations?.parts_error || null
export const getOperationsTools = state => state?.operations?.tools || null



