import React from 'react';
import Modal from "../../../components/Modals/BasicModal";
import UserAddressForm from "../form/UserAddressForm";
import {useDispatch, useSelector} from "react-redux";
import {userAddressUpdateThunk} from "../../../store/thunks/userAddresses/case-lk-command-thunk";
import {IUserAddressesFormSendRequestResult} from "../../../types/UserAddressesForms/UserAddressesInputOutput";
import {userAddressesEditModalSelector} from "../../../selectors/user-addresses-selector";
import ErrorBoundary from "../../../components/ErrorBoundary";
import {editUserAddressModalActions} from "../../../store/actions/user-address-action";
import {useLang} from "../../../context/langProvider";

const UserAddressEditModal = () => {
    const {getT} = useLang()
    const modal = useSelector(userAddressesEditModalSelector);
    const dispatch = useDispatch();
    const sendRequest = async (values: any): Promise<IUserAddressesFormSendRequestResult> => {
        //@ts-ignore
        return await dispatch(userAddressUpdateThunk(values));
    }
    const onClose = () => dispatch(editUserAddressModalActions(null, false));
    if (!modal.isOpen) {
        return null
    }
    return (
        <Modal open={modal.isOpen} onClose={onClose} title={getT("Редактирование адреса")}>
            <ErrorBoundary>
                <div className="modal-body">
                    {modal.deliveryForm && <UserAddressForm typeDeliveryForm={modal.deliveryForm} sendRequest={sendRequest}/>}
                </div>
            </ErrorBoundary>
        </Modal>
    );
};

export default UserAddressEditModal;