import Button from "../../components/ui/Button";
import React, {useEffect, useRef, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {userAddressesGetFormsSelector} from "../../selectors/user-addresses-selector";
import {UserAddressBaseForm} from "../../types/UserAddressesForms/FormFieldTypes";
import UserAddressForm from "../../features/userAddresses/form/UserAddressForm";
import {userAddressCreateThunk, userAddressesGetForms} from "../../store/thunks/userAddresses/case-lk-command-thunk";
import TabBase from "../../shared/ui/tab/TabBase";
import {IUserAddressesFormSendRequestResult} from "../../types/UserAddressesForms/UserAddressesInputOutput";
import {AppDispatch} from "../../store/store";
import {useLang} from "../../context/langProvider";

function UserAddressCreateWidget() {
    const {getT} = useLang()
    const dispatch = useDispatch<AppDispatch>();
    const ref = useRef<HTMLDivElement>(null);
    const [isCreate, setToggleCreate] = useState<boolean>(false)
    const forms = useSelector(userAddressesGetFormsSelector);
    const [choiceForm, setChoiceForm] = useState<UserAddressBaseForm | null>(null);
    // useOnClickOutside(ref, () => setToggleCreate(false));
    useEffect(() => {
        dispatch(userAddressesGetForms());
    }, []);

    if (!Array.isArray(forms)) {
        return null
    }
    const onSendCreateAddress = async (values: any): Promise<IUserAddressesFormSendRequestResult> => {
        return await dispatch(userAddressCreateThunk(values))
    }

    return (
        <div className={"settings-section w-100 bg-white"} ref={ref}>
            <div className="d-flex justify-between items-center">
                <h2 className="">{getT("Адрес доставки")}</h2>
                <div className="">
                    {isCreate ?
                        <Button theme={"light"} onClick={() => setToggleCreate(false)}>
                            <svg width={"24"} height={"24"} viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path
                                        d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
                                        fill="#0F0F0F"></path>
                                </g>
                            </svg>
                        </Button>
                        :
                        <Button theme={"success"} onClick={() => setToggleCreate(true)}>{getT("Создать")} +</Button>
                    }
                </div>
            </div>
            {isCreate &&
                <TabBase>
                    <div className="">
                        <h3>{getT("Тип доставки")}</h3>

                        <TabBase.TabPanel<UserAddressBaseForm>
                            data={forms}
                            renderItem={(item: UserAddressBaseForm, index) => {
                                const isActive = (choiceForm?.plugin ?? null) === item.plugin;
                                return <TabBase.TabPanelItem onClick={() => setChoiceForm(item)} isActive={isActive}
                                                             key={item.plugin}>
                                    {item.name}
                                </TabBase.TabPanelItem>
                            }}
                        />
                    </div>
                    {choiceForm && <TabBase.TabWrapItem>
                        <>
                            <h2>{choiceForm.name}</h2>
                            <UserAddressForm typeDeliveryForm={choiceForm} key={choiceForm.plugin}
                                             sendRequest={onSendCreateAddress}/>
                        </>

                    </TabBase.TabWrapItem>}
                </TabBase>
            }

        </div>
    )
}

export default UserAddressCreateWidget;