import ApiService from "../../api/api";
import {GiblabApi} from "../../api/Giblab/GiblabApi";
import {changeLoaderStatusAC} from "../reducers/CommonReducer";
import {dispatcherErrorThunk} from "./common-thunk";

const apiService = new ApiService();
export const downloadIfpProjectGiblabThunk = (
    version: string, code: string, client: string
) => async (dispatch: any) => {
    try {
        dispatch(changeLoaderStatusAC(true));
        const order = await apiService.getProject(version, code, client);
        if (apiService._errors) {
            dispatch(dispatcherErrorThunk({error: apiService._getErrors()}));
            return;
        }
        const ifpProject = await GiblabApi.getIfpProject(order);
        if (!!ifpProject?.error) {
            dispatch(dispatcherErrorThunk({error: ifpProject.error}));
            return;
        }
        setTimeout(() => {
            window.open(ifpProject?.response?.link, '_blank')
        }, 0);
        dispatch(changeLoaderStatusAC(false));
    } catch (error) {
    }
}