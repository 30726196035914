// initialValuesStrategies.ts

// Стратегия для редактирования существующего адреса
import {
    isAddressDeliveryVariantsFormGuards,
    isEditUserAddressFormGuards,
    isUserAddressTypeGuards,
    UserAddressFormFieldConfigType
} from "../../types/UserAddressesForms/FormFieldTypes";
import {utilsTypeof} from "../../helpers/utils-typeof";


interface ISelectInitialValuesUserAddressesDeliveryStrategy {
    [key: string]: string | number
}

// Основная функция для выбора стратегии
export const selectInitialValuesUserAddressesDeliveryStrategy = (typeDeliveryForm: UserAddressFormFieldConfigType): ISelectInitialValuesUserAddressesDeliveryStrategy => {
    if (!utilsTypeof.isArray(typeDeliveryForm.form)) return {};

    // Формируем объект начальных значений на основе полей формы
    let values = typeDeliveryForm.form.reduce((acc, field) => {
        return {
            ...acc, [field.name]: field.value || "",
        }
    }, {});
    // Если форма базовая
    if (isUserAddressTypeGuards(typeDeliveryForm)) {
        values = {
            ...values,
            form: typeDeliveryForm.class,
            plugin: typeDeliveryForm.plugin,
        }
    }
    // Если форма для редактирования существующего адреса, добавляем дополнительные поля
    if (isEditUserAddressFormGuards(typeDeliveryForm)) {
        values = {
            ...values, id: typeDeliveryForm.id, default: typeDeliveryForm.default
        }
    }
    // Этот блок кода добавляет флаг saveAddress, который определяет, нужно ли сохранять новый адрес доставки.
    // Флаг инициализируется значением 0 и не связан с API. Он используется для фронтенд-логики, чтобы контролировать,
    // будет ли адрес отправлен на сохранение, если пользователь решит сохранить его для будущих заказов.
    if (isAddressDeliveryVariantsFormGuards(typeDeliveryForm)) {
        values = {
            ...values,
            saveAddress: 0,
            form: typeDeliveryForm.plugin.class,
            plugin: typeDeliveryForm.plugin.id

        }
    }

    // Возвращаем начальные значения, если не попали в специфичные условия
    return values;
};