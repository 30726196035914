// if (+part?.operations?.frez?.length !== +part?.operations_inputs?.frez?.length) {
//     let operationsInputsNew = [];
//     let partOperationsFrez = part?.operations?.frez?.map((frez, index) => {
//         let findInputs = part?.operations_inputs?.frez?.find(input => Number(input?.id) === Number(frez?.id)) ?? {};
//         // if(findInputs){
//         findInputs.id = index + 1;
//         let inputs = {
//             db_id: findInputs?.db_id ?? frez?.db_id ?? null,
//             side: findInputs?.side ?? frez?.side ?? null,
//             ...findInputs
//
//         };
//         operationsInputsNew.push(inputs);
//         return {
//             ...frez,
//             id: findInputs.id, inputs: inputs
//         };
//         // }
//         // return frez;
//
//     });
// }


import {initialProcessingEdgeEditProjectForPart} from "../../External/helpers_processing_edge_api";
import {getUiIdForPart} from "../../../helpers/part/partHelpers";
import {utilsTypeof} from "../../../helpers/utils-typeof";


/**
 *  part {object} - деталь
 *  bands {array} - кромки
 *  productionConstants {object} - настройки
 *  processingEdgePoint {object || undefined} - обработка торцов
 *
 * return {part} - Обновленная деталь
 * */
export const mappingPartialPart = (partItem, bands, productionConstants, processingEdgePoint) => {
    let part = {...partItem};
    const isOnXncZenk = productionConstants.hasOwnProperty('production.xnc_bore_zenk_on') ? Number(productionConstants['production.xnc_bore_zenk_on']) : 0

    // -------------------srez----------------------
    if (!part.srez) {
        part.srez = {};
        ['t', 'b', 'l', 'r'].forEach((sid) => {
            part.srez[sid] = {
                otstup: null,
                ugol: null,
                db_id: null
            };
        })
    }

    // ---------------edge--------------------------

    if (typeof part?.edge === "object") {
        for (const side in part.edge) {
            const edge = {...part.edge[side]}; // Создание копии объекта edge
            const db_id = Number(edge.db_id);
            if (db_id === -1) {
                part.srez[side].ugol = edge.radius;
                edge.db_id = null;
                edge.cut = null;
                edge.radius = null;
            } else {
                const bandById = bands?.find(b => b?.goods_id === edge.db_id);
                edge.color = bandById ? bandById?.color : null;
            }

            part.edge[side] = edge; // Присваивание обновленного объекта обратно
        }
    }

    // ---------------operations------------------

    if (Array.isArray(part?.operations?.bore)) {
        part.operations.bore = part.operations.bore
            .filter(utilsTypeof.isObject)
            .map(item => {
                if (!item.hasOwnProperty("start_point")) {
                    item["start_point"] = "ld";
                }
                if (['f', 'bb'].includes(item.side) && item.hasOwnProperty('zenkovanie') && !isOnXncZenk) {
                    item['zenkovanie'] = 'no'
                }
                return item;
            })

    } else {
        part.operations.bore = []
    }


    const operationsKeys = ["hem", "cut_to", "frez", "trough", "paz"];
    operationsKeys.forEach(key => {
        part.operations[key] = Array.isArray(part?.operations?.[key])
            ? part.operations[key].filter(utilsTypeof.isObject)
            : [];
    });

    // --------------- Проверка operations_inputs.frez ---------------

    if (!Array.isArray(part.operations_inputs.frez)) {
        part.operations_inputs.frez = [];
    }

    if (part.operations.frez.length !== part.operations_inputs.frez.length) {
        let operationsInputsNew = [];

        part.operations.frez = part.operations.frez.map((frez, index) => {
            const findInputs = part.operations_inputs.frez.find(input => Number(input?.id) === Number(frez?.id)) ?? {};

            const newId = index + 1;
            let inputs = {
                db_id: findInputs?.db_id ?? frez?.db_id ?? null,
                side: findInputs?.side ?? frez?.side ?? null,
                ...findInputs,
                id: newId
            };

            operationsInputsNew.push(inputs);

            return {
                ...frez,
                id: newId,
                inputs
            };
        });
        part.operations_inputs.frez = operationsInputsNew;
    }

    //-------------------- initial processing egde -------------------------------

    const payload_edge_edit = initialProcessingEdgeEditProjectForPart(part, productionConstants, processingEdgePoint)


    //------------------------uniq part ------------------------------------------
    let uuid = {};
    if (!part?.hasOwnProperty('uuid') || !(typeof part?.uuid === "string")) {
        uuid = getUiIdForPart()
    }

    return {
        ...part,
        ...payload_edge_edit,
        ...uuid
    };

}