import {
    FREZ_MOVE_ADD_MODAL,
    FREZ_MOVE_EDIT_MODAL,
    FREZ_MOVE_TYPES_MODAL,
    FREZ_OPERATION_CREATE_MODAL
} from "../../actions/extended-frez-operation-actions";
import {EditOperationFrezMoveModalType, FrezMoveAddModalT, FrezMoveTypesModalT} from "./interfaceExtendedFrezStore";

export type ExtendFrezOperationsStoreType = {
    createOperationFrezModal: {
        isOpen: boolean
    },
    editFrezMoveModal: EditOperationFrezMoveModalType,
    frezMoveTypesModal: FrezMoveTypesModalT,
    addFrezMoveModal: FrezMoveAddModalT
};

const INITIAL_STATE: ExtendFrezOperationsStoreType = {
    createOperationFrezModal: {
        isOpen: false,
    },
    editFrezMoveModal: {
        type: null,
        frez: null,
        indexFrezEdit: null,
        isOpen: false,
    },
    frezMoveTypesModal: {
        frez: null,
        isOpen: false,
        insertionIndex: null,
    },
    addFrezMoveModal: {
        frez: null,
        isOpen: false,
        type: null,
        initialPrevForm: null,
        insertionIndex: null,
    }
}
// export type ExtendFrezOperationsType = typeof INITIAL_STATE;


const ExtendedFrezOperationsReducer = (state: ExtendFrezOperationsStoreType = INITIAL_STATE, action: any) => {

    switch (action.type) {
        case FREZ_MOVE_ADD_MODAL: {
            return {
                ...state,
                addFrezMoveModal: {
                    ...action.payload,
                }
            }
        }
        case FREZ_MOVE_TYPES_MODAL: {
            return {
                ...state,
                frezMoveTypesModal: {
                    ...action.payload
                }
            }
        }
        case FREZ_MOVE_EDIT_MODAL: {
            return {
                ...state,
                editFrezMoveModal: {
                    ...state.editFrezMoveModal,
                    ...action.payload,
                }
            }
        }
        case FREZ_OPERATION_CREATE_MODAL: {
            return {
                ...state,
                createOperationFrezModal: {
                    isOpen: action.payload.isOpen,
                }
            }
        }
        default: {
            return state
        }
    }
}

export default ExtendedFrezOperationsReducer;