import React from 'react';
import AlertsModal from "./AlertsModal";
import CheckProjectModal from "../CheckProjectModal/CheckProjectModal";
import FrezErrorsModal from "./FrezErrorsModal";
import {useSelector} from "react-redux";
import {getAlertsProjectModal, getFrezErrorsProjectModal} from "../../../selectors/modal-selector";
import LockWindow from "../LockWindow";

const ModalsProjectManager = () => {
    const frezModal = useSelector(getFrezErrorsProjectModal);
    const alertsModal = useSelector(getAlertsProjectModal);
    return (
        <>
            <CheckProjectModal key={'CheckProjectModal'}/>
            {alertsModal?.isOpen && <AlertsModal key="alertsModal"/>}
            {frezModal?.isOpen && <FrezErrorsModal key="frezErrorsModal"/>}
            <LockWindow key="lockWindow"/>
        </>
    );
};

export default ModalsProjectManager;