import {instances} from "../api_axios_settings";
import {URI_API_CHECKOUT} from "../uri_api_action";
import {rejectTransform, resolveTransform} from "../utils_api";
import {IAddressDeliveriesVariants} from "../../types/UserAddressesForms/FormFieldTypes";

interface IRes {

    error?: any,
    variants?: IAddressDeliveriesVariants[]

}

export const CheckoutApi = {
    getCheckoutDeliveryFormByOrderId: async (orderId: string, clientId:string): Promise<IRes> => {
        const url = URI_API_CHECKOUT + '/form?order=' + orderId + '&client_id=' + clientId;
        return instances.get(url)
            .then((response) => resolveTransform(response))
            .catch((error) => rejectTransform(error));
    }
}