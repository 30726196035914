import React, {useState} from 'react';
import TabBase from "../../shared/ui/tab/TabBase";
import {IAddressDeliveriesVariants} from "../../types/UserAddressesForms/FormFieldTypes";
import ErrorBoundary from "../../components/ErrorBoundary";
import UserAddressForm from "../userAddresses/form/UserAddressForm";
import {useLang} from "../../context/langProvider";
import {
    IExternalErrorAddress,
    IUserAddressesFormSendRequestResult
} from "../../types/UserAddressesForms/UserAddressesInputOutput";

interface Props {
    deliveryVariants: IAddressDeliveriesVariants;
    validateAndAddAddressRequest: (values: any) => Promise<IUserAddressesFormSendRequestResult>
    externalErrors: IExternalErrorAddress | undefined
    defaultIndexForm: number
}

const OrderAddressVariantCreate = ({
                                       deliveryVariants,
                                       externalErrors,
                                       validateAndAddAddressRequest,
                                       defaultIndexForm = 0
                                   }: Props) => {
    const {getT} = useLang()
    const [indexDelivery, setIndexDelivery] = useState<number>(defaultIndexForm);
    const typeDeliveryForm = deliveryVariants && Array.isArray(deliveryVariants)
        ? deliveryVariants.find((_: any, index: number) => index === indexDelivery) : null;
    if (!Array.isArray(deliveryVariants)) {
        return <p className="">You don't have delivery types</p>
    }
    return (
        <TabBase>
            <div className="">
                <h3>{getT("Тип доставки")}</h3>
                <TabBase.TabPanel<IAddressDeliveriesVariants>
                    data={deliveryVariants}
                    renderItem={(item, index) => {
                        const isActive = indexDelivery === index;
                        return <TabBase.TabPanelItem onClick={() => setIndexDelivery(index)}
                                                     isActive={isActive}
                                                     key={item.plugin.id}>
                            {item.name}
                        </TabBase.TabPanelItem>
                    }}
                />
            </div>
            <ErrorBoundary>
                {typeDeliveryForm && <TabBase.TabWrapItem>
                    <>
                        <h2>{typeDeliveryForm.name}</h2>
                        <UserAddressForm typeDeliveryForm={typeDeliveryForm}
                                         key={typeDeliveryForm.plugin.id}
                                         externalErrors={externalErrors}
                                         sendRequest={validateAndAddAddressRequest}/>
                    </>

                </TabBase.TabWrapItem>}
            </ErrorBoundary>
        </TabBase>
    );
};

export default OrderAddressVariantCreate;