import React from 'react';
import "./paz.loader.svg.css"
interface IPazSvgImage {
    src?: string | null;
    className?: string;
    width?: string;
    isScaleHover?: boolean;
}

const PazSvgImage: React.FC<IPazSvgImage> = ({src, width, className, isScaleHover=false}) => {
    if (!src) return null;
    const widthP = width ?? '100px';
    const classNameWrap = `${className || ''} paz-svg-image-wrap ${isScaleHover ? 'paz-svg-scale-hover' : '' }`;
    return (
        <div className={classNameWrap}>
            <img width={widthP} src={`data:image/svg+xml;base64,${src}`}/>
        </div>
    );
};

export default PazSvgImage;