export const mappingPartialPlaneOperaion = (plane) => {
    return {
        ...plane,
        id: plane.id,
        add_to_detail: plane?.add_to_detail ?? null,
        name: plane?.name ?? "Название не указано",
        code_1c: plane?.code_1c ?? 0,
        layer_z: plane?.layer_z ?? 0,
        expense: plane?.expense ?? 0,
        unit: plane?.unit ?? 0,
        type: plane?.type ?? "",
        type_name: plane?.type_name ?? "Название не указано",
        image: plane?.image ?? "",
    }
}