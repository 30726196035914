import React from 'react';
import "./css/error-field-message.css"

const ErrorFieldMessage = ({children}) => {
    return (
        <div className={"error-field-message"}>
            {children}
        </div>
    );
};

export default ErrorFieldMessage;