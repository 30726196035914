import React from 'react';
import Modal from "../Modals/BasicModal";
import Container from "../Container";
import ImgTelegram from "../../images/messengers/telegram_logo.png";
import ImgYoutube from "../../images/messengers/youtube-logo.png";

const styleFlex = {
    display: 'flex',
    alignItems: 'center'
}
const TechnicalSupport = ({getT, isOpen, onClose, data}) => {
    if (!data) return null;
    return (
        <Modal title={getT("Техническая поддержка")} cName={'technical-modal'} open={isOpen} onClose={onClose} key={'technical-support'}>
            <Container>
                <div className="technical-wrap">
                    <p>{getT("Возникли вопросы по онлайн-сервису? Обращайтесь")}:</p>
                    <ul>
                        {data?.name && <li>{getT('Фирма')}: {data?.name}</li>}

                        {data?.tel && <li>{getT('Номер')}:<a href={`tel:${data?.tel}`}>{data?.tel}</a></li>}
                        {data?.email && <li>{getT('Почта')}:<a href={`mailto:${data?.email}`}>{data?.email}</a></li>}
                        <li style={styleFlex}><img style={{width: '20px', height: '20px', marginRight: '5px'}} src={ImgTelegram}/> Telegram: <a  href="https://t.me/ifurn_pro" target={'_blank'}>ifurn_pro</a></li>
                        <li style={styleFlex}><img style={{width: '25px', height: '25px', marginRight: '5px'}} src={ImgYoutube}/>  Youtube: <a  href="https://www.youtube.com/@ifurnpro" target={'_blank'}>@ifurnpro</a></li>
                    </ul>
                </div>

            </Container>

        </Modal>
    );
};

export default TechnicalSupport;