import React from 'react';
import "./css/button-custom.css";

interface IProps {
    type?: 'button' | 'submit';
    children?:any;
    title?: string;
    onClick?: () => void;
    theme?: "primary" | "secondary" | "success" | "warning" | "danger" | "light" ;
    size?: 'small' | 'medium' | 'large';
    className?: string;
}
const Button :React.FC<IProps>= ({type, size, theme, onClick, children, title, className}) => {
    const setting = {
        type: type ? type : 'button',
        theme: theme ? theme : 'primary',
        size: size ? size : 'medium',
        onClick: onClick ? onClick : ()=>{},
    }
    const classNameSetting = `btn-custom btn-custom-${setting.theme} btn-custom-${setting.size} ${className}`;
    return (
        <button type={type} className={classNameSetting} onClick={onClick} title={title}>
            {children ? children : null}
            {title? title: null}
        </button>
    );
};

export default Button;