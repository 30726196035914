import React from 'react';
import Danger from "../../../../Icons/Danger";
import IconWarning from "../../../../Icons/IconWarning";

interface NotificationBoxItemProps {
    title?: string;
    messages?: string[];
    alerts?: string[];
    errors?: string[];
    onClick?: () => void;
    header?: React.ReactNode;
}

const NotificationIBoxItem: React.FC<NotificationBoxItemProps> = ({
                                                                      title,
                                                                      messages,
                                                                      onClick,
                                                                      errors,
                                                                      alerts,
                                                                      header
                                                                  }) => {
    return (
        <div className={"notification-item"} onClick={onClick} style={{cursor: onClick ? "pointer" : 'default'}}>
            {title ? <h3>{title}</h3> : null}
            {header ? header : null}
            {!!(Array.isArray(messages) && messages?.length) &&
                <div className="wrap">
                    {messages?.map((message) => (<p style={{margin: "5px 0"}}>{message}</p>))}
                </div>
            }
            {!!(Array.isArray(alerts) && alerts?.length) &&
                <div className="wrap">
                    <IconWarning width={25} height={25}/>
                    {alerts?.map((message) => (<p style={{margin: "5px 0"}}>{message}</p>))}
                </div>
            }
            {!!(Array.isArray(errors) && errors?.length) &&
                <div className="wrap">
                    <Danger style={{transform: "translate(-15px,0)"}}/>
                    {errors?.map((message) => (<p style={{margin: "5px 0"}}>{message}</p>))}
                </div>
            }
        </div>

    )
};

export default NotificationIBoxItem;


// return (

// );