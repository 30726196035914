import React, {useEffect, useCallback, useRef} from "react";
import CloseModal from "../../../Icons/Actions/Close";
import "./Modal.css";
import GoBackArrow from "../../../Icons/Actions/GoBackArrow";

const Modal = ({open, onClose, children, title, widthProp = '', cName = ''}) => {
    const modalRef = useRef();
    const divRef = useRef();

    const closeModal = (e) => {
        if (modalRef.current === e.target) {
            onClose();
        }
    };
    useEffect(() => {
        const keyPress = (event) => {
            event.stopPropagation(); // ⛔ Останавливаем всплытие
            if (event.key === "Escape") {
                console.log("ESC нажата внутри div (событие не всплывает)");
                onClose();
            }
        };

        const element = divRef.current;
        if (element) {
            element.addEventListener("keydown", keyPress);
        }

        return () => {
            if (element) {
                element.removeEventListener("keydown", keyPress);
            }
        };
    }, []);
    // const keyPress = useCallback(
    //   (e) => {
    //     if (e.key === "Escape" && open) {
    //       onClose();
    //     }
    //   },
    //   [onClose, open]
    // );
    //
    // useEffect(() => {
    //   document.addEventListener("keydown", keyPress);
    //   return () => document.removeEventListener("keydown", keyPress);
    // }, [keyPress]);

    if (!open) {
        return null;
    }
    const styleModal = widthProp ? {width: widthProp} : {};

    return (
        <div className={`modal-bg ${cName}`} onClick={closeModal} ref={divRef} tabIndex={0}>
            <div className="modal-wrapper" style={styleModal}>
                <div className="modal-header">
                    <div className="goback-modal" onClick={onClose}>
                        <GoBackArrow/>
                    </div>
                    <h3>{title}</h3>
                    <span className="close-modal" onClick={onClose}>
            <CloseModal/>
          </span>
                </div>
                {children}
            </div>
        </div>
    );
};
export default Modal;
