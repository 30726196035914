import React from 'react';
import "./style.css"
const InputCheckbox = ({title = null, checked, name_field, onChange, id}) => {

    return (
        <div className="form-field form-check-field">
            <input type="checkbox" id={id} name={name_field} className={"form-check-input"}
                   checked={checked} onChange={onChange}/>
            <label className={"form-check-label"} htmlFor={id}> {title}  </label>
        </div>
    );
};

export default InputCheckbox;