import {changeLoaderStatusAC} from "../../reducers/CommonReducer";
import {dispatcherErrorThunk} from "../common-thunk";
import {FrezeMakeApi} from "../../../api/Frez/FrezeMakeApi";
import {operationSetFrezNewgoAction} from "../../actions/operation-actions";
import {FrezMoveItemTypeUnion, IFrezNewgoOperation} from "../../../types/ExtentdsFrezOperaions/FrezOperationsTypes";
import {changeOperationsModalValue} from "../../reducers/ModalReducer";
import {FREZ_NEWGO_ASIDE_TYPE} from "../../constats/extendFrezOperations/frezMoveSettings";
import {
    frezMoveAddModalAC,
    frezMoveEditModalAC,
    frezOperationCreateModalAC
} from "../../actions/extended-frez-operation-actions";
import {setPartWithSizesAC} from "../../reducers/OrderReducer";


type FrezCheckToErrorArgType = {
    part: any,
    frezToSend: IFrezNewgoOperation;
}
const frezeCheckErrors = async ({part, frezToSend}: FrezCheckToErrorArgType) => {
    const frezeCheckResult = await FrezeMakeApi.check(part, frezToSend);

    const frezMoveResultWithErrors = Array.isArray(frezeCheckResult.result?.frez_move) ? frezeCheckResult.result?.frez_move : frezToSend.frez_move;

    return {
        commonError: frezeCheckResult?.commonError,
        result: {
            frezUpdate: {...frezToSend, frez_move: frezMoveResultWithErrors},
            errors: frezeCheckResult.result?.error ?? null,
            messages: frezeCheckResult.result?.alerts ?? null,
        }
    }

}

interface IExtendFrezMoveOperationAdding {
    part: any,
    frezeAdd: FrezMoveItemTypeUnion,
    frez: IFrezNewgoOperation;
    insertionIndex: number | null;
}

export const extendFrezMoveCheckedAndAdding = ({
                                                   part,
                                                   frezeAdd,
                                                   frez, insertionIndex
                                               }: IExtendFrezMoveOperationAdding) => async (dispatch: any) => {
    try {
        dispatch(changeLoaderStatusAC(true));
        let frezToSend = {...frez};
        if (!Array.isArray(frezToSend.frez_move)) {
            frezToSend.frez_move = []
        }
        if (insertionIndex !== null) {
            frezToSend.frez_move.splice(insertionIndex, 0, frezeAdd);
        } else {
            frezToSend.frez_move.push(frezeAdd);
        }
        const result = await frezeCheckErrors({part, frezToSend});

        if (result.commonError) {
            dispatch(dispatcherErrorThunk({error: result.commonError}));
            return;
        }

        dispatch(operationSetFrezNewgoAction({
            frez: result.result.frezUpdate,
            errors: result.result.errors,
            messages: result.result.messages,
        }));
        dispatch(frezMoveAddModalAC({
            isOpen: false,
            frez: null,
            type: null,
            initialPrevForm: null,
            insertionIndex: null
        }));
        dispatch(changeLoaderStatusAC(false));

    } catch (error) {
        console.log(error)
        dispatch(dispatcherErrorThunk(error))
    }
}

interface IextendFrezMoveOperationEdit {
    part: any,
    frezeAdd: FrezMoveItemTypeUnion,
    indexFrezEdit: number;
    frez: IFrezNewgoOperation;
}


export const extendFrezMoveOperationEdit =
    ({part, frez, frezeAdd, indexFrezEdit}: IextendFrezMoveOperationEdit) => async (dispatch: any) => {
        try {
            dispatch(changeLoaderStatusAC(true));
            let frezToSend = {...frez};
            if (!Array.isArray(frezToSend.frez_move)) {
                frezToSend.frez_move = []
            }
            frezToSend.frez_move[indexFrezEdit] = frezeAdd;

            const result = await frezeCheckErrors({part, frezToSend});

            if (result.commonError) {
                dispatch(dispatcherErrorThunk({error: result.commonError}));
                return;
            }
            // dispatch(frezMoveEditModalAC({frez: result.result.frezUpdate}))
            dispatch(operationSetFrezNewgoAction({
                frez: result.result.frezUpdate,
                errors: result.result.errors,
                messages: result.result.messages,
            }));
            dispatch(frezMoveEditModalAC({isOpen: false, frez: null, type: null, indexFrezEdit: null}));
            dispatch(changeLoaderStatusAC(false));

        } catch (error) {
            console.log(error)
            dispatch(dispatcherErrorThunk(error))
        }
    }


type FextendSaveFrezOperationType = {
    part: any,
    frezeAdd: IFrezNewgoOperation,
}
export const extendSaveFrezOperation = ({
                                            part,
                                            frezeAdd
                                        }: FextendSaveFrezOperationType) => async (dispatch: any, getState: any) => {
    const order = getState().order.order;
    const bandsType = order.bands_type;
    const materials = order.material;
    const bands = order.band;
    const client = order?.client;
    try {
        dispatch(changeLoaderStatusAC(true));
        const result = await frezeCheckErrors({part, frezToSend: frezeAdd});

        if (result.commonError || result.result.errors) {
            if (result.commonError) {
                dispatch(dispatcherErrorThunk({error: result.commonError}))
                return;
            }

            dispatch(operationSetFrezNewgoAction({
                frez: result.result.frezUpdate,
                errors: result.result.errors,
                messages: result.result.messages,
            }));

            dispatch(changeLoaderStatusAC(false));
            return
        }


        let resultSave = await FrezeMakeApi.save({part, frezeAdd, bands, bandsType, client: client, materials});
        if (resultSave?.error) {
            dispatch(dispatcherErrorThunk(resultSave));
            return;
        }

        const targetCode = resultSave.code;
        const partUpdate = resultSave.part;
        const findOperationFrezByCode = partUpdate.operations.frez_newgo.find((item: {
            code: string
        }) => item.code === targetCode);

        dispatch(setPartWithSizesAC(partUpdate));
        dispatch(operationSetFrezNewgoAction({
            frez: findOperationFrezByCode,
            errors: result.result.errors,
            messages: result.result.messages,
        }));

        dispatch(changeLoaderStatusAC(false));
    } catch (error) {
        console.log(error)
        dispatch(dispatcherErrorThunk(error))
    }
}


/**
 *  Создать операцию в текущей детали frez_newgo
 *  поле code = null
 *  и добавить контур типа ms =  замкнутый контур. frez_move: []
 *  и отркыть операцию aside
 *
 * */
export const extendFrezNewgoOperationCreate = (frezNew: IFrezNewgoOperation) => async (dispatch: any, getState: any) => {
    try {

        dispatch(operationSetFrezNewgoAction({
            frez: frezNew,
            errors: null,
            messages: null
        }));
        dispatch(changeOperationsModalValue("aside", FREZ_NEWGO_ASIDE_TYPE));
        dispatch(frezOperationCreateModalAC(false))

    } catch (e) {
        console.log(e)
        dispatch(dispatcherErrorThunk(e))
    }
}

export const deleteFrezMoveOperationThunk = (part: any, frez: IFrezNewgoOperation, indexDeleteFrezMove: number) => async (dispatch: any, getState: any) => {
    try {
        dispatch(changeLoaderStatusAC(true));
        frez.frez_move.splice(indexDeleteFrezMove, 1);
        const result = await frezeCheckErrors({part, frezToSend: frez});

        if (result.commonError) {
            dispatch(dispatcherErrorThunk({error: result.commonError}));
            return
        }
        dispatch(operationSetFrezNewgoAction({
            frez: result.result.frezUpdate,
            errors: result.result.errors,
            messages: result.result.messages,
        }));


        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        console.log(e)
        dispatch(dispatcherErrorThunk(e))
    }
}

export const getExtendFrezeOperationsFoEditAsideThunk = (part: any, operation: any) => async (dispatch: any) => {
    try {
        dispatch(changeLoaderStatusAC(true));
        const result = await FrezeMakeApi.convert(operation);
        if (result.error) {
            dispatch(dispatcherErrorThunk(result.error))
            return;
        }
        const checkResult = await frezeCheckErrors({part, frezToSend: result});
        if (checkResult.commonError) {
            dispatch(dispatcherErrorThunk({error: checkResult.commonError}));
            return;
        }
        dispatch(operationSetFrezNewgoAction({
            frez: checkResult.result.frezUpdate,
            errors: checkResult.result.errors,
            messages: checkResult.result.messages
        }))
        dispatch(changeOperationsModalValue("aside", FREZ_NEWGO_ASIDE_TYPE));
        dispatch(changeLoaderStatusAC(false))
    } catch (e) {
        console.log(e)
        dispatch(dispatcherErrorThunk(e))
    }
}