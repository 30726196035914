import React, {useMemo} from 'react';
import {useLang} from "../../context/langProvider";
import {useDispatch, useSelector} from "react-redux";
import {orderDeliveryVariantsSelector} from "../../selectors/user-addresses-selector";
import {
    IExternalErrorAddress,
    IUserAddressesFormSendRequestResult
} from "../../types/UserAddressesForms/UserAddressesInputOutput";
import {IUserAddress, IUserAddressDelivery} from "../../types/UserAddressesForms/FormFieldTypes";
import UserAddressForm from "../../features/userAddresses/form/UserAddressForm";
import OrderAddressVariantCreate from "../../features/confirmAddressDelivery/OrderAddressVariantCreate";
import InputRadio from "../../components/InputFields/InputRadio";
import "./manage-delivery-address.css"
import {confirmAddressDeliveryFieldSelector} from "../../selectors/order-confirm-selector";
import {
    ICheckoutAddressDeliveryFieldDataAction,
    setCheckoutAddressDeliveryFieldDataAction
} from "../../store/actions/checkout-address-delivery-action";
import {utilsTypeof} from "../../helpers/utils-typeof";
import {
    addOrderDeliveryAddressThunk,
    selectOrderDeliveryAddressThunk,
    updateOrderDeliveryAddressThunk
} from "../../store/thunks/Checkout/case-checkout-address-delivery-thunl";
import IconCheckedTick from "../../shared/ui/icon/IconCheckedTick";


const ManageDeliveryAddressesWidget = () => {
    const {getT} = useLang();
    const dispatch = useDispatch();
    const deliveryVariants = useSelector(orderDeliveryVariantsSelector);
    const fieldDelivery = useSelector(confirmAddressDeliveryFieldSelector) as ICheckoutAddressDeliveryFieldDataAction;
    const onSelectAddressDeliveryType = async (type: "existing" | "new", form: IUserAddress | null) => {

        if (type === "existing" && form) {
            dispatch(selectOrderDeliveryAddressThunk(form));
            return;
        }
        if (type === "new") {
            dispatch(setCheckoutAddressDeliveryFieldDataAction({
                valueForm: null,
                formId: "new",
                externalErrors: null
            }))
        }

    };

// метод для добавление нового адресса
    const validateAndAddAddressRequest = async (values: {
        [key: string]: string,
        saveAddress: string
    }): Promise<IUserAddressesFormSendRequestResult> => {
        await dispatch(addOrderDeliveryAddressThunk(values));
        return new Promise<IUserAddressesFormSendRequestResult>((resolve, reject) => {
            return {
                errors: null,
                isOk: false
            }
        })
    }

    //метод для обновление адреса который выбран и содеждит ошибку
    const onRequestInvalidAddress = async (values: any): Promise<IUserAddressesFormSendRequestResult> => {
        await dispatch(updateOrderDeliveryAddressThunk(values));
        return new Promise<IUserAddressesFormSendRequestResult>((resolve, reject) => {
            return {
                errors: null,
                isOk: false
            }
        })
    }
    const isInvalidDeliveryAddressField = useMemo(() => {
        const hasError = utilsTypeof.isObject(fieldDelivery.externalErrors) && Object.keys(fieldDelivery?.externalErrors ?? {}).length > 0;
        const isEmpty = !utilsTypeof.isObject(fieldDelivery.valueForm) || Object.keys(fieldDelivery?.valueForm ?? {}).length === 0;
        return isEmpty || hasError || !fieldDelivery.formId; // true, если поле невалидное
    }, [fieldDelivery]);

    return (
        <div onClick={(e) => {
            e.stopPropagation()
        }}
             className={"confirm-address-"}
        >
            <label className={"label"}>{getT("Метод доставки")}</label>

            {Array.isArray(deliveryVariants) && (<>
                    {
                        deliveryVariants.map((item, index) => {
                            return (item.clientAddresses.map((address: IUserAddressDelivery) => {
                                const label = `${item.plugin.description} ${address.asText}`
                                const checked = Number(fieldDelivery.formId) === Number(address.id);
                                return <div className="delivery-address-variant-item">
                                    <div className="delivery-address-variant__header">
                                        <InputRadio label={label} key={address.id} value={address.id}
                                                    onChange={
                                                        (event: any) => {
                                                            onSelectAddressDeliveryType("existing", address)
                                                        }
                                                    }
                                                    checked={checked}
                                                    className=""
                                                    name="addresses-variant"
                                                    id={`adr-${address.id}`}

                                        />
                                        {checked ? (isInvalidDeliveryAddressField ?
                                            <IconCheckedTick stroke={"#ff0000"} fill={"#ff0000"}/> :
                                            <IconCheckedTick/>) : null}
                                    </div>
                                    {!!(checked && utilsTypeof.isObject(fieldDelivery.externalErrors)) &&
                                        <div className="delivery-address-variant__content">
                                            <UserAddressForm
                                                externalErrors={fieldDelivery.externalErrors as IExternalErrorAddress}
                                                typeDeliveryForm={address}
                                                key={address.id}
                                                sendRequest={onRequestInvalidAddress}
                                            />
                                        </div>
                                    }
                                </div>

                            }))
                        })
                    }
                    <div className="delivery-address-variant-item">
                        <div className="delivery-address-variant__header">
                            <InputRadio label={getT("Добавить метод доставки")} key={'anotherAddress'}
                                        onChange={() => onSelectAddressDeliveryType("new", null)}
                                        value={""}
                                        checked={fieldDelivery.formId === "new"}
                                        className=""
                                        id={"addresses-variant-new"}

                            />
                            {fieldDelivery.formId === "new" ? (isInvalidDeliveryAddressField ?
                                <IconCheckedTick stroke={"#ff0000"} fill={"#ff0000"}/> : <IconCheckedTick/>) : null}
                        </div>
                        {(fieldDelivery.formId === "new") && <div className="delivery-address-variant__content">
                            <OrderAddressVariantCreate deliveryVariants={deliveryVariants}
                                                       defaultIndexForm={fieldDelivery?.defaultIndexForm ?? 0}
                                                       externalErrors={fieldDelivery?.externalErrors ?? {}}
                                                       validateAndAddAddressRequest={validateAndAddAddressRequest}/>
                        </div>}
                    </div>


                </>
            )}
        </div>
    );
};

export default ManageDeliveryAddressesWidget;