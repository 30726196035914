export const GLASS_ID_TYPE_MATERIAL = 124;

// немецкий de, словацкий sk, латвийский lv, литовский lt, эстонский et,
export const languagesList = ["ru", "en", "ua", "pl", "ro", "he", "de", "sk", "lv", "lt", "et"];

export const maskPhoneCountry = {
    "+38 ": "(999) 999-99-99",
    "+972 ": "(999) 999-99-99",
    "+373 ": "9999-9999",
    "+40 ": "-99-999-9999",
    "+359 ": "(999) 999-999",
    "+48 ": "(999) 999-999",
    "+7 ": "(999) 999-99-99",
    "+36 ": "(999) 999-999",

    "+49 ": "(999) 999-99-99",
    "+421 ": "(999) 999-999",
    "+371 ": "9999-9999",
    "+370 ": "(999) 99-9999",
    "+372 ": "9999-9999",
    "+43 ": "999-999-9999",
    "+254 ": "(999) 999-999",
};

export const Sides = [
    {"bore_f": "Лицевая"},
    {"bore_bb": "Обратная"},
    {"bore_b": "Нижний торец"},
    {"bore_t": "Верхний торец"},
    {"bore_l": "Левый торец"},
    {"bore_r": "Правый торец"}

];

export const SheetsUnitMaterialConst = [4, 6, 7];

export const sideLR = ["l", "r"];
export const sideTB = ["t", "b"];
export const sideFR = ["f", "bb"];

export const SIDES_FROM_OPERATIONS = [
    {id: "t", name: "верх"},
    {id: "b", name: "низ"},
    {id: "l", name: "лево"},
    {id: "r", name: "право"}
];

export const isIncludeChangePartSizeBeforeOpenOperation = ["w_pre_joint", "saw"];
export const CONSTANT_PART_SIZE_GABARIT = "gabarit";


export const URL_REDIRECT_HOSTNAME = ['localhost', 'newgo2.ifurn.pro', 'newgo.ifurn.pro'];
export const PATHNAME_FOR_REDIRECT = ['/', '', '/login']
export const IFURN_PRO_URL = `${process.env.REACT_APP_IFURN_PRO_URL}#login`;

export const SELECT_OPTIONS_PHONE = {
    ua: {
        label: 'UA',
        code: "+38 ",
        mask: "(999) 999-99-99",
    },
    he: {
        label: 'HE',
        code: "+972 ",
        mask: "(999) 999-99-99",

    },
    md: {
        label: 'MD',
        code: "+373 ",
        mask: "9999-9999",
    },
    ro: {
        label: 'RO',
        code: "+40 ",
        mask: "-99-999-9999",
    },
    pl: {
        label: 'PL',
        code: "+48 ",
        mask: "(999) 999-999",
    },
    bg: {
        label: 'BG',
        code: "+359 ",
        mask: "(999) 999-999"
    },
    hu: {
        label: 'HU',
        code: "+36 ",
        mask: "(999) 999-999",
    },
    de: {
        label: 'DE',
        code: "+49 ",
        mask: "(999) 999-99-99",
    },
    sk: {
        label: 'SK',
        code: "+421 ",
        mask: "(999) 999-9999",
    },
    lv: {
        label: 'LV',
        code: "+371 ",
        mask: "9999-9999",
    },
    lt: {
        label: 'LT',
        code: "+370 ",
        mask: "(999) 99-9999",
    },
    et: {
        label: 'ET',
        code: "+372 ",
        mask: "9999-9999",
    },
    at: {
        label: 'AT',
        code: "+43 ",
        mask: "999-999-999",
    },
    ru: {
        label: 'RU',
        code: "+7 ",
        mask: "(999) 999-99-99",
    },
    ke:{
        label: 'KE',
        code: "+254 ",
        mask: "(999) 999-999",
    }

}

export const LS_FIRM = 'ifurn_service_main_user_firm';
export const LS_CONNECTED_BOX3D = 'ifurn_service_main_is_connected_box3d';
//isAuth
export const LS_IS_AUTH = 'ifurn_service_main_user_firm_is_auth'
// imgApiUrl
export const LS_IMG_API_URL = 'ifurn_service_main_user_firm_img_api_url'
export const LS_IMAGES_INTERNAL = 'ifurn_service_main_images_internal'
export const LS_GIBLAB_DOMEN = 'ifurn_service_main_giblab_domen'
export const LS_CUTTING_CARD_TYPE_VERSION_DEFAULT = 'ifurn_service_main_cutting_card_type_version_default'
export const LS_LINK_T0_3D_CARD = 'ifurn_service_main_link_to_3d_card'
//lang
export const LS_LANG = 'lang';
// export const LS_LANG = 'ifurn_service_main_client_lang';
export const LS_CLIENT_TOKEN = 'ifurn_service_main_client_token';
export const LS_USER_TOKEN = 'ifurn_service_main_user_token';
// export const LS_PROCESSING_EDGE_INTERFACE = 'ifurn_service_main_user_token';



export const TOGGLE_PROCESSING_EDGE_BY_PART_EDIT = "TOGGLE_PROCESSING_EDGE_BY_PART_EDIT"
export const TOGGLE_PROCESSING_EDGE_BY_PART_OPEN_SIDE_FOR_EDIT = "TOGGLE_PROCESSING_EDGE_BY_PART_OPEN_SIDE_FOR_EDIT"
// export const TOGGLE_PROCESSING_EDGE_BY_PART_AND_SIDE_FOR_EDIT = "TOGGLE_PROCESSING_EDGE_BY_PART_OPEN_SIDE_FOR_EDIT"
