import { tools } from './tools.js';
import { bores } from './bores.js';
import { troughs } from './troughs.js';
import { pazes } from './pazes.js';
import { foreignFrezes } from './foreignFrezes.js';
import { inputFrezes } from './inputFrezes.js';
import { edges } from './edges.js';
import { cutTos } from './cutTos.js';
import { srezes } from './srezes.js';
import { measurementsLib } from './measurements.js';
import { translateLib } from './translate.js';

import { mainCss } from './mainCss.js';
import { blueprintJs } from './blueprintJs.js';
import { extraSvgs } from './extraSvgs.js';
import { coordsMark } from './coordsMark.js';

export const blueprints = {
  frontBlueprintInfo(part, materials, language, partSizesType) {
    let T = translateLib.translate(language);
    let material = tools.getMaterial(part.material, materials);

    let materianlNameFailed = T(translateLib.BLUEPRINT_INFO_NO_MATERIAL_NAME);
    let materialName;

    if (material === null) {
      materialName = materianlNameFailed;
    } else {
      materialName = material?.translate?.[language] || material?.name;

      if (materialName !== null) {
        materialName = tools.cropStr(materialName);
      } else {
        materialName = materianlNameFailed;
      }
    }

    let { x: partX, y: partY } = tools.getXY(part, partSizesType)
    let partZ = part.z;

    let width = tools.rnd(partY || 0);
    let length = tools.rnd(partX || 0);
    let z = tools.rnd(partZ);
    let count = tools.rnd(part.count || 0);

    let widthText = T(translateLib.BLUEPRINT_INFO_W);
    let lengthText = T(translateLib.BLUEPRINT_INFO_L);
    let zText = T(translateLib.BLUEPRINT_INFO_Z);
    let countText = T(translateLib.BLUEPRINT_INFO_COUNT);
    return tools.g([
      tools.operationTable([
        tools.text(
          tools.tspan(`${materialName}`, { x: 0 })
          + tools.tspan(`${lengthText}: ${length}`, { x: 0, dy: '1.2em' })
          + tools.tspan(`${widthText}: ${width}`, { x: 0, dy: '1.2em' })
          + tools.tspan(`${zText}: ${z}`, { x: 0, dy: '1.2em' })
          + tools.tspan(`${countText}: ${count}`, { x: 0, dy: '1.2em' })
        )]
      )], { id: 'front' }
    );
  },
  partEdgeInfo(part, order, language, side) {
    let edge = part.edge[side];

    if (!edge) return '';

    let dbId = edge.db_id;

    if (!dbId) return '';

    let T = translateLib.translate(language);

    let band = tools.getBandWithDbId(order.band, dbId);

    let name = tools.getBandName(band, language);
    name = tools.cropStr(name);
    let preJoint = T(parseInt(edge.pre_joint || 0) ? translateLib.YES : translateLib.NO);
    let curve = T(parseInt(edge.curve || 0) ? translateLib.YES : translateLib.NO);
    let y = band.y;
    let z = band.z;

    let preJointText = T(translateLib.BLUEPRINT_INFO_EDGE_PRE_JOINT);
    let curveText = T(translateLib.BLUEPRINT_INFO_EDGE_CURVE);
    let yText = T(translateLib.BLUEPRINT_INFO_EDGE_Y);
    let zText = T(translateLib.BLUEPRINT_INFO_EDGE_Z);

    return tools.g([
      tools.operationTable([
        tools.text(
          tools.tspan(`${name}`, { x: 0 })
          + tools.tspan(`${zText}: ${z}`, { x: 0, dy: '1.2em' })
          + tools.tspan(`${yText}: ${y}`, { x: 0, dy: '1.2em' })
          + tools.tspan(`${preJointText}: ${preJoint}`, { x: 0, dy: '1.2em' })
          + tools.tspan(`${curveText}: ${curve}`, { x: 0, dy: '1.2em' })
        )
      ])
    ], { id: `${side}_info` })
  },
  frontBlueprint(
    part, order, translate, sideTranslates, measurementOffset,
    needLetterForFrez
  ) {
    let partSizesType = order.partSizesType || '';
    let typeSvg = order.type_svg || 'all';
    let typeSmallCartLbl = ['small', 'cart', 'lbl'].includes(typeSvg);

    // let [partX, partY, partX1, partY1, partZ] = tools.toFloat(
    //   part.x, part.y, part.x1, part.y1, part.z
    // );

    let { x: partX, y: partY } = tools.getXY(part, partSizesType);
    let partZ = part.z;
    let partX1, partY1;
    [partX, partY, partX1, partY1, partZ] = tools.toFloat(
      partX, partY, partX, partY, partZ
    );

    let fill = ['cart', 'lbl'].includes(typeSvg) ? 'none' : '#f5f5dc';

    let language = order.lang || 'en';

    let frontPazes = (
      part.operations.paz || []
    ).filter(p => ['f', 'bb'].includes(p.side));
    let boresSvg = bores.bores(
      part.operations.bore || [], partX, partY, partX1, partY1, partZ, translate,
      sideTranslates, measurementOffset, partSizesType,
      { typeSvg: typeSvg, language: language }
    );
    let pazSvg = pazes.pazes(
      frontPazes, partX, partY, partX1, partY1, partZ, translate,
      sideTranslates, measurementOffset, partSizesType, { language, typeSvg: typeSvg }
    );
    let troughSvg = troughs.troughs(
      part.operations.trough || [], partX, partY, partX1, partY1, partZ,
      translate, sideTranslates, measurementOffset, language, partSizesType
    );
    let foreignFrezesSvg = foreignFrezes.foreignFrezes(
      part.operations.frez || [], order.band, partX, partY, partX1, partY1,
      partZ, translate, sideTranslates, measurementOffset, language, typeSvg,
      needLetterForFrez, partSizesType
    );
    let cutTosSvg = cutTos.cutTos(
      part.operations.cut_to || [], partX, partY, partZ, language, typeSvg
    );

    let edgesSvg = edges.edges(
      part.edge, part.edge_edit, partX, partY, partZ, order.band,
      typeSvg
    );

    let partRectStrokeWidth;

    if (typeSvg === 'lbl') {
      partRectStrokeWidth = '10px';
    } else {
      partRectStrokeWidth = '1px';
    }

    let frontInfo, hovers, measurements;

    if (typeSmallCartLbl) {
      frontInfo = hovers = measurements = '';
    } else {
      frontInfo = this.frontBlueprintInfo(
        part, order.material, language, partSizesType
      );
      hovers = (
        cutTosSvg[1]
        + troughSvg[1]
        + pazSvg[1]
        + boresSvg[1]
        + foreignFrezesSvg[1]
        + frontInfo
      );
      measurements = [
        ...boresSvg[3],
        ...pazSvg[2],
        ...troughSvg[2],
        ...foreignFrezesSvg[2],
      ];
    }
    return [
      tools.g([
        tools.rect(0, 0, partX, partY, {
          fill: fill, 'stroke-width': partRectStrokeWidth, hover_show: 'front'
        }),
        srezes.srezesFront(part.srez || {}, partX, partY, partZ),
        srezes.postformingOnFront(part.edge || {}, partX, partY, partZ),
        cutTosSvg[0],
        troughSvg[0],
        pazSvg[0],
        boresSvg[0],
        boresSvg[2],
        foreignFrezesSvg[0],
        edgesSvg,
      ], { translate: translate, part_side: 'f', }),
      hovers,
      measurements
    ];
  },

  getEdgePaintCorrections(part, side, bands) {
    let result = '';
    let corrections = part.additional_frez_info?.sides_edge_paint_correction?.[side];

    if (!tools.objectIsTrue(corrections)) {
      return result;
    }

    // --- checking if processing of to_do === 'remove' is allowed ---
    let processRemoveTodos = false;
    // either if there is 'add' to_do
    for (const correction of corrections) {
      if (correction.to_do === 'add') {
        processRemoveTodos = true;
        break;
      }
    }
    // or edge has color
    const edgeColor = tools.getEdgeColor(bands, part.edge[side].db_id, {partEdge: part.edge[side]});
    if (edgeColor !== 'none') {
      processRemoveTodos = true;
    }
    // ------

    // sorting corrections
    const correctionsSequence = {
      remove: 0,
      add: 1
    };
    corrections = corrections
      .filter( e => typeof e === 'object' && !Array.isArray(e))
      // sorts this way: [numbers ascending, Infinity, Nan]
      .toSorted(
        (a,b) => correctionsSequence[a.to_do] - correctionsSequence[b.to_do]
      );
    // ------

    const partZ = +part.z || 0;
    const partY = +part.y || 0;

    for (const correction of corrections) {
      const toDo = correction.to_do; // can be either 'add' or 'remove'

      if (toDo === 'remove' && !processRemoveTodos)
        continue;

      const use = correction.use;
      const end = +correction.end;
      const start = +correction.start;
      const bandId = correction.band;
      let x = 0;
      let y = 0;
      let width = 0;
      let height = 0;
      
      if (use === 'x') {
        x = start;
        y = 0;
        width = Math.abs(end - start);
        height = partZ;
      } else if (use === 'y') {
        width = partZ;
        height = Math.abs(end - start);
        x = 0;
        y = partY - end;
      }

      let color = 'gray';
      if (toDo === 'add') {
        color = tools.getBandWithDbId(bands, bandId).color || 'none';
      }

      result += tools.rect(x, y, width, height, {fill: color, stroke: 'none', 'stroke-width': '0'});
    }
    return result;
  },

  sideBlueprint(part, order, side, translate, sideTranslates, measurementOffset) {
    let partSizesType = order.partSizesType || '';
    let typeSvg = order.type_svg || 'all';
    let typeSmallCartLbl = ['small', 'cart', 'lbl'].includes(typeSvg);

    // let [partX, partY, partX1, partY1, partZ] = tools.toFloat(
    //   part.x, part.y, part.x1, part.y1, part.z
    // );

    let { x: partX, y: partY } = tools.getXY(part, partSizesType);
    let partZ = part.z;
    let partX1, partY1;
    [partX, partY, partX1, partY1, partZ] = tools.toFloat(
      partX, partY, partX, partY, partZ
    );

    let [sideX, sideY] = [partX, partY];
    let strokeWidth = !['lbl', 'cart'].includes(typeSvg) ? '1px' : '5px';

    if (['r', 'l'].includes(side)) {
      sideX = partZ;
    } else if (['t', 'b'].includes(side)) {
      sideY = partZ;
    }

    let language = order.lang || 'en';

    let bore = part.operations.bore.filter(
      a => [side, 'f', 'bb'].includes(a.side)
    );
    let paz = part.operations.paz.filter(
      a => [side, 'f', 'bb'].includes(a.side)
    );
    let frez = part.operations.frez || []

    let hoverKwargs = {};
    let hovers = '', measurements = '', info = '';

    let fill = tools.getEdgeColor(
      order.band, part.edge[side].db_id, { partEdge: part.edge[side], }
    );

    if (fill !== 'none' && typeSvg === 'lbl') {
      fill = 'black';
    }

    if (fill !== 'none') {
      hoverKwargs.hover_show = `${side}_info`;
      info = this.partEdgeInfo(part, order, language, side);
    } else if (typeSvg === 'lbl') {
      // getting rid of rectangles on sides
      return ['', '', ''];
    }

    let boresSvg = bores.bores(
      bore, partX, partY, partX1, partY1, partZ, translate, sideTranslates,
      measurementOffset, partSizesType,
      { onSide: true, partSide: side, typeSvg: typeSvg, language: language }
    );
    let pazSvg = pazes.pazes(
      paz, partX, partY, partX1, partY1, partZ, translate, sideTranslates,
      measurementOffset, partSizesType,
      { partSide: side, onSide: true, typeSvg: typeSvg, language, }
    );

    if (typeSmallCartLbl) {
      hovers = '';
      measurements = '';
      info = ''
    } else {
      hovers = (
        pazSvg[1]
        + boresSvg[1]
        + info
      );
      measurements = [...boresSvg[3], ...pazSvg[2]];
    }
    return [
      tools.g([
        tools.rect(
          0, 0, sideX, sideY,
          {
            fill: fill, stroke: 'black', 'stroke-width': strokeWidth,
            ...hoverKwargs
          }
        ),
        this.getEdgePaintCorrections(part, side, order.band),
        // inputFrezes.inputFrezesOnSide(
        //   frez, partX, partY, partZ, order.band, part, side
        // ),
        srezes.srezes(part.srez || {}, order.band, partX, partY, partZ, side),
        srezes.postformingOnEdges(part.edge, side, partX, partY, partZ),
        pazSvg[0],
        boresSvg[0],
      ], {
        translate: translate, part_side: side
      }),
      hovers,
      measurements
    ]
  },
  wholeBlueprint(partId, order, allowTurn = true) {
    // "gabarit" - x1, "saw" - x, "w_pre_joint"- x2

    if (allowTurn) {
      order = tools.turnOrderInside(tools.mirrorOrder(order))
      // order = tools.turnOrderInside(order);
    }

    let part = order.part.filter(p => p.id.toString() === partId.toString())[0];

    let language = order.lang || 'en';
    let T = translateLib.translate(language);

    let partSizesType = order.partSizesType || '';
    let typeSvg = order.type_svg || 'all';
    let needLetterForFrez = (
      Boolean(order.need_letter_for_frez)
      || typeSvg === 'lbl'
    );

    let { x: partX, y: partY } = tools.getXY(part, partSizesType);
    let partZ = part.z;
    let partX1, partY1;
    [partX, partY, partX1, partY1, partZ] = tools.toFloat(
      partX, partY, partX, partY, partZ
    );

    // let [partX, partX1, partY, partY1, partZ] = tools.toFloat(
    //   part.x, part.x1, part.y, part.y1, part.z
    // );

    let offset = ['small', 'cart', 'lbl'] ? 250 : 160;
    let sideOffset = 150;
    let measurementOffset = 100;

    let frontTranslate = (
      !['cart'].includes(typeSvg)
        ? [partZ + offset, partZ + offset]
        : [0, 0]
    );
    let leftTranslate = [offset - sideOffset, partZ + offset];
    let topTranslate = [partZ + offset, offset - sideOffset];
    let rightTranslate = [partX + partZ + offset + sideOffset, partZ + offset];
    let bottomTranslate = [partZ + offset, partY + partZ + offset + sideOffset];

    let sideTranslates = {
      left: leftTranslate,
      top: topTranslate,
      right: rightTranslate,
      bottom: bottomTranslate,
    };

    tools.applyVirtualVerticalMirroringForEdges( part.operations.bore );

    let frontBlueprintSvg = this.frontBlueprint(
      part, order, frontTranslate, sideTranslates, measurementOffset,
      needLetterForFrez
    );
    let lBlueprintSvg = this.sideBlueprint(
      part, order, 'l', leftTranslate, sideTranslates, measurementOffset
    );
    let tBlueprintSvg = this.sideBlueprint(
      part, order, 't', topTranslate, sideTranslates, measurementOffset
    );
    let rBlueprintSvg = this.sideBlueprint(
      part, order, 'r', rightTranslate, sideTranslates, measurementOffset
    );
    let bBlueprintSvg = this.sideBlueprint(
      part, order, 'b', bottomTranslate, sideTranslates, measurementOffset
    );

    let hovers = (
      frontBlueprintSvg[1]
      + lBlueprintSvg[1]
      + tBlueprintSvg[1]
      + rBlueprintSvg[1]
      + bBlueprintSvg[1]
    );

    let preparedMeasurementsData = [
      ...frontBlueprintSvg[2],
      ...lBlueprintSvg[2],
      ...tBlueprintSvg[2],
      ...rBlueprintSvg[2],
      ...bBlueprintSvg[2],
    ];

    let [measurements, noMeasurements, partMeasurements, additionalOffset] = (
      measurementsLib.formatMeasurements(
        partX, partX1, partY, partY1, partZ, frontTranslate, sideTranslates,
        measurementOffset, preparedMeasurementsData
      )
    );

    let height = partY + 2 * partZ + offset * 2 + additionalOffset;
    let width = partX + 2 * partZ + offset * 2 + additionalOffset;
    let scale;
    if (tools.objectIsTrue(order.screen_size)) {
      let [screenX, screenY] = tools.toFloat(
        order.screen_size.x, order.screen_size.y
      );
      scale = Math.min(screenX / width, screenY / height);
    } else {
      scale = 1;
    }
    let center = [
      offset + partZ + additionalOffset + partX / 2,
      offset + partZ + additionalOffset + partY / 2
    ];
    let centerTextureDirection = [center[0], center[1]];
    let centerOneSideSymbol = [center[0], center[1]];
    let oneSide = part.one_side && true;
    let texture = part.texture || false;

    let scriptVars = {
      partSizesType: T(partSizesType.toUpperCase()),
      scale,
      blueprintSize: {
        width,
        height,
      },
      typeSvg,
      partX,
      partY,
      textHints: {
        'blueprint-zoom-in': T(translateLib.ZOOM_IN),
        'blueprint-zoom-out': T(translateLib.ZOOM_OUT)
      }
    };
    let dataForGroup = {
      lBlueprintSvg: lBlueprintSvg[0],
      tBlueprintSvg: tBlueprintSvg[0],
      rBlueprintSvg: rBlueprintSvg[0],
      bBlueprintSvg: bBlueprintSvg[0],
      frontBlueprintSvg: frontBlueprintSvg[0],
      coordsMark: '',
      oneSideSymbol: tools.oneSideSymbol(
        centerOneSideSymbol, oneSide, part.turn
      ),
      measurements,
      hovers
    };

    if (texture) {
      dataForGroup.textureDirection = tools.textureDirection(
        centerTextureDirection, { typeSvg, partX, partY, turn: part.turn }
      );
    }

    if (!['cart', 'small', 'lbl'].includes(typeSvg)) {
      dataForGroup.coordsMark = coordsMark(
        offset - sideOffset + partZ, offset + partY + sideOffset - partZ
      );
    }

    let dataForGroupNoMeasurements = { ...dataForGroup };
    dataForGroupNoMeasurements.measurements = noMeasurements;

    if (['cart'].includes(typeSvg)) {
      return tools.svg([
        tools.style(mainCss),
        frontBlueprintSvg[0]
      ],
        partX,
        partY
      );
    } else if (['small', 'lbl'].includes(typeSvg)) {
      dataForGroupNoMeasurements.measurements = '';
      dataForGroupNoMeasurements.oneSideSymbol = '';
      scale = 100 / (width > height ? width : height);
      let toCx = Math.max(50 * (1 / scale) - width / 2, 0);
      let toCy = Math.max(50 * (1 / scale) - height / 2, 0);
      return tools.svg([
        tools.style(mainCss),
        tools.g([
          ...Object.values(dataForGroupNoMeasurements)
        ], { transform: `scale(${scale}) translate(${toCx}, ${toCy})` })
      ],
        100,
        100,
        { small: '' })
    }

    return tools.svg([
      tools.style(mainCss),
      tools.rect(
        0, 0, partX, partY,
        {
          fill: 'white',
          stroke: 'none',
          id: 'blueprint-background'
        }
      ),
      tools.g([
        tools.g([
          ...Object.values(dataForGroup)
        ], {
          transform: `translate(${additionalOffset}, ${additionalOffset})`,
          inner: '',
          'transform-origin': '0 0'
        }),
        tools.g([
          ...Object.values(dataForGroupNoMeasurements)
        ], {
          transform: `translate(${additionalOffset} ${additionalOffset})`,
          inner_no_measurements: '',
          display: 'none',
          'transform-origin': '0 0'
        }),
      ], {
        blueprint_container: ''
      }),
      extraSvgs,
      tools.script(blueprintJs(scriptVars))
    ],
      height + 300,
      width + 300,
      {
        part_x: partX,
        part_y: partY,
        id: 'blueprint',
        'transform-origin': '0 0',
        style: 'display: block; margin: auto'
      });
  },
  blueprintComments(
    sideTranslates, partX, partY, partZ, sideOffset, screenSize
  ) {
    let rightOffset = sideTranslates.right;
    let bottomOffset = sideTranslates.bottom;
    let leftOffset = sideTranslates.left;
    let topOffset = sideTranslates.top

    let verticalLineX = rightOffset[0] + partZ + sideOffset - 3;
    let verticalLineY1 = topOffset[1];
    let verticalLineY2 = bottomOffset[1] + sideOffset;

    let horizontalLineX1 = leftOffset[0];
    let horizontalLineX2 = rightOffset[0] + partZ + sideOffset - 2;
    let horizontalLineY = bottomOffset[1] + sideOffset;

    let fontSize = 30;
    let horizontalTextX = (
      horizontalLineX1
      + (horizontalLineX2 - horizontalLineX1) / 2
      - (screenSize[0].toString()).length * fontSize / 2
    )
    let horizontalTextY = horizontalLineY + fontSize;

    let verticalTextX = verticalLineX + fontSize;
    let verticalTextY = (
      verticalLineY1
      + (verticalLineY2 - verticalLineY1) / 2
      - 15
    )
    let dimensions = tools.g([
      tools.line(verticalLineX, verticalLineY1, verticalLineX, verticalLineY2, {
        stroke: 'red', 'stroke-width': '3px'
      }),
      tools.line(horizontalLineX1, horizontalLineY, horizontalLineX2,
        horizontalLineY,
        {
          stroke: 'red', 'stroke-width': '3px'
        }),
      tools.text(
        Number(screenSize[0]),
        {
          x: horizontalTextX,
          y: horizontalTextY,
          'font-size': `${fontSize}px`,
          fill: 'red'
        }
      ),
      tools.text(
        Number(screenSize[1]),
        {
          x: verticalTextX,
          y: verticalTextY,
          'font-size': `${fontSize}px`,
          fill: 'red'
        }
      )
    ]);
    return dimensions;
  },
  blueprintByPart(order) {
    let blueprints = {};

    let orderParts = order.part || [];
    order = tools.turnOrderInside(tools.mirrorOrder(order));

    for (let part of orderParts) {
      blueprints[part.id] = this.wholeBlueprint(part.id, order, false)
    }
    return blueprints;
  }
}
