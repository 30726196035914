/**
 *  1. choice type form
 *  2. builder created field for forms
 *
 * */
import {
    isAddressDeliveryVariantsFormGuards,
    isAddressDropdownField,
    isAddressLookupField,
    isAddressTextField, isEditUserAddressFormGuards, isIUserAddressDeliveryItemGuards,
    UserAddressFormFieldConfigType
} from "../../../types/UserAddressesForms/FormFieldTypes";
import FieldInput from "../../../components/FormUi/FieldInput";
import FieldSelect from "../../../components/FormUi/FieldSelect";
import Button from "../../../components/ui/Button";
import FieldProxy from "./FieldProxy";
import Label from "../../../components/FormUi/Label";
import LookupField from "./LookupField";
import ErrorFieldMessage from "../../../components/FormUi/ErrorFieldMessage";
import InputCheckbox from "../../../components/InputFields/InputCheckbox/InputCheckbox";
import {IUserAddressesFormSendRequestResult} from "../../../types/UserAddressesForms/UserAddressesInputOutput";
import useCustomAddressesForm from "../hook/useCustomAddressesForm";
import {useLang} from "../../../context/langProvider";

interface IUserAddressForm {
    typeDeliveryForm: UserAddressFormFieldConfigType,
    sendRequest: (values: { [key: string]: any }) => Promise<IUserAddressesFormSendRequestResult>
    externalErrors?: { [key: string]: string[] };  // Добавляем пропс для внешних ошибок
}

function UserAddressForm({typeDeliveryForm, sendRequest, externalErrors}: IUserAddressForm) {
    const {getT} = useLang()
    const {formik, fields} = useCustomAddressesForm({typeDeliveryForm, sendRequest});
    // Объединяем ошибки from formik и внешние ошибки
    const getError = (fieldName: string) => {
        const formikError = formik.errors?.[fieldName] ?? null;
        const externalError = externalErrors?.[fieldName] ?? null;
        return externalError ?? formikError ?? null;  // Приоритет внешних ошибок
    };

    let extendFilter:any ={order: null, plugin: null};
    if(isIUserAddressDeliveryItemGuards(typeDeliveryForm) && typeDeliveryForm?.order){
        extendFilter = {order: typeDeliveryForm.order.toString(), plugin: typeDeliveryForm.plugin.toString()};
    }
    if(isAddressDeliveryVariantsFormGuards(typeDeliveryForm) && typeDeliveryForm?.order){
        extendFilter = {order: typeDeliveryForm.order.toString() , plugin: typeDeliveryForm.plugin.id.toString()};
    }

    return <form onSubmit={(event) => {
        event.preventDefault();
        event.stopPropagation()
        formik.handleSubmit()
    }} className={"bs-form"}>
        <div className="bs-form-row">
            {fields.map(field => {
                const error = getError(field.name);
                if (isAddressTextField(field)) {
                    return <FieldProxy formValues={formik.values} fieldSetting={field}
                                       onFieldReset={() => {
                                           formik.setFieldValue(`${field.name}`, field.value); // Сбрасываем значение
                                       }}
                    >
                        <div className="bs-form-item bs-sm-12 bs-md-6 bs-lg-4 error">
                            <Label>{field.label}</Label>
                            <FieldInput error={error?.length as string}
                                        name={field.name} value={formik.values[field.name]}
                                        type={field.options.type}
                                        required={field.required}
                                        onChange={formik.handleChange} placeholder={field.label}/>
                            {Array.isArray(error) && (<ErrorFieldMessage>{error.join('.')}</ErrorFieldMessage>)}
                        </div>
                    </FieldProxy>

                }


                if (isAddressDropdownField(field)) {
                    return <FieldProxy formValues={formik.values} fieldSetting={field}
                                       onFieldReset={() => {
                                           formik.setFieldValue(`${field.name}`, field.value); // Сбрасываем значение
                                       }}
                    >
                        <div className="bs-form-item bs-sm-12 bs-md-6 bs-lg-4">
                            <Label>{field.label}</Label>
                            <FieldSelect
                                error={error?.length as string}
                                name={field.name} onChange={formik.handleChange}
                                value={formik.values[field.name]}
                                required={field.required}>
                                <option value="">-------</option>
                                {Object.entries(field.options.option).map(([value, label]) => {
                                    const selected = value === formik.values[field.name];
                                    return <option selected={selected} value={value}>{label}</option>
                                })}

                            </FieldSelect>
                            {Array.isArray(error) && (<ErrorFieldMessage>{error.join('.')}</ErrorFieldMessage>)}
                        </div>
                    </FieldProxy>

                }

                if (isAddressLookupField(field)) {
                    const defaultExistingOption = field.options?.option?.[field.value] ?? null
                    const defaultValue = defaultExistingOption ? {label: defaultExistingOption, value: field.value} : null;

                    return <FieldProxy formValues={formik.values} fieldSetting={field}
                                       onFieldReset={() => formik.setFieldValue(`${field.name}`, field.value)}
                    >
                        <div className="bs-form-item bs-sm-12 bs-md-6 bs-lg-4">
                            <Label>{field.label}</Label>
                            <LookupField fieldSetting={field} formValues={formik.values}
                                         defaultValue={defaultValue}
                                         extendFilter={extendFilter}
                                         onChangeFiled={(name, value) => formik.setFieldValue(`${name}`, value)}
                            />
                            {Array.isArray(error) && (<ErrorFieldMessage>{error.join('.')}</ErrorFieldMessage>)}
                        </div>
                    </FieldProxy>

                }
            })}
        </div>

        {/*При редактирование адреса будет отображаться поле default*/}
        {/*Это для установки адреса по умаолчанию*/}

        <div className="">
            {formik.values.hasOwnProperty('default') && <div className="bs-form-item bs-sm-12 bs-md-6 bs-lg-4 error">
                {/*//@ts-ignore*/}
                <InputCheckbox title={getT("Адрес по умолчанию") as string}
                               id={'field-default'}
                               name={'default'}
                               checked={!!formik.values.default}
                               onChange={(event: any) => {
                                   const checked = event.target.checked;
                                   formik.setFieldValue('default', Number(checked));
                               }}
                />
            </div>
            }

            {/*Для страницы заказа, если пользователь захочет добавить новый адрес доставки*/}
            {/*и захочет сохранить этот новый адрес*/}

            {formik.values.hasOwnProperty('saveAddress') &&
                <div className="bs-form-item bs-sm-12 bs-md-6 bs-lg-4 error">
                    {/*//@ts-ignore*/}
                    <InputCheckbox title={getT("Сохранить этот адрес для будущих заказов") as string}
                                   id={'field-saveAddress'}
                                   name={'saveAddress'}
                                   checked={!!formik.values.saveAddress}
                                   onChange={(event: any) => {
                                       const checked = event.target.checked;
                                       formik.setFieldValue('saveAddress', Number(checked));
                                   }}
                    />
                </div>
            }
        </div>
        <div className="text-center mt-2">
            <Button type={"submit"}>{getT("Сохранить")}</Button>
        </div>

    </form>
}

export default UserAddressForm;