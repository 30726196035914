import {utilsTypeof} from "../../../helpers/utils-typeof";

export const mappingPartialMaterial = (material) => {
    const additional = utilsTypeof.isObject(material?.additional)
        ? {
            ...material?.additional,
            active: material.additional?.hasOwnProperty("active") ? Number(material.additional.active) : 1
        }
        : {
            trimL: "",
            trimW: "",
            rez_napr: "",
            active: 1,
            turn: "",
            hard_cut: ""
        };

    const z = utilsTypeof.isValidNumber(material.z) ? Number(material.z) : 16;
    const haveError = !utilsTypeof.isValidNumber(material.z);

    const band_only_connected = material?.hasOwnProperty("band_only_connected") ? material?.band_only_connected : 0;

    return {
        ...material,
        band_only_connected: band_only_connected,
        additional: additional,
        haveError: haveError,
        z: z
    };
}
