import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {getOrderPart, getOrderPartSizeType} from "../../../selectors/order-selector";
import Modal from "../BasicModal";
import {useLang} from "../../../context/langProvider";
import {modalToggleAlertModalAc} from "../../../store/actions/modal-action";
import {getAlertsProjectModal} from "../../../selectors/modal-selector";
import NotificationWrapper from "./components/NotificationWrapper";
import ErrorBoundary from "../../ErrorBoundary";
import {getAlertsProject} from "../../../selectors/common-selector";
import {onBeforeOpenOperationChangePartSizeType} from "../../../store/reducers/OrderReducer";
import {CONSTANT_PART_SIZE_GABARIT} from "../../../constants";

const AlertsModal = () => {
    const {getT} = useLang();
    const dispatch = useDispatch();

    const modal = useSelector(getAlertsProjectModal);
    const alerts = useSelector(getAlertsProject);
    const parts = useSelector(getOrderPart);
    const partSize = useSelector(getOrderPartSizeType)
    if (!modal.isOpen) return null;

    const onClose = () => {
        dispatch(modalToggleAlertModalAc(false))
    }
    const onOpenOperationModalByPartId = (part: any) => {
        let isActivateChangePartSize = partSize === CONSTANT_PART_SIZE_GABARIT;
        dispatch(onBeforeOpenOperationChangePartSizeType(!isActivateChangePartSize, partSize, part, true))
    }

    return (
        <Modal title={getT("Предупреждения")} open={modal.isOpen} key={'modal-alerts'} onClose={onClose}>
            <ErrorBoundary>
                <div className="modal-body">
                    <NotificationWrapper notifications={alerts}
                                         onOpenOperation={onOpenOperationModalByPartId}
                                         parts={parts}
                                         getT={getT}/>
                </div>
            </ErrorBoundary>
        </Modal>
    );
};

export default AlertsModal;