import {instances} from "../api_axios_settings";
import {URI_API_FREZE_MAKE} from "../uri_api_action";
import {findErrorResponse, rejectTransform, resolveTransform} from "../utils_api";
import {LS_FIRM} from "../../constants";
import {IFrezNewgoOperation} from "../../types/ExtentdsFrezOperaions/FrezOperationsTypes";

type ResultErrorApi = { error?: string };


type SaveArgType = {
    part: any, frezeAdd: any, materials: any[], bands: any[], bandsType: any[], client: string | undefined
}
type ResponseSaveType = {
    part: any, code: string,
}


type ResponseCheck = {
    commonError: null | string,
    result: {
        alerts: string[] | null,
        // result_status: "error" | "ok",
        error: string[] | null
        frez_move: any[] | null
    }
}
const utilResponseResultCheck = (res: any): ResponseCheck => {
    const commonError = findErrorResponse(res);

    return {
        commonError: !!commonError ? commonError : null,
        result: {
            alerts: res?.alerts ? res?.alerts : null,
            error: res?.error ? res?.error : null,
            frez_move: res?.frez_move ? res?.frez_move : null,
        }
    };
}


export interface IFrezeMakeApi {
    check: (part: any, frezeAdd: any) => Promise<ResponseCheck>;
    save: (data: SaveArgType) => Promise<ResponseSaveType & ResultErrorApi>;
    convert: (operation: any) => Promise<ResultErrorApi & IFrezNewgoOperation>;
    checkAll: (orderId: number) => Promise<any>;
    checkByPartId: (orderId: number, partId: number) => Promise<any>;
}

export const FrezeMakeApi: IFrezeMakeApi = {
    check(part: any, frezeAdd: any) {
        let url = URI_API_FREZE_MAKE + '/check'
        return instances.post(url, {part: part, freze_add: frezeAdd, firm: localStorage.getItem(LS_FIRM)})
            .then(res => {
                return utilResponseResultCheck(res.data)
            })
            .catch((error) => {
                let error_values = findErrorResponse(error);
                return Promise.reject({
                        commonError: error_values ? error_values : null,
                        result: {alerts: null, error: null, frez_move: null}
                    }
                )
            })
    },
    save({part, frezeAdd, materials, bands, bandsType, client}) {
        let url = URI_API_FREZE_MAKE + '/make'
        return instances.post(url, {
            part: part,
            freze_add: frezeAdd,
            firm: localStorage.getItem(LS_FIRM),
            bands: bands,
            materials: materials,
            bands_type: bandsType,
            client: client
        })
            .then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    convert(operation: any) {
        let url = URI_API_FREZE_MAKE + '/convert'
        return instances.post(url, operation)
            .then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    checkAll(orderId) {
        return instances.get(URI_API_FREZE_MAKE + '/check_all/' + orderId)
            .then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    },
    checkByPartId(orderId, partId) {
        return instances.get(URI_API_FREZE_MAKE + '/check_all/' + orderId + '/' + partId)
            .then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    }
}