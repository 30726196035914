import React, {useRef, useState} from 'react';
import {useOnClickOutside} from "../../HOOK/useOutsideClick";
import "./css/hint-tooltip.css"
interface Props {
    children: React.ReactNode;
    header: React.ReactNode;
    className?: string;
}

const HintTooltip: React.FC<Props> = ({children, header, className = ''}) => {
    const [isOpen, toggleOpen] = useState(false);
    const ref = useRef<HTMLDivElement | null>(null);
    useOnClickOutside(ref, () => toggleOpen(false));


    return (
        <div className={`hint-tooltip-message ${className}`}  ref={ref}>
            <div className="hint-tooltip-header"  onClick={() => {
                console.log('click')
                toggleOpen(!isOpen)
            }}>
                {header}
            </div>
            {isOpen && <div className="hint-tooltip-content">
                {children}
            </div>}
        </div>
    );
};

export default HintTooltip;