const TROUGH_W = 'TROUGH_W';
const TROUGH_L = 'TROUGH_L';
const BLUEPRINT_INFO_EDGE_Z = 'BLUEPRINT_INFO_EDGE_Z';

export const translateLib = {
  GABARIT: 'GABARIT',
  SAW: 'SAW',
  W_PRE_JOIN: 'W_PRE_JOINT',

  PAZ_POPUP_NAME: 'PAZ_POPUP_NAME',
  PAZ_TYPE: 'PAZ_TYPE',
  PAZ_TYPE_OPEN: 'PAZ_TYPE_OPEN',
  PAZ_TYPE_CLOSED: 'PAZ_TYPE_CLOSED',
  
  PAZ_D_FROM_L: 'PAZ_D_FROM_L',
  PAZ_D_FROM_R: 'PAZ_D_FROM_R',
  PAZ_D_FROM_T: 'PAZ_D_FROM_T',
  PAZ_D_FROM_B: 'PAZ_D_FROM_B',
  
  PAZ_Z: 'PAZ_Z',

  PAZ_SIDE_L: 'PAZ_SIDE_L',
  PAZ_SIDE_R: 'PAZ_SIDE_R',
  PAZ_SIDE_B: 'PAZ_SIDE_B',
  PAZ_SIDE_T: 'PAZ_SIDE_T',
  PAZ_SIDE_F: 'PAZ_SIDE_F',
  PAZ_SIDE_BB: 'PAZ_SIDE_BB',
  PAZ_SIDE: 'PAZ_SIDE',
  
  PAZ_W_PAZ: 'PAZ_W_PAZ',
  PAZ_D_SIDE_START: 'PAZ_D_SIDE_START',
  PAZ_D_SIDE_END: 'PAZ_D_SIDE_END',

  BORE_POPUP_NAME: 'BORE_POPUP_NAME',
  BORE_SIDE: 'BORE_SIDE',
  BORE_SIDE_F: 'BORE_SIDE_F',
  BORE_SIDE_BB: 'BORE_SIDE_BB',
  BORE_SIDE_L: 'BORE_SIDE_L',
  BORE_SIDE_R: 'BORE_SIDE_R',
  BORE_SIDE_T: 'BORE_SIDE_T',
  BORE_SIDE_B: 'BORE_SIDE_B',
  BORE_X: 'BORE_X',
  BORE_Y: 'BORE_Y',
  BORE_D: 'BORE_D',
  BORE_Z: 'BORE_Z',

  TROUGH_POPUP_NAME: 'TROUGH_POPUP_NAME',
  TROUGH_X: 'TROUGH_X',
  TROUGH_Y: 'TROUGH_Y',
  TROUGH_L: TROUGH_L,
  TROUGH_W: TROUGH_W,
  TROUGH_Z: 'TROUGH_Z',
  TROUGH_R: 'TROUGH_R',

  CUT_TO_POPUP_NAME: 'CUT_TO_POPUP_NAME',
  CUT_TO_B: 'CUT_TO_B',
  CUT_TO_L: 'CUT_TO_L',
  CUT_TO_R: 'CUT_TO_R',
  CUT_TO_T: 'CUT_TO_T',
  CUT_TO_SIDE_INDENT: 'CUT_TO_INDENT',

  FREZ_POPUP_NAME: 'FREZ_POPUP_NAME',
  FREZ_SIDE: 'FREZ_SIDE',
  FREZ_SIDE_L: 'FREZ_SIDE_L',
  FREZ_SIDE_R: 'FREZ_SIDE_R',
  FREZ_SIDE_T: 'FREZ_SIDE_T',
  FREZ_SIDE_B: 'FREZ_SIDE_B',
  FREZ_SIDE_F: 'FREZ_SIDE_F',
  FREZ_SIDE_BB: 'FREZ_SIDE_BB',
  FREZ_C_0: `FREZ_C_0`,
  FREZ_C_1: `FREZ_C_1`,
  FREZ_C_2: `FREZ_C_2`,
  FREZ_DIAMETER: 'FREZ_DIAMETER',

  BLUEPRINT_INFO_EDGE_NAME: 'BLUEPRINT_INFO_EDGE_NAME',
  BLUEPRINT_INFO_EDGE_PRE_JOINT: 'BLUEPRINT_INFO_EDGE_PRE_JOINT',
  BLUEPRINT_INFO_EDGE_CURVE: 'BLUEPRINT_INFO_EDGE_CURVE',
  BLUEPRINT_INFO_EDGE_Z: BLUEPRINT_INFO_EDGE_Z,
  BLUEPRINT_INFO_EDGE_Y: TROUGH_W,

  BLUEPRINT_INFO_NO_MATERIAL_NAME: 'BLUEPRINT_INFO_NO_MATERIAL_NAME',
  BLUEPRINT_INFO_MATERIAL: 'BLUEPRINT_INFO_MATERIAL',
  BLUEPRINT_INFO_W: TROUGH_W,
  BLUEPRINT_INFO_L: TROUGH_L,
  BLUEPRINT_INFO_Z: BLUEPRINT_INFO_EDGE_Z,
  BLUEPRINT_INFO_COUNT: 'BLUEPRINT_INFO_COUNT',

  EDGE_CUTTING: 'EDGE_CUTTING',

  YES: 'YES',
  NO: 'NO',

  START_POINT_NAME: 'START_POINT_NAME',
  START_POINT_LD: 'START_POINT_LD',
  START_POINT_LU: 'START_POINT_LU',
  START_POINT_RD: 'START_POINT_RD',
  START_POINT_RU: 'START_POINT_RU',

  IMAGE_DIFFERS_FOR_USER_CONVENIENCE: 'IMAGE_DIFFERS_FOR_USER_CONVENIENCE',

  ZOOM_IN: 'ZOOM_IN',
  ZOOM_OUT: 'ZOOM_OUT',

  MILIMETERS: 'MILIMETERS',

  TRANSLATIONS: {
    ru: {
      MILIMETERS: 'мм',
      ZOOM_IN: 'Увеличить чертёж',
      ZOOM_OUT: 'Уменьшить чертёж',
      GABARIT: 'Габаритные размеры',
      SAW: 'Пильные размеры',
      W_PRE_JOINT: 'Размеры без кромки',
      IMAGE_DIFFERS_FOR_USER_CONVENIENCE: [
        'Изображение отличается от реальной программы',
        'для удобства пользователя',
      ],
      START_POINT_NAME: 'Начало координат',
      START_POINT_LD: 'левый нижний',
      START_POINT_LU: 'левый верхний',
      START_POINT_RD: 'правый нижний',
      START_POINT_RU: 'правый верхний',
      YES: 'да',
      NO: 'нет',
      PAZ_POPUP_NAME: 'Паз',
      PAZ_TYPE: 'тип',
      PAZ_TYPE_OPEN: 'открытый',
      PAZ_TYPE_CLOSED: 'закрытый',
      PAZ_D_FROM_T: 'отступ от верхней стороны',
      PAZ_D_FROM_L: 'отступ от левой стороны',
      PAZ_D_FROM_R: 'отступ от правой стороны',
      PAZ_D_FROM_B: 'отступ от нижней стороны',
      PAZ_Z: 'глубина',
      PAZ_SIDE_L: 'левая',
      PAZ_SIDE_R: 'правая',
      PAZ_SIDE_B: 'нижняя',
      PAZ_SIDE_T: 'верхняя',
      PAZ_SIDE_F: 'лицевая',
      PAZ_SIDE_BB: 'обратная',
      PAZ_SIDE: 'сторона операции',
      PAZ_W_PAZ: 'ширина паза',
      PAZ_D_SIDE_START: 'отступ от начала',
      PAZ_D_SIDE_END: 'отступ от конца',
      BORE_POPUP_NAME: 'Отверстие',
      BORE_SIDE: 'сторона',
      BORE_SIDE_F: 'лицевая',
      BORE_SIDE_BB: 'обратная',
      BORE_SIDE_L: 'левая',
      BORE_SIDE_R: 'правая',
      BORE_SIDE_T: 'верхняя',
      BORE_SIDE_B: 'нижняя',
      BORE_X: 'X',
      BORE_Y: 'Y',
      BORE_D: 'диаметр',
      BORE_Z: 'глубина',
      TROUGH_POPUP_NAME: 'Выемка',
      TROUGH_X: 'центр по X',
      TROUGH_Y: 'центр по Y',
      TROUGH_W: 'ширина',
      TROUGH_L: 'длина',
      TROUGH_Z: 'глубина',
      TROUGH_R: 'радиус',
      CUT_TO_POPUP_NAME: 'Урезка/упил',
      CUT_TO_SIDE_INDENT: 'сторона отступа',
      CUT_TO_B: 'низ',
      CUT_TO_L: 'лево',
      CUT_TO_R: 'право',
      CUT_TO_T: 'верх',
      FREZ_POPUP_NAME: 'Фрезеровка',
      FREZ_SIDE: 'сторона',
      FREZ_SIDE_L: 'левая',
      FREZ_SIDE_R: 'правая',
      FREZ_SIDE_T: 'верхняя',
      FREZ_SIDE_B: 'нижняя',
      FREZ_SIDE_F: 'лицевая',
      FREZ_SIDE_BB: 'обратная',
      FREZ_C_0: 'по центру траектории',
      FREZ_C_1: 'слева от траектории',
      FREZ_C_2: 'справа от траектории',
      FREZ_DIAMETER: 'диаметр',
      BLUEPRINT_INFO_MATERIAL: 'материал',
      BLUEPRINT_INFO_NO_MATERIAL_NAME: 'нет имени',
      BLUEPRINT_INFO_COUNT: 'количество',
      BLUEPRINT_INFO_EDGE_NAME: 'название кромки',
      BLUEPRINT_INFO_EDGE_PRE_JOINT: 'прифуговка',
      BLUEPRINT_INFO_EDGE_CURVE: 'криволинейка',
      BLUEPRINT_INFO_EDGE_Z: 'толщина',
      EDGE_CUTTING: 'кромкование реза',
    },
    ua: {
      MILIMETERS: 'мм',
      ZOOM_IN: 'Збільшити креслення',
      ZOOM_OUT: 'Зменшити креслення',
      GABARIT: 'Габаритні розміри',
      SAW: 'Пиляльні розміри',
      W_PRE_JOINT: 'Розміри без кромки',
      IMAGE_DIFFERS_FOR_USER_CONVENIENCE:
        'Зображення відрізняється від реальної '
        + 'програми для зручності користувача',
      START_POINT_NAME: 'Початок координат',
      START_POINT_LD: 'лівий нижній',
      START_POINT_LU: 'лівий верхній',
      START_POINT_RD: 'правий нижній',
      START_POINT_RU: 'правий верхній',
      YES: 'так',
      NO: 'ні',
      PAZ_POPUP_NAME: 'Паз',
      PAZ_TYPE: 'тип',
      PAZ_TYPE_OPEN: 'відкритий',
      PAZ_TYPE_CLOSED: 'закритий',
      PAZ_D_FROM_T: 'відступ від верхньої сторони',
      PAZ_D_FROM_L: 'відступ від лівої сторони',
      PAZ_D_FROM_R: 'відступ від правої сторони',
      PAZ_D_FROM_B: 'відступ від нижньої сторони',
      PAZ_Z: 'глибина',
      PAZ_SIDE_L: 'ліва',
      PAZ_SIDE_R: 'права',
      PAZ_SIDE_B: 'нижня',
      PAZ_SIDE_T: 'верхня',
      PAZ_SIDE_F: 'лицьова',
      PAZ_SIDE_BB: 'зворотна',
      PAZ_SIDE: 'сторона операції',
      PAZ_W_PAZ: 'ширина пазу',
      PAZ_D_SIDE_START: 'відступ від початку',
      PAZ_D_SIDE_END: 'відступ від кінця',
      BORE_POPUP_NAME: 'Отвір',
      BORE_SIDE: 'сторона',
      BORE_SIDE_F: 'лицьова',
      BORE_SIDE_BB: 'зворотна',
      BORE_SIDE_L: 'ліва',
      BORE_SIDE_R: 'права',
      BORE_SIDE_T: 'верхня',
      BORE_SIDE_B: 'нижня',
      BORE_X: 'X',
      BORE_Y: 'Y',
      BORE_D: 'діаметр',
      BORE_Z: 'глибина',
      TROUGH_POPUP_NAME: 'Виїмка',
      TROUGH_X: 'центр по X',
      TROUGH_Y: 'центр по Y',
      TROUGH_W: 'ширина',
      TROUGH_L: 'довжина',
      TROUGH_Z: 'глибина',
      TROUGH_R: 'радіус',
      CUT_TO_POPUP_NAME: 'Урізка/упил',
      CUT_TO_SIDE_INDENT: 'сторона відступу',
      CUT_TO_B: 'низ',
      CUT_TO_L: 'ліво',
      CUT_TO_R: 'право',
      CUT_TO_T: 'верх',
      FREZ_POPUP_NAME: 'Фрезерування',
      FREZ_SIDE: 'сторона',
      FREZ_SIDE_L: 'ліва',
      FREZ_SIDE_R: 'права',
      FREZ_SIDE_T: 'верхня',
      FREZ_SIDE_B: 'нижня',
      FREZ_SIDE_F: 'лицьова',
      FREZ_SIDE_BB: 'зворотна',
      FREZ_C_0: 'по центру траєкторії',
      FREZ_C_1: 'зліва від траєкторії',
      FREZ_C_2: 'справа від траєкторії',
      FREZ_DIAMETER: 'діаметр',
      BLUEPRINT_INFO_MATERIAL: 'матеріал',
      BLUEPRINT_INFO_NO_MATERIAL_NAME: 'немає імені',
      BLUEPRINT_INFO_COUNT: 'кількість',
      BLUEPRINT_INFO_EDGE_NAME: 'назва кромки',
      BLUEPRINT_INFO_EDGE_PRE_JOINT: 'прифугівка',
      BLUEPRINT_INFO_EDGE_CURVE: 'криволінійка',
      BLUEPRINT_INFO_EDGE_Z: 'товщина',
      EDGE_CUTTING: 'кромкування різу',
    },
    en: {
      MILIMETERS: 'mm',
      ZOOM_IN: 'Zoom blueprint in',
      ZOOM_OUT: 'Zoom blueprint out',
      GABARIT: 'Overall dimensions',
      SAW: 'Saw dimensions',
      W_PRE_JOINT: 'Dimensions without edge',
      IMAGE_DIFFERS_FOR_USER_CONVENIENCE:
        'Picture differs from real program for '
        + 'user convenience',
      START_POINT_NAME: 'Coordinates origin',
      START_POINT_LD: 'bottom left',
      START_POINT_LU: 'upper left',
      START_POINT_RD: 'bottom right',
      START_POINT_RU: 'upper right',
      YES: 'yes',
      NO: 'no',
      PAZ_POPUP_NAME: 'Groove',
      PAZ_TYPE: 'type',
      PAZ_TYPE_OPEN: 'open',
      PAZ_TYPE_CLOSED: 'closed',
      
      PAZ_D_FROM_T: 'indent from top side',
      PAZ_D_FROM_L: 'indent from left side',
      PAZ_D_FROM_R: 'indent from right side',
      PAZ_D_FROM_B: 'indent from bottom side',

      PAZ_Z: 'depth',
      PAZ_SIDE_L: 'left',
      PAZ_SIDE_R: 'right',
      PAZ_SIDE_B: 'bottom',
      PAZ_SIDE_T: 'top',
      PAZ_SIDE_F: 'front',
      PAZ_SIDE_BB: 'back',

      PAZ_SIDE: 'operation side',
      PAZ_W_PAZ: 'groove width',
      PAZ_D_SIDE_START: 'indent start',
      PAZ_D_SIDE_END: 'indent end',
      BORE_POPUP_NAME: 'Bore',
      BORE_SIDE: 'side',
      BORE_SIDE_F: 'front',
      BORE_SIDE_BB: 'back',
      BORE_SIDE_L: 'left',
      BORE_SIDE_R: 'right',
      BORE_SIDE_T: 'top',
      BORE_SIDE_B: 'bottom',
      BORE_X: 'X',
      BORE_Y: 'Y',
      BORE_D: 'diameter',
      BORE_Z: 'depth',
      TROUGH_POPUP_NAME: 'Trough',
      TROUGH_X: 'X center',
      TROUGH_Y: 'Y center',
      TROUGH_W: 'width',
      TROUGH_L: 'length',
      TROUGH_Z: 'depth',
      TROUGH_R: 'radius',
      CUT_TO_POPUP_NAME: 'Cut',
      CUT_TO_SIDE_INDENT: 'indent side',
      CUT_TO_B: 'bottom',
      CUT_TO_L: 'left',
      CUT_TO_R: 'right',
      CUT_TO_T: 'top',
      FREZ_POPUP_NAME: 'Milling',
      FREZ_SIDE: 'side',
      FREZ_SIDE_L: 'left',
      FREZ_SIDE_R: 'right',
      FREZ_SIDE_T: 'top',
      FREZ_SIDE_B: 'bottom',
      FREZ_SIDE_F: 'front',
      FREZ_SIDE_BB: 'back',
      FREZ_C_0: 'along the trajectory center',
      FREZ_C_1: 'along the trajectory left',
      FREZ_C_2: 'along the trajectory right',
      FREZ_DIAMETER: 'diameter',
      BLUEPRINT_INFO_MATERIAL: 'material',
      BLUEPRINT_INFO_NO_MATERIAL_NAME: 'no name',
      BLUEPRINT_INFO_COUNT: 'quantity',
      BLUEPRINT_INFO_EDGE_NAME: 'edge name',
      BLUEPRINT_INFO_EDGE_PRE_JOINT: 'jointing',
      BLUEPRINT_INFO_EDGE_CURVE: 'curvilinear',
      BLUEPRINT_INFO_EDGE_Z: 'depth',
      EDGE_CUTTING: 'edge cutting',
    },
  },

  translate(language) {
    if (!Object.keys(this.TRANSLATIONS).includes(language)) {
      language = 'en';
    }
    let This = this;

    function inner(what) {
      if (!language || !what) {
        return '';
      }
      return This.TRANSLATIONS[language][what] || what;
    }

    return inner
  }
}
