import TranslateApi from "../languages/translationApi";
import md5 from "md5";
import apiService from "../api/api";
import {_setMirroringOperations} from "../store/reducers/OrderReducer";
import {LS_IMG_API_URL, LS_LANG} from "../constants";

const api = new apiService();

export function random(letters, either, numbers) {
    const chars = [
        "ABCDEFGHIJKLMNPQRSTUVWXYZABCDEFGHIJKLMNPQRSTUVWXYZABCDEFGHIJKLMNPQRSTUVWXYZ",
        "abcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyzabcdefghijklmnopqrstuvwxyz",
        "0123456789012345678901234567890123456789"

    ];

    return [letters, numbers, either].map(function (len, i) {
        return Array(len).fill(chars[i]).map(function (x) {
            return x[Math.floor(Math.random() * x.length)];
        }).join("");
    }).concat().join("").split("").sort(function () {
        return 0.5 - Math.random();
    }).join("");
}

export function formatNumbersThousands(value) {
    if (!value) return
    // if (isNaN(value)) return null;
    // if (value === 0) return value
    //::TODO Округление до 2-х знаков
    return Math.floor(Number(value) * 1000) / 1000;
}
export function formatNumbersThousandsNumber(value) {
    if (isNaN(value)) return 0;
    // if (value === 0) return value
    //::TODO Округление до 2-х знаков
    return Math.floor(Number(value) * 1000) / 1000;
}

export function truncateToOneDecimalPlace(value) {
    if (typeof value === 'number' && !isNaN(value)) {
        return Math.floor(value * 10) / 10;
    } else {
        return null
    }
}

export function _unique(arr) {
    let result = [];

    for (let str of arr) {
        if (!result.includes(str)) {
            result.push(str);
        }
    }

    return result;
}

export function getDate() {
    const d = new Date();
    const time = d.toLocaleTimeString();
    const m = d.getMonth() + 1;
    const month = (d.getMonth() + 1) < 10 ? "0" + (d.getMonth() + 1) : (d.getMonth() + 1);
    const date = d.getDate() + 1 < 10 ? "0" + d.getDate() + 1 : d.getDate();
    return d.getFullYear() + "-" + month + "-" + date + " " + time;
}

const checkPartSrezExist = (part) => {
    let counter = 0;
    ["t", "b", "l", "r"].forEach(e => {
        if (part.srez) {
            if (Number(part.srez[e].ugol) > 0 || Number(part.srez[e].otstup) > 0 || Number(part.srez[e].db_id) > 0) {
                counter++;
            }
        }
    });
    return counter;
};

export const checkOrderOperationsExist = (order) => {
    let counter = 0;
    const parts = order.part;
    parts.forEach((part) => {
        const operations = part.operations;
        counter += (
            (operations.bore?.length ?? 0) +
            (operations.paz?.length ?? 0) +
            (operations.trough?.length ?? 0) +
            (operations.frez?.length ?? 0) +
            (operations.cut_to?.length ?? 0) +
            checkPartSrezExist(part)
        );
    });
    return counter > 0;
};

export const onSetDTMemory = (order, isSet = true) => {
    if (isSet) {
        if (order) {
            let orderDP = Object.assign({}, order),
                today = new Date(),
                date = today.getFullYear() + "-" + (Number((today.getMonth() + 1) < 10) ? "0" + (today.getMonth() + 1) : (today.getMonth() + 1)) + "-" + (Number(today.getDate() < 10) ? "0" + today.getDate() : today.getDate()),
                time = today.getHours() + ":" + ((today.getMinutes()) < 10 ? "0" + (today.getMinutes()) : (today.getMinutes())) + ":" + (Number(today.getSeconds()) < 10 ? "0" + today.getSeconds() : today.getSeconds());
            delete orderDP.date;
            if (orderDP.hasOwnProperty("boxes") && orderDP.boxes.length > 0) {
                let lenghtBox = orderDP.boxes.length;
                for (let b = 0; b < lenghtBox; b++) {
                    orderDP.boxes[b].html = "";
                    orderDP.boxes[b].new_box.help.html = "";
                }
            }
            let stageLS = {
                order: {
                    ...orderDP
                },
                date: date + " " + time
            };
            try {
                localStorage.setItem(orderDP.id, JSON.stringify(stageLS));
            } catch (e) {

            }
        }
    }
};


export const setSubstrateBody = (isToggle, className = "") => {
    if (isToggle) {
        document.body.classList.add(`substrate-${className}`);
    } else {
        document.body.classList.remove(`substrate-${className}`);

    }
};

export const getTranslateMessageAPI = (key) => {
    const lang = localStorage.getItem("lang") || "ua";
    const keyMD = md5(key);
    if (TranslateApi[0][keyMD]) {
        if (TranslateApi[0][keyMD][lang]) {
            return TranslateApi[0][keyMD][lang];
        } else {
            console.info("не найденно слова", key);
            return key;
        }
    }

};
export const clearAllStorage = () => {
    let copyLS = localStorage;
    for (var attr in copyLS) {
        if (attr !== LS_LANG && attr !== LS_IMG_API_URL && attr !== "setItem" && attr !== "getItem" && attr !== "removeItem" && attr !== "clear" && attr !== "key") {
            window.localStorage.removeItem(`${attr}`);
        }
    }
};



export const isEmptyEdgePart = (edge) => {
    if (Object?.keys(edge)?.length !== 4) return false;
    // return true
    return !edge?.t?.db_id && !edge?.b?.db_id && !edge?.l?.db_id && !edge?.r?.db_id;
};




export function isObject(value) {
    return (
        typeof value === "object" &&
        value !== null &&
        !Array.isArray(value)
    );
}


export const manipulateSessionCalculate = {
    get: async () => {
        try {
            let calculateStorage = sessionStorage.getItem("calculate");
            let calculate = calculateStorage;
            if (Number(calculate) && calculate?.length !== 0) {
                let response = await api._getCalculateValues(calculateStorage);
                calculate = response.data;
            }
            return calculate;
        } catch (e) {
            console.log("error get calculate", e.message);
            return "";
        }
    },
    set: async (resultGl) => {
        try {
            sessionStorage.setItem("calculate", resultGl);
        } catch (e) {
            let response = await api._setCalculateValues(resultGl);
            sessionStorage.setItem("calculate", response.id);
        }
    },
    delete: () => {
        try {

            sessionStorage.removeItem("calculate");
        } catch (e) {
            sessionStorage.removeItem("calculate");
        }
    }
};

export const changeMirroringTypeInPart = ({part, types, mirroringType}) => {
    try {
        types.map(t => {
            for (let i = 0; i < part.operations[t].length; i++) {
                if (t === "paz" && part.operations[t][i]?.hasOwnProperty("id_template_paz")) {
                    part.operations[t][i] = {
                        ..._setMirroringOperations(part.operations[t][i], mirroringType)
                    };


                } else {


                    part.operations[t][i] = {
                        ..._setMirroringOperations(part.operations[t][i], mirroringType)
                    };
                }
            }
        });
        part.srez = {
            ...part.srez,
            ..._setMirroringOperations(part.srez, mirroringType)
        };
        let copyEdgeL = {
            ...part.edge["l"]
        };
        let copyEdgeR = {
            ...part.edge["r"]
        };
        let copyEdgeT = {
            ...part.edge["t"]
        };
        let copyEdgeB = {
            ...part.edge["b"]
        };
        switch (mirroringType) {
            case "hor": {
                part.edge = {
                    ...part.edge,
                    l: {
                        ...copyEdgeR
                    },
                    r: {
                        ...copyEdgeL
                    }

                };


                break;
            }
            case "ver": {
                part.edge = {
                    ...part.edge,
                    b: {
                        ...copyEdgeT
                    },
                    t: {
                        ...copyEdgeB
                    }

                };

            }
        }
        return {
            ...part
        };
    } catch (e) {
        console.error("error", e.message);
        return {
            ...part
        };
    }
};


export const createSelectedOperationsSidesDataForParams = ({operations}) => {
    let side_f = operations?.filter(item => item.side === "f" || !item.side);
    let side_b = operations?.filter(item => item.side === "bb");
    let side_ends = operations?.filter(item => item.side !== "bb" && item.side !== "f" && !!item.side);
    return {
        side_f: side_f || [],
        side_b: side_b || [],
        side_end: side_ends || []
    };
};

export const createOperationsForSidesOfDiameter = ({sideOperations}) => {
    return sideOperations?.reduce(
        (accumulator, currentValue) => {
            if (accumulator.hasOwnProperty(`d_${currentValue.d}`)) {
                accumulator[`d_${currentValue.d}`].push(
                    currentValue.id
                );
                return accumulator;
            }
            accumulator[`d_${currentValue.d}`] = [
                currentValue.id
                // d: currentValue.d
            ];
            return accumulator;
        },
        {}
    );
};

export const isCheckedOperations = ({currentOperations, currentOperationsFilter}) => {
    let findAll = currentOperationsFilter?.filter(id => currentOperations.some(operation => Number(operation.id) === Number(id)));
    return findAll.length > 0 ? findAll.length === currentOperations.length : false;
};

export const findMatchPresenceOperation = ({currentOperations, currentOperationsFilter}) => {
    let findAll = currentOperationsFilter?.filter(id => currentOperations.some(operation => Number(operation.id) === Number(id)));
    return !!findAll.length;
};
export const isCheckedOperationSelectRest = ({cut, srez, hem, operationsFilter}) => {

    let contain = [];
    if (cut) {
        contain.push(cut.id);
    }
    if (hem) {
        contain.push(hem.id);
    }
    if (srez) {
        contain.push(-1);
    }
    let mergeOperationsFilter = [...operationsFilter.hem || [], ...operationsFilter.cut_to || [], ...operationsFilter.srez || []]?.flat();
    return contain.length > 0 ? contain.length === mergeOperationsFilter.length : false;

};
/**
 * Площать деталей в метериале.
 *
 * @param {array} parts - parts.
 * @param {array} product - product.
 * @param {string} partSizeType - type detail.
 * @returns {number} - Accumulator detail area.
 */

export const getCalcAreaParts = ({parts = [], product = [], partSizeType = 'saw'}) => {
    if (!parts?.length || !Array.isArray(parts) || !Array.isArray(product)) return 0
    let values = parts.reduce((acc, part) => {
        const productCount = product?.find(item => Number(item?.id) === Number(part?.product))?.count ?? 1;

        switch (partSizeType) {
            case 'saw':
                return acc += (Number(part.x) * Number(part.y)) * (Number(part.count) * Number(productCount));
            case 'gabarit':
                return acc += (Number(part.x1) * Number(part.y1)) * (Number(part.count) * Number(productCount));
            case 'w_pre_joint':
                return acc += (Number(part.x2) * Number(part.y2)) * (Number(part.count) * Number(productCount));
        }
    }, 0);
    return Number((values) / 1000000)?.toFixed(2)
}
/**
 * Сложенение кол-ва деталей в метериале.
 *
 * @param {array} parts - parts.
 * @param {array} product - product.
 * @returns {number} - Accumulator count detail.
 */
export const getPartsCount = ({parts = [], product = []}) => {
    if (!Array.isArray(parts) || !Array.isArray(product)) return 0;
    return parts.reduce((accumulator, value) => {
        const productCount = product?.find(item => Number(item?.id) === Number(value?.product))?.count ?? 1;
        return accumulator + (Number(value.count) * Number(productCount))
    }, 0)
}

/**
 * Функция которая ищет dom элемент и проставляет фокус.
 *
 * @param {string} classN - find to class.
 * @param {number} row - index row.
 * @param {number} col - index col.
 * @param {function | null} cb - callback function.
 * @returns {undefined} - return.
 */

export const focusedNexElementDetailing = ({classN, row, col, cb}) => {
    let current = document.querySelector(`.${classN}_${row}_${col}`);
    let next = document.querySelector(`.${classN}_${row}_${col + 1}`);
    if (next) {
        let findElement = next.querySelector('select, input');
        if (findElement) {
            findElement.focus()
        }
    }
    if (!next && cb) {
        cb();
    }
}

export const findNextElementForFocused = ({classN, row, col}) => {
    let next = document.querySelector(`.${classN}_${row}_${col + 1}`);
    return !!next
}
export const getDetailStyleTypeSize = (sizeType) => {
    return {
        fontWeight: sizeType === "w_pre_joint" ? "bold" : "normal",
        fontStyle: sizeType === "saw" ? "italic" : "normal"
    }
}
export const getDetailOptionSizesOfType = (sizeType = 'saw') => {
    let keys = sizeType === 'saw' ? {x: 'x', y: 'y'} : sizeType === 'gabarit' ? {
        x: 'x1', y: 'y1'
    } : {x: 'x2', y: 'y2'}
    return keys
}


export const compareOrders = (storage, order) => {
    const cleanOrder = (ord) => {
        const {
            date,
            cutting_card,
            api_error_calc,
            firm_contact,
            firm_name,
            production_constants,
            user_name,
            versions,
            ...rest
        } = ord;
        return {...rest}
    };


    return JSON.stringify(cleanOrder(storage)).toUpperCase() === JSON.stringify(cleanOrder(order)).toUpperCase();
};

export const utf8ToB64 = (str) => {
    return btoa(unescape(encodeURIComponent(str)));
}

export const createFormData = (data) => {
    let formData = new FormData();
    for (const [key, value] of Object.entries(data)) {
        if (value || value === '' || value === 0) {
            formData.append(key, value)
        }
    }
    return (Object.keys(formData).length) ? false : formData
}

export const createFormDataArrayObj = (data) => {
    let formData = new FormData();
    for (let item = 0; item < data.length; item++) {
        for (const [key, value] of Object.entries(data[item])) {
            if (value || value === '') {
                formData.append(key, value)
            }
        }
    }
    return (Object.keys(formData).length) ? false : formData
}


export function numberWithSpaces(x) {
    return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, " ");
}


export function formatDate(date) {
    // Extracting date components
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Adding 1 to month because it's zero-based
    const day = String(date.getDate()).padStart(2, '0');
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    // Formatting the date
    const formattedDate = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`;

    return formattedDate;
}