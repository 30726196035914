import {changeLoaderStatusAC} from "../../reducers/CommonReducer";
import {UserAddressesApi} from "../../../api/UserAddresses/UserAddressesApi";
import {dispatcherErrorThunk} from "../common-thunk";
import {setUserAddressDeliveryEntriesActions} from "../../actions/user-address-action";

export const getUserAddressesDeliveryThunk = () => async (dispatch: any, getState: any) => {
    try {
        dispatch(changeLoaderStatusAC(true));
        const clientId = getState().auth.user.client_id;
        const result = await UserAddressesApi.getUserAddresses(clientId);
        if (result?.error && result?.error?.length > 0) {
            dispatch(dispatcherErrorThunk({error: result.error}));
            return;
        }
        dispatch(setUserAddressDeliveryEntriesActions(result.addresses));
        dispatch(changeLoaderStatusAC(false));


    } catch (error) {
        dispatch(dispatcherErrorThunk(error));
    }
}