import React from "react";
import "./InputForm.css";

const InputForm = ({
                       type = "text",
                       name,
                       htmlFor,
                       onChange,
                       onBlur,
                       placeholder,
                       cName = "",
                       label,
                       required,
                       value,
                       error = "",
                       cNameField = "",
                       ...rest
                   }) => {
    const classFormField = `form-field ${!!error ? " error " : ""} ${cNameField}`
    const classInput = `form-input ${cName} `;
    return (
        <div className={classFormField}>
            {label && <label className="label" htmlFor={name}>
                {label}
            </label>}
            <input
                name={name}
                type={type}
                className={classInput}
                placeholder={placeholder}
                required={required}
                onChange={onChange}
                onBlur={onBlur}
                value={value ? value : ''}
                {...rest}
            />
        </div>
    );
};

export default InputForm;
