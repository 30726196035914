import React from 'react';
import {IGetT} from "../../../Boxes/interfaces";
import NotificationIBoxItem from "./NotificationIBoxItem";


// {
//     "id": 12,
//     "code": "QXw7kz",
//     "info": "#51_2_100",
//     "name": " №7 Плита_тильня",
//     "x": 787,
//     "y": 1413,
//     "z": 18,
//     "alerts": [
//     "[3 -> info] !ru:Расстояние до предыдущей точки менее минимально желательного. [4.2426406871193 -> 6.8]",
//     "[5 -> info] !ru:Расстояние до предыдущей точки менее минимально желательного. [4.2426406871193 -> 6.8]"
// ],
//     "error": [
//     "[3 -> info] !ru:Радиус меньше минимального. [3 -> 3.9]",
//     "[5 -> info] !ru:Радиус меньше минимального. [3 -> 3.9]"
// ]
// }
interface NotificationItem {
    id: number;
    code: string,
    info: string
    name: string
    x: number
    y: number
    z: number
    alerts: string[],
    error: string[],
}

interface NotificationWrapperProps {
    notifications: NotificationItem[] | null,
    onOpenOperation: (partId: number, code: string) => void,
    getT: IGetT
}

const FrezNotificationWrapper: React.FC<NotificationWrapperProps> = ({notifications, onOpenOperation, getT}) => {
    if (!Array.isArray(notifications)) return null;
    return (
        <div className="notification-wrapper">
            {notifications
                .map(item => {
                    const error = Array.isArray(item.error) ? item.error : [];
                    const alerts = Array.isArray(item.alerts) ? item.alerts : [];
                    return (
                        <div key={item.id}>
                            <NotificationIBoxItem header={
                              <div className="">
                                  <h5 className={"mb-0"}>[#{item.id}] {getT('Деталь')}: {item.name} ({item.x} x {item.y} x {item.z}) </h5>
                                  <strong className={"text-success fs-12"}>{getT("Фрезеровка")} - {item.info} [{item.code}]</strong>
                              </div>

                            } errors={error} alerts={alerts}
                                                  onClick={() => {
                                                  onOpenOperation(item.id, item.code);
                                              }}/>
                        </div>
                    )
                })}
        </div>
    );
};

export default FrezNotificationWrapper;