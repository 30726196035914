import React from 'react';

interface IFieldInputFormula {
    required?: boolean;
    name: string;
    value: string | number;
    onChange: (e: React.ChangeEvent<HTMLSelectElement>) => void;
    error?: string | null;
    className?: string;
    children?: React.ReactNode;
    size?: "small" | "medium" | "large";
}

const FieldSelect: React.FC<IFieldInputFormula> = ({
                                                      required = false,
                                                      error,
                                                      onChange,
                                                      name,
                                                      className,
                                                    children, size = "medium",
                                                  }) => {
    const cName = `form-field ${className} ${error ? "error" : ""} `;
    const classNameSelect = `form-input ${size}`;

    return (
        <>
            <div className={cName}>
                <select required={required} name={name}   onChange={(e) => onChange(e)}
                        className={classNameSelect}>
                    {children}

                </select>

            </div>
            {/*{error && error?.length > 0 && (<ErrorFieldMessage>{error}</ErrorFieldMessage>)}*/}
        </>
    );
};

export default FieldSelect;