import MainApi from "../../../../api/MainApi";

const api_main = new MainApi();
const _uri_api = api_main._baseIfurn;

async function isUrlAvailable(url: string): Promise<boolean> {
    try {
        const response = await fetch(url, {
            method: 'GET',
            redirect: 'follow',
            referrerPolicy: 'unsafe-url',
        }); // Отправляем HEAD-запрос
        return response.ok; // true, если статус 200-299
    } catch (error) {
        return false; // Ошибка означает, что URL недоступен
    }
}
/**
 * Получить SVG  пазования.
 *
 * @param d1 - отступ от стороны.
 * @returns html svg
 */
async function getSvgResult(d1: number) {
    const svgPath = d1 > 0 ? "/frez_pics/paz.svg" : "/frez_pics/chet.svg";
    const svgPathLocal = d1 > 0 ? "/image/paz/paz.svg" : "/image/paz/chet.svg";

    const urlSvgServer = _uri_api + svgPath;
    const isAvailableUrl = await isUrlAvailable(urlSvgServer);
    const url = isAvailableUrl ? urlSvgServer : svgPathLocal;

    const response = await fetch(url, {
        method: "GET",
        redirect: 'follow',
        referrerPolicy: 'unsafe-url',
    });

    return await response.text();
}

/**
 * Генерирует SVG для операции пазования детали.
 * `part.operations.paz` может содержать как операции, так и шаблоны.
 *
 * @param part - Объект детали с операциями пазования.
 * @returns Обновленный объект детали с добавленным закодированным SVG-изображением для каждой операции пазования.
 */
async function fmSvgToPaz(part: any) {
    try {
        const partPazUpdate = await Promise.all(
            part.operations.paz.map(async (paz: any, index: number) => {
                // Дождаться текста перед заменой
                const keyD = paz?.hasOwnProperty('d1') ? 'd1' : 'd';
                const d1 = paz?.[keyD] ? paz[keyD] : 0;
                let svg = await getSvgResult(+d1);
                svg = svg
                    .replace("*126", d1 ?? '')
                    .replace("*125", paz?.z ?? '')
                    .replace("*225", part?.z ?? '')
                    .replace("*025", paz?.w_paz ?? '')
                    .replace("*325", ["t", "b"].includes(paz.side_from) ? part.x1 : part.y1);
                return {...paz, picture: btoa(unescape(encodeURIComponent(svg)))};
            }));

        return {...part, operations: {...part.operations, paz: partPazUpdate}};
    } catch (e) {
        console.error(e)
        return part
    }
}

/**
 * Генерирует SVG только для шаблонов пазования.
 *
 * @param templates - Массив шаблонов пазования.
 * @returns Обновленный массив шаблонов с добавленным закодированным SVG-изображением.
 */
async function fmSvgToTemplates(templates: any[]) {
    try {
        const templatePaz = await Promise.all(
            templates.map(async (temp: any, index: number) => {
                // Дождаться текста перед заменой
                const keyD = temp?.hasOwnProperty('d1') ? 'd1' : 'd';
                const d1 = temp?.[keyD] ? temp[keyD] : 0;
                let svg = await getSvgResult(+d1);
                svg = svg
                    .replace("*126", d1 ?? '')
                    .replace("*125", temp?.z ?? '')
                    .replace("*225", '*')
                    .replace("*025", temp?.w_paz ?? '')
                    .replace("*325", "*");
                return {...temp, picture: btoa(unescape(encodeURIComponent(svg)))};
            }));

        return templatePaz;
    } catch (e) {
        console.error(e)
        return templates
    }
}


export const utilPazConvertSvg = {
    getSvgByPart: async (part: any) => {
        return await fmSvgToPaz(part);
    },
    getSvgForTemplates: async (template: any[]) => {
        return await fmSvgToTemplates(template);
    },

}
