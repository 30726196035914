import {utilsTypeof} from "../../../helpers/utils-typeof";
import "./tab-base.css"
import button from "../../../components/ui/Button";

interface ITabBase {
    children?: React.ReactNode;
}

const TabBase = ({children}: ITabBase) => {

    return (
        <div className="tab-base-contaier">
            {children}
        </div>
    )

}


TabBase.TabWrapItem = ({children}: { children?: React.ReactNode; }) => {
    return (
        <div className="tab-base-wrapper-item">
            {children}
        </div>
    )
}

// Компонент для рендеринга контента вкладки (TabPanel)
interface ITabPanel<T> {
    data: T[];
    renderItem: (item: T, index: number) => React.ReactNode;

}

TabBase.TabPanel = <T, >({data, renderItem}: ITabPanel<T>) => {
    return (
        <div className="tab-base-panel-wrapper">
            {data.map((item, index) => {

                return renderItem(item, index)
            })}
        </div>
    );
};

interface TabPanelItem {
    onClick: (event: React.MouseEvent) => void;
    children?: React.ReactNode;
    isActive?: boolean;
}

TabBase.TabPanelItem = ({onClick, children, isActive = false}: TabPanelItem) => {
    const className = `tab-base-panel ${isActive ? 'active' : ''}`;
    return (
        <button className={className} type={"button"} onClick={onClick}>{children}</button>

    );
};
export default TabBase;