import {CONSTANT_PART_SIZE_GABARIT} from "../../constants";
import {
    getPartSizesThunk,
    INITIAL_SELECTED_MATERIALS_GOODS_OF_DETAILING,
    onBeforeOpenOperationChangePartSizeType,
    onToggleSelectedMaterialGoodsIdOfDetailing,
    togglePageOperationPartsError
} from "../reducers/OrderReducer";
import {
    cleanBoreForm,
    cleanCutForm,
    cleanHemForm,
    cleanPazForm,
    onResetOperationsSelected,
    setOpeningDetailsFromPart,
    setOperationsPartImageDataAC
} from "../reducers/OperationsReducer";
import {changeOperationsModalValue} from "../reducers/ModalReducer";
import {filteredPartsByMaterialId} from "../../helpers/part/partHelpers";
import {utilsTypeof} from "../../helpers/utils-typeof";
import {modalToggleFrezErrorsProjectModalAc} from "../actions/modal-action";
import {getExtendFrezeOperationsFoEditAsideThunk} from "./extendFrezOperations/extend-frez-move-operations-thunk";
import {frezOperationCreateModalAC} from "../actions/extended-frez-operation-actions";
import {dispatcherErrorThunk} from "./common-thunk";
import {changeLoaderStatusAC} from "../reducers/CommonReducer";

/**
 * closeOperationDetailingPageThunk.Закрыть страницу операции
 *
 * @param {object} part - активная деталь
 */
export const closeOperationDetailingPageThunk = (part) => (dispatch, getState) => {
    try {
        const order = getState().order;
        const parts = order.order.part;
        const selectedMaterialGoodsIdOfDetailing = order.selectedMaterialGoodsIdOfDetailing;
        let timesPartSize = getState().order.order.beforeChangePartSize
        let isActivateChangePartSize = timesPartSize ? !(timesPartSize === CONSTANT_PART_SIZE_GABARIT) : false;
        dispatch(onBeforeOpenOperationChangePartSizeType(isActivateChangePartSize, null, null, false, timesPartSize, () => {
            const partsByMaterial = filteredPartsByMaterialId(parts, part.material);
            const targetIndexPart = partsByMaterial.findIndex(item => Number(part?.id) === Number(item.id));
            const pageNumber = Math.ceil((targetIndexPart + 1) / selectedMaterialGoodsIdOfDetailing?.itemsPerPage);

            dispatch(onToggleSelectedMaterialGoodsIdOfDetailing({
                ...INITIAL_SELECTED_MATERIALS_GOODS_OF_DETAILING,
                goods_id: utilsTypeof.isValidNumber(part.material) ? part.material : null,
                pagination_page: utilsTypeof.isValidNumber(pageNumber) ? pageNumber : 1,
            }));
            dispatch(setOpeningDetailsFromPart({partId: part.id, goodsId: part.material}));
            dispatch(onResetOperationsSelected());
            dispatch(setOperationsPartImageDataAC(null));
            dispatch(changeOperationsModalValue("aside", null));

        }));
    } catch (e) {

    }
}
/**
 * toggleDetailSelectionInPageThunk. Переключить деталь на странице операции.
 * Нужно скрыть aside и очистить формы, так как формы для операций общие.
 *
 * @param {object} partSelect - Деталь
 */
export const toggleDetailSelectionInPageThunk = (partSelect) => dispatch => {
    try {
        // dispatch(getPartSizesThunk(partSelect));
        dispatch(changeOperationsModalValue("part", partSelect.id));
        dispatch(togglePageOperationPartsError());
        dispatch(cleanCutForm())
        dispatch(cleanHemForm())
        dispatch(cleanPazForm())
        dispatch(cleanBoreForm())
        dispatch(changeOperationsModalValue("aside", null))
    } catch (e) {

    }
}

export const openFrezDetailWithErrorThunk = (partId, code) => async (dispatch, getState) => {
    const order = getState().order;
    const parts = order.order.part;
    const partSize = order.order.partSizesType;
    const isActivateChangePartSize = partSize === CONSTANT_PART_SIZE_GABARIT;
    const operationModal = getState().modal.operations;

    try {
        dispatch(changeLoaderStatusAC(true))
        const targetPart = parts.find(part => Number(part?.id) === Number(partId));
        const targetOperationFrez = targetPart?.operations?.frez?.find(frez => frez.code === code);

        if (!targetPart || !targetOperationFrez) {
            throw new Error("There is no target operation frez by detail");
        }
        if (!operationModal.isOpen) {
            await dispatch(onBeforeOpenOperationChangePartSizeType(!isActivateChangePartSize, partSize, targetPart, true))
        }
        await dispatch(getExtendFrezeOperationsFoEditAsideThunk(targetPart, targetOperationFrez))
        dispatch(modalToggleFrezErrorsProjectModalAc(false, null))
        dispatch(changeLoaderStatusAC(false))
    } catch (e) {
        dispatch(dispatcherErrorThunk(e));
    }
}