import React from 'react';

const FieldWrap = ({label, children, error}) => {

    if (!children) return null
    let cName = `form-field  ${error ? "error" : ""}`;
    return (
        <div className={cName}>
            {label ? <label className={'label'}> {label}</label> : null}
            {children ? children : null}
        </div>
    );
};

export default FieldWrap;