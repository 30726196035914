import React, {useEffect, useState} from 'react';
import PazSvgImage from "./PazSvgImage";
import {utilPazConvertSvg} from "../../helpers/utils/operations/paz/utilPazConvertSvg";

interface IProps {
    part: any,
    operationPaz: any
    width?: string
    isScaleHover?: boolean
    className?: string
}

const PazImageLoader: React.FC<IProps> = ({part, operationPaz, width, isScaleHover, className}) => {
    const [picturePaz, setPicturePaz] = useState(null);
    useEffect(() => {
        const fetchSvgData = async () => {
            try {
                const updatedPart = await utilPazConvertSvg.getSvgByPart({
                    ...part,
                    operations: {paz: [operationPaz]}
                });
                const picture = updatedPart.operations.paz?.[0]?.picture;
                setPicturePaz(picture);
            } catch (error) {
                console.error(error);
            }
        };

        fetchSvgData();
    }, [operationPaz, part]); // Запрос будет выполняться, когда изменится part или form

    return <PazSvgImage className={className} src={picturePaz} key={operationPaz} width={width}
                        isScaleHover={isScaleHover}/>;
};

export default PazImageLoader;