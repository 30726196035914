import {
    IAddressDeliveriesVariants,
    IUserAddress,
    UserAddressBaseForm
} from "../../../types/UserAddressesForms/FormFieldTypes";
import {AnyAction} from "redux";
import {
    USER_ADDRESS_DELIVERY_EDIT_DATA_MODAL,
    USER_ADDRESS_DELIVERY_ENTRIES_DATA,
    USER_ADDRESS_SET_FORMS,
    USER_ADDRESS_SET_ORDER_DELIVERY_VARIANT
} from "../../actions/user-address-action";

export interface IUserAddressesState {
    forms: null | UserAddressBaseForm[]
    addresses: IUserAddress[] | null,
    editAddressModal: {
        deliveryForm: IUserAddress | null,
        isOpen: boolean
    },
    orderDeliveryVariants:IAddressDeliveriesVariants | null
}

const INITIAL_STATE: IUserAddressesState = {
    forms: null,
    addresses: null,
    editAddressModal: {
        deliveryForm: null,
        isOpen: false
    },
    orderDeliveryVariants: null,

}

const userAddressesReducer = (state = INITIAL_STATE, action: AnyAction) => {
    switch (action.type) {
        case USER_ADDRESS_SET_FORMS: {
            return {...state, forms: action.payload}
        }
        case USER_ADDRESS_DELIVERY_ENTRIES_DATA: {
            return {...state, addresses: action.payload}
        }
        case USER_ADDRESS_DELIVERY_EDIT_DATA_MODAL: {
            return {...state, editAddressModal: action.payload}
        }
        case USER_ADDRESS_SET_ORDER_DELIVERY_VARIANT:{
            return {...state, orderDeliveryVariants: action.payload}
        }
        default: {
            return state
        }
    }
}

export default userAddressesReducer