import {IExternalErrorAddress} from "../../types/UserAddressesForms/UserAddressesInputOutput";

export const CHECKOUT_ADDRESS_DELIVERY_SET_FIELD_DATA = "CHECKOUT_ADDRESS/DELIVERY_SET_FIELD_DATA";
export type ICheckoutAddressDeliveryFieldDataAction = {
    valueForm: null | { [key: string]: string | number },
    formId: null | string | "new",
    externalErrors: IExternalErrorAddress | null,
    defaultIndexForm?: number | null
}

export const setCheckoutAddressDeliveryFieldDataAction = ({
                                                              valueForm = null,
                                                              formId = null,
                                                              externalErrors = null,
                                                              defaultIndexForm =null
                                                          }: ICheckoutAddressDeliveryFieldDataAction) => {
    return {
        type: CHECKOUT_ADDRESS_DELIVERY_SET_FIELD_DATA,
        payload: {
            valueForm, externalErrors, formId, defaultIndexForm: defaultIndexForm
        }
    }
}