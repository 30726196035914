import React from 'react';


interface IFieldInputFormula {
    required?: boolean;
    name: string;
    value: string | number;
    onChange: (e: any) => void;
    error?: string | null ;
    className?: string;
    type: "text" | "number";
    disabled?: boolean;
    placeholder?: string;
    size?: "small" | "medium" | "large";
}

const FieldInput: React.FC<IFieldInputFormula> = ({
                                                      value,
                                                      required = false,
                                                      error ,
                                                      onChange,
                                                      name,
                                                      className,
                                                      type = "text",
                                                      disabled = false,
                                                      placeholder = '',
                                                      size = "medium",
                                                  }) => {
    let cName = `form-field ${className} ${error ? "error" : ""}`;
    const classNameInput = `form-input ${size}`;
    return (
        <>
            <div className={cName}>
                <input type={type}
                       disabled={disabled}
                       className={classNameInput}
                       name={name}
                       required={required}
                       value={value}
                       onChange={(e) => onChange(e)}
                       placeholder={placeholder}/>
            </div>
            {/*{error && error?.length > 0 && (<ErrorFieldMessage>{error}</ErrorFieldMessage>)}*/}
        </>
    );
};

export default FieldInput;