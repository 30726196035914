import React from 'react';
import {classNames} from "react-select/dist/declarations/src/utils";

interface Props extends React.SVGProps<SVGSVGElement> {
    width?: number;
    height?: number;
    fill?: string;
    stroke?: string;
    className?: string;
}

const IconCheckedTick: React.FC<Props> = ({
                                              width = 20,
                                              height = 20,
                                              fill = "#11e70d",
                                              stroke = "#11e70d",
                                              className = '',
                                              ...rest // сюда попадает onClick, className и т. д.
                                          }) => {
    const classN = `icon icon-checked-tick ${className ?? ''}`;

    return (
        <svg
            width={width}
            height={height}
            fill={fill}
            stroke={stroke}
            className={classN}
            viewBox="0 0 496.512 496.512"
            xmlns="http://www.w3.org/2000/svg"
            {...rest} // передаем onClick и другие атрибуты
        >

            <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
            <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
            <g id="SVGRepo_iconCarrier">
                <g>
                    <g>
                        <path
                            d="M248.256,0c-136.96,0-248,111.152-248,248.256s111.04,248.256,248,248.256s248-111.152,248-248.256S385.216,0,248.256,0z M248.256,480.512c-127.92,0-232-104.192-232-232.256S120.336,16,248.256,16s232,104.192,232,232.256 S376.176,480.512,248.256,480.512z"></path>
                    </g>
                </g>
                <g>
                    <g>
                        <polygon
                            points="348.368,154.368 193.904,322.944 130.128,259.088 118.816,270.4 194.4,346.064 360.144,165.168 "></polygon>
                    </g>
                </g>
            </g>
        </svg>
    );
};

export default IconCheckedTick;