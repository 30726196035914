import {
    IAddressDeliveriesVariants,
    IUserAddress,
    UserAddressBaseForm,
} from "../../types/UserAddressesForms/FormFieldTypes";

export const USER_ADDRESS_SET_FORMS = 'USER_ADDRESS/SET_FORMS';
export const USER_ADDRESS_SET_ORDER_DELIVERY_VARIANT = 'USER_ADDRESS/SET_ORDER_DELIVERY_VARIANT';
export const USER_ADDRESS_DELIVERY_ENTRIES_DATA = 'USER_ADDRESS/DELIVERY_ENTRIES_DATA';
export const USER_ADDRESS_DELIVERY_EDIT_DATA_MODAL = 'USER_ADDRESS/DELIVERY_EDIT_DATA_MODAL';

export const userAddressSetFormsActions = (forms: UserAddressBaseForm | null) => {
    return {
        type: USER_ADDRESS_SET_FORMS,
        payload: forms,
    }
}




export const setUserAddressDeliveryEntriesActions = (addresses: IUserAddress[] | null) => {
    return {
        type: USER_ADDRESS_DELIVERY_ENTRIES_DATA,
        payload: addresses,
    }
}

export const editUserAddressModalActions = (deliveryForm: IUserAddress | null, isOpen: boolean) => {
    return {
        type: USER_ADDRESS_DELIVERY_EDIT_DATA_MODAL,
        payload: {
            deliveryForm: deliveryForm,
            isOpen: isOpen,
        }
    }
}

export const setOrderDeliveryVariantActions = (variants: IAddressDeliveriesVariants[] | null) => {
    return {
        type: USER_ADDRESS_SET_ORDER_DELIVERY_VARIANT,
        payload: variants,
    }
}