import React from 'react';

interface Props {
    className?: string;
    style?: React.CSSProperties;
    children?: React.ReactNode;
    title?: string;
    htmlFor?: string;
}

const Label: React.FC<Props> = ({className = "", style = {}, children, title = "", htmlFor = ""}) => {

    return <label htmlFor={htmlFor} className={`label ${className}`}>
        {title ? title : null}
        {children ? children : null}
    </label>
};

export default Label;