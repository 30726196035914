import {UserAddressFormFieldConfigType} from "../../../types/UserAddressesForms/FormFieldTypes";
import {useMemo} from "react";
import {
    selectInitialValuesUserAddressesDeliveryStrategy
} from "../../../shared/stategy/initialValuesUserAddressDeliveryStrategies";


export default function useUserAddressFormBuilder(typeDeliveryForm: UserAddressFormFieldConfigType) {
    // Получаем поля формы из typeDeliveryForm
    const fields = typeDeliveryForm.form;

    // Устанавливаем начальные значения для формы
    const initialValues = useMemo(() => {

        return selectInitialValuesUserAddressesDeliveryStrategy(typeDeliveryForm);
    }, [typeDeliveryForm]);

// Возвращаем поля формы и начальные значения
    return {fields, initialValues};
}
