import {
    changeAlertStatusAC,
    changeLoaderMessageAC,
    changeLoaderStatusAC,
    checkErrorsAPI,
    getCheckProjectErrorsThunk,
    setProjectCalcErrorsAC,
    typeErrorApi,
    wrapperSendCalcShoppingGb
} from "./CommonReducer";
import ApiService from "../../api/api";
import {
    changeAddPartModalValue,
    changeBatchMachiningModalValue,
    changeCheckProjectModalValue,
    changeMemoryDTOrderProjectModalValue,
    changeOperationsModalValue,
    changeShopingCardModalValue,
    changeUploadProjectModalValue,
    onTogglePostformingModal
} from "./ModalReducer";
import {
    changeMirroringTypeInPart,
    compareOrders,
    getTranslateMessageAPI,
    manipulateSessionCalculate,
    random
} from "../../helpers/helpers";
import ApiImages from "../../api/apiImages";
import {
    _constructorBore,
    _constructorCutTo,
    _constructorHem,
    _constructorPaz,
    _constructorTrough,
    getFirmToolsThunk,
    onOperationToggleFilter,
    onSetOperationPartsError
} from "./OperationsReducer";
import {
    CONSTANT_PART_SIZE_GABARIT,
    GLASS_ID_TYPE_MATERIAL,
    isIncludeChangePartSizeBeforeOpenOperation,
    LS_FIRM,
    LS_IMAGES_INTERNAL,
    SELECT_OPTIONS_PHONE
} from "../../constants";
import alert from "../../components/Alert/Alert";
import {actionsCuttingChart} from "./CuttingChartReducer";
import {setManagerAuthDataThunk} from "./AuthReducer";
import {gaEvents} from "../../gaEvents";
import {blueprints} from "../../blueprints/blueprints";
import {dispatcherErrorThunk, generationOfPackagingDispatched} from "../thunks/common-thunk";
import {findKeyByCodePhone} from "../../helpers/helpersListCountryCode";
import {ORDER_MATERIAL_TOGGLE_IS_BAND_CONNECTED} from "../actions/order_material";
import {orderSetGoodServicePartThunk} from "../thunks/order-thunk";
import {
    ORDER_MULTI_CHOICE_MATERIAL_BAND_DELETE_IN_ORDER,
    ORDER_PART_SET_GOODS_SERVICE_TYPE,
    ORDER_REMOVE_ADDITIONAL_GOODS_SERVICE_FOR_ORDER,
    ORDER_SET_ADDITIONAL_GOODS_SERVICE_FOR_ORDER,
    ORDER_SET_ADDITIONAL_GOODS_SERVICES,
    ORDER_TOGGLE_CURRENCY_GOODS_SERVICE_FOR_ORDER_TYPE,
    ORDER_TOGGLE_CURRENCY_GOODS_SERVICE_TYPE
} from "../actions/order-actions";
import {getCalculateAdditionalGoodsServiceOfPart} from "../../helpers/goods_service_order_part";
import {calculateNumberOfProfilesHandlerAndEdge, getFetchingProcessingEdgeData} from "../thunks/processing-edge-thunk";
import {
    ORDER_PART_CHANGE_PROCESSING_EDGE,
    ORDER_PART_CHANGE_PROCESSING_EDGE_SIDE_IN_PART,
    ORDER_PART_OPERATION_CHANGE_FREZ_INFO
} from "../actions/order-part-action";
import {processingEdgeSetPointAC} from "../actions/processing-edge-action";
import {INITIAL_DEFAULT_PART_EDGE_EDIT} from "../constats/processing-edge-const";
import {getCalculateShippingProcessingEdge} from "../../helpers/processing-edge-helpers";
import {getUiIdForPart} from "../../helpers/part/partHelpers";
import {cleanCncPartMapping} from "../../helpers/cleanCncPartMapping";
import {getUpdateKeyEdgeSettingsAfterFrezRemoval} from "../../helpers/helpers-operations";
import {getAllAdvancedGoodsServiceByOrderIdThunk} from "../thunks/advansed-goods-service-thunk";
import {utilLogger} from "../../helpers/util-logger";
import {getMachineToolPostProcessorFilesThunk} from "../thunks/machine-tool-post-processor-files-thunk";
import {isNewgoFrezOperation} from "../../pages/Operations/helpers/operations-helpers";
import {mappingPartialPart} from "../../api/helpers/transformPartialsProject/mappingPartialPart";
import {CHECKOUT_ADDRESS_DELIVERY_SET_FIELD_DATA} from "../actions/checkout-address-delivery-action";
import {UserAddressesApi} from "../../api/UserAddresses/UserAddressesApi";
import {pazTemplateCleanByPartIdMapping} from "../../helpers/utils/operations/pazTemplateCleanByPartIdMapping";
import {utilsTypeof} from "../../helpers/utils-typeof";


const CHANGE_ORDER_PRODUCTION_CONST = "PRODUCTION/CHANGE_ORDER_PRODUCTION_CONST";
const FILTER_PARTS_OF_MATERIAL = "TAB_HOME/FILTER_PARTS_OF_MATERIAL";
const SELECTED_MATERIAL_GOODS_ID_OF_DETAILING = "TAB_HOME/SELECTED_MATERIAL_SELECTED_MATERIAL_GOODS_ID_OF_DETAILING";

const SET_INSTRUCTION_BOX = "SET_INSTRUCTION_BOX";
const SET_PROJECT_DATA = "SET_PROJECT_DATA";
const CHANGE_ORDER_TITLE = "CHANGE_ORDER_TITLE";
const ADD_ORDER_MATERIAL = "ADD_ORDER_MATERIAL";
const ADD_ORDER_BOX = "ADD_ORDER_BOX";
const ADD_ORDER_BAND = "ADD_ORDER_BAND";
const ADD_ORDER_GOODS = "ADD_ORDER_GOODS";
const CHANGE_ADD_PART_FORM_VALUE = "CHANGE_ADD_PART_FORM_VALUE";
const ADD_NEW_PART = "ADD_NEW_PART";
const ADD_NEW_ARRAY_PARTS = "ADD_NEW_ARRAY_PARTS";
const ADD_NEW_PART_BOX = "ADD_NEW_PART_BOX";
const SET_PARTS_PREVIEWS = "SET_PARTS_PREVIEWS";
const SET_PART_PREVIEW_ITEM = "SET_PART_PREVIEW_ITEM";
const SET_CUTTING_PREVIEWS = "SET_CUTTING_PREVIEWS";
const NOT_FETCHING_IMG = "NOT_FETCHING_IMG";
const CHANGE_PART_DATA = "CHANGE_PART_DATA";
const UPDATE_ALL_PART = "PART/UPDATE_ALL";
const PART_CHANGE_ALL_DATA = "PART/CHANGE_ALL_DATA";
const ADD_NEW_BORE = "ADD_NEW_BORE";
const ADD_NEW_PAZ = "ADD_NEW_PAZ";
const ADD_NEW_HEM = "ADD_NEW_HEM";
const ADD_NEW_CUT_TO = "ADD_NEW_CUT_TO";
const ADD_NEW_TROUGH = "ADD_NEW_TROUGH";
const CHANGE_OPERATION_DATA = "CHANGE_OPERATION_DATA";
const DELETE_OPERATION_DATA = "DELETE_OPERATION_DATA";
const DELETE_All_OPERATION_DATA = "DELETE_All_OPERATION_DATA";
const SET_PART_WITH_SIZES = "SET_PART_WITH_SIZES";
const ADD_NEW_TEMPLATE_OPERATIONS = "ADD_NEW_TEMPLATE_OPERATIONS";
const EDIT_NEW_TEMPLATE_OPERATIONS = "EDIT_NEW_TEMPLATE_OPERATIONS";
const CLEAR_CALCULATE_VALS_DATA = "CLEAR_CALCULATE_VALS_DATA";
const CLEAR_ALL_CALCULATE_VALS_DATA = "CLEAR_ALL_CALCULATE_VALS_DATA";
const SET_ORDER_CALCULATE_DATA = "SET_ORDER_CALCULATE_DATA";
const CHANGE_MATERIALS_CALCULATE_TYPE = "CHANGE_MATERIALS_CALCULATE_TYPE";
const CHANGE_MATERIALS_CALCULATE_RES_TO_CLIENT = "CHANGE_MATERIALS_CALCULATE_RES_TO_CLIENT";
const CHANGE_ORDER_CALCULATE_DISCOUNTS_DATA = "CHANGE_ORDER_CALCULATE_DISCOUNTS_DATA";
const CHANGE_ORDER_SIZE_TYPE = "CHANGE_ORDER_SIZE_TYPE";
const SET_PARTS_WITH_SIZES = "SET_PARTS_WITH_SIZES";
const CHANGE_BANDS_TYPE_DATA = "CHANGE_BANDS_TYPE_DATA";
const COPY_ORDER_PART_DATA = "COPY_ORDER_PART_DATA";
const DELETE_ORDER_PART_DATA = "DELETE_ORDER_PART_DATA";
const DELETE_ORDER_PART_BOX_DATA = "DELETE_ORDER_PART_BOX_DATA";
const ADD_ORDER_PRODUCT_DATA = "ADD_ORDER_PRODUCT_DATA";
const DELETE_ORDER_PRODUCT_DATA = "DELETE_ORDER_PRODUCT_DATA";
const CHANGE_FOR_SALE_MATERIALS_COUNT = "CHANGE_FOR_SALE_MATERIALS_COUNT";
const DELETE_GOODS_ORDERS_DATA = "DELETE_GOODS_ORDERS_DATA";
const DELETE_MATERIALS_ORDER_DATA = "DELETE_MATERIALS_ORDER_DATA";
const DELETE_ALL_MATERIALS_BANDS_ORDER_DATA = "DELETE_ALL_MATERIALS_BANDS_ORDER_DATA";
const DELETE_BANDS_ORDER_DATA = "DELETE_BANDS_ORDER_DATA";
const DELETE_ORDER_BOX = "DELETE_ORDER_BOX";
const CHANGE_PRODUCTS_VALUES_DATA = "CHANGE_PRODUCTS_VALUES_DATA";
const SET_BANDS_CODES_DATA = "SET_BANDS_CODES_DATA";
const CHANGE_ORDER_MATERIAL_VALUE_DATA = "CHANGE_ORDER_MATERIAL_VALUE_DATA";
const CHANGE_ORDER_CONFIRM_FORM_VALUE_DATA = "CHANGE_ORDER_CONFIRM_FORM_VALUE_DATA";
const CHANGE_DATA_ORDER_CONFIRM_FORM_VALUE_DATA = "CHANGE_DATA_ORDER_CONFIRM_FORM_VALUE_DATA";
const CHANGE_ORDER_URGENT_DATA = "CHANGE_ORDER_URGENT_DATA";
const CHANGE_ORDER_UNIVERSAL_DATA = "CHANGE_ORDER_UNIVERSAL_DATA";
const CHANGE_ORDER_MATERIAL_ADDITIONAL_DATA = "CHANGE_ORDER_MATERIAL_ADDITIONAL_DATA";
const CHANGE_ORDER_BAND_ADDITIONAL_DATA = "CHANGE_ORDER_BAND_ADDITIONAL_DATA";
const CHANGE_ORDER_CURRENCY_DATA = "CHANGE_ORDER_CURRENCY_DATA";
const SET_PRODUCTION_CONSTANTS_DATA = "SET_PRODUCTION_CONSTANTS_DATA";
const ADD_NEW_ORDER_EXTRA_DATA = "ADD_NEW_ORDER_EXTRA_DATA";
const DELETE_ORDER_EXTRA_DATA = "DELETE_ORDER_EXTRA_DATA";
const CHANGE_FREZ_OPERATION_BANDS_DATA = "CHANGE_FREZ_OPERATION_BANDS_DATA";
const CHANGE_FREZ_OPERATION_GLASS_EDGE_TYPE_DATA = "CHANGE_FREZ_OPERATION_GLASS_EDGE_TYPE_DATA";
const SET_DEFAULT_MATERIAL_ADITIONAL_DATA = "SET_DEFAULT_MATERIAL_ADITIONAL_DATA";
const SET_DEFAULT_BAND_ADITIONAL_DATA = "SET_DEFAULT_BAND_ADITIONAL_DATA";

const COPY_OPERATION_ITEM = "COPY_OPERATION_ITEM";
const MIRRORING_OPERATION_ITEM = "MIRRORING_OPERATION_ITEM";
const GLOBAL_CHANGE_MIRRORING = "GLOBAL_CHANGE_MIRRORING";
const MIRRORING_ACTIONS_OPERATION_ITEM = "MIRRORING_ACTIONS_OPERATION_ITEM";
const CLEAR_TRANSACTIONS_HISTORY_PART = "CLEAR_TRANSACTIONS_HISTORY_PART";

const CHANGE_TURNS_PART = "CHANGE_TURNS_PART";

const CHANGE_FOR_MATERIALS_PARTS_SALE_COUNT = "CHANGE_FOR_MATERIALS_PARTS_SALE_COUNT";

const CHANGE_BATCH_MACHINING_HOLES_FORM_VALUES = "CHANGE_BATCH_MACHINING_HOLES_FORM_VALUES";
const UPDATE_BATCH_MACHINING_HOLES_PART = "UPDATE_BATCH_MACHINING_HOLES_PART";
const RESET_BATCH_MACHINING_FORM = "RESET_BATCH_MACHINING_FORM";
const CLIENT_ON_CHANGE_MATERIAL_SIZES = "CLIENT_ON_CHANGE_MATERIAL_SIZES";
const CLIENT_ON_CHANGE_BAND_SIZES = "CLIENT_ON_CHANGE_BAND_SIZES";
const CLIENT_MATERIAL_CHANGE_COUNT = "CLIENT_MATERIAL_CHANGE_COUNT";
const ON_CLEAR_DETAIL_NOTES = "ON_CLEAR_DETAIL_NOTES";


const SUCCESS_SAVE_ORDER_PROJECT = "SUCCESS_SAVE_ORDER_PROJECT";
const INCONFORMOTY_DATA_MEMORY_STORE = "INCONFORMOTY_DATA_MEMORY_STORE";
const SET_ORDER_COPY = "SET_ORDER_COPY";
const SET_EDGE_TYPE_PART = "SET_EDGE_TYPE_PART";
const SET_ALL_EDGE_TYPE_PARTS = "SET_ALL_EDGE_TYPE_PARTS";
const ORDER_CHANGE_LANG = "APP/ORDER_CHANGE_LANG";

const ORDER_CHANGE_POSITION_INDEX_MATERIAL_BAND = "ORDER/CHANGE_POSITION_INDEX_MATERIAL_BAND";

const ORDER_DETAIL_UPDATE = "ORDER/DETAIL_UPDATE";
const ORDER_CURRENT_PART_SIZE_SAVE = "ORDER/CURRENT_PART_SIZE_CHANGE";
const MATERIAL_DRAG_PART = "MATERIAL_DRAG/PART";
// const GLOBAL_UPDATE_GR = "GLOBAL_UPDATE_GR";
const DELETE_PROJECT_ORDER_VERSION = "DELETE_PROJECT_ORDER_VERSION";

const PART_CU_INCLUDE_COMBINE_MATERIAL = "PART/CU_INCLUDE_COMBINE_MATERIAL";
const COMBINE_MATERIAL_CHANGE_POSITION_PART = "COMBINE_MATERIAL/CHANGE_POSITION_PART";
const CHANGE_COLOR_BAND = "BAND/CHANGE_COLOR";

const CHANGE_MATERIAL_OPTION_VALUE = "MATERIAL/CHANGE_OPTION_VALUE";
const CHANGE_CURRENT_MATERIAL_PARTS_EDGE_CONNECTED = "MATERIAL/CHANGE_CURRENT_PARTS_EDGE_CONNECTED";
const REMOVE_PRE_JOINT_CURVE_MATERIAL_PARTS_EDGE = "MATERIAL/REMOVE_PRE_JOINT_CURVE_MATERIAL_PARTS_EDGE";
// const CHANGE_PART_EDGE_COLOR = 'PART_EDGE/CHANGE_COLOR';


const REMOVE_ORDER_GOODS = "REMOVE_ORDER_GOODS";
const ADD_POSTFORM_OPERATION_FOR_PART = "PART/ADD_POSTFORM_OPERATION_FOR_PART";


const SET_CUTTING_CARD = "ORDER/SET_CUTTING_CARD";
const CUTTING_CARD_UPDATE_OWNER_UI_CUT_DROP_ID = "ORDER/CUTTING_CARD_UPDATE_OWNER_UI_CUT_DROP_ID";

const REMOVE_ALL_PARTS_IN_MATERIAL = "MATERIAL/REMOVE_ALL_PARTS";

const OPERATIONS_SELECTED_DELETE = "ORDER_PART/OPERATIONS_SELECTED_DELETE";

const MATERIAL_COMBINE_UPDATE_DEPENDENCIES = "MATERIAL_COMBINE/UPDATE_DEPENDENCIES";
const api = new ApiService();
const apiImages = new ApiImages();

export const _getItemID = (array) => {
    return [...array].length === 0 ? 1 : Math.max.apply(null, [...array].map((e) => e.id)) + 1;
};
export const _getBandCode = (id, bands) => {
    let code = null;
    [...bands].forEach((e, i) => {
        if (Number(id) === Number(e.goods_id)) {
            code = Number(i) + 1;
        }
    });
    return code;
};
export const _getBandGoodsIDS = (code, bands) => {


    let goods_id = null;

    [...bands].forEach((e, i) => {
        if (Number(code) - 1 === Number(i)) {
            goods_id = e.goods_id;
        }
    });

    return goods_id;
};
/**
 *  filtered details that will be displayed.
 *  this radio list value 0 - display all, 1 - with, -1 - without
 *  this checkbox list value true -  with, false - all
 *  this input or select  value null - all
 * @param {boolean}  - errors. toggle an display parts of has error
 * @param {object}  - cnc_values. пазы, выемки, фрезеровки, сверления
 * @param {object}  - operation_values. Сшивки/без сшивок/все (по умолчанию), урезка/без урезки/все, срез торца/без среза торца/все
 * @param {object}  - edging. Кромкование: прямолинейное/без прямолинейного/все, криволинейное/без криволинейного/все,  без прифуговки/все
 *
 */
export const INITIAL_FILTER_PARTS_OF_MATERIAL = {
    name: null,
    product: null,
    x: {min: null, max: null},
    y: {min: null, max: null},
    edge: null,
    errors: false, // отображать детали с ошибками
    has_checked_parts: false, // отображать выбраные детали
    has_info_parts: false, // отображать детали которые имеют описание
    // cnc: false,
    cnc_values: {
        bore: false,
        paz: false,
        trough: false,
        frez: false
    },
    operation_values: {
        hem: 0,
        cut_to: 0,
        srez: 0,
    },
    edging: {
        line: 0,
        curvilinear: 0,
        pre_join: 0,
    }


}

export const INITIAL_SELECTED_MATERIALS_GOODS_OF_DETAILING = {
    goods_id: null,
    pagination_page: 1,
    itemsPerPage: 20,
    checked_ids_part: []
}
let initialState = {
    order: null,
    additional_goods_service: null,
    filterPartsOfMaterial: INITIAL_FILTER_PARTS_OF_MATERIAL,
    selectedMaterialGoodsIdOfDetailing: INITIAL_SELECTED_MATERIALS_GOODS_OF_DETAILING,
    addPartForm: {
        name: random(1, 1, 1),
        material: null,
        x: "",
        y: "",
        count: ""
    },
    confirmAddressDelivery: {
        // type:
        valuesFrom: null, // ключ значение полей формы адерса
        formId: null, // id созданого адреса. Если поле = "new" То это "Добавить другой адрес" (которого нет в существующих)
        externalErrors: null, //ошибки по текущему адресу из valuesFrom
    },
    confirm: {
        name: "",
        surname: "",
        patronymic: "",
        comment: "",
        email: "",
        phone: "",
        phone1: "",
        phone2: "",
        region: "ua",
        phone_code: "+38 ",
        deliveryMethod: getTranslateMessageAPI("Самовывоз"),
        deliveryPoint_select: "",
        objectAdress: "",
        salePoint: "",
        productionPoint: "",
        arrPoint: "",
        region_code: "",
        firmPhone: "",
        pack: []
    },
    notFetchingImg: false,
    partsPreviewCutting: [],
    partsPreview: [],
    batchMachiningHolesForm: {
        material: [{
            goods_id: ""
        }],
        parts: [{id: ""}],
        side: "",
        replacement: "",
        depth_from: "",
        depth_to: "",
        plane_from: "",
        plane_to: "",
        diameter_to: "",
        diameter_from: "",
        operations: "bore"

    },
    successSaveOrder: false,
    memoryChangeDtOrder: false,
    orderCopy: null,
    beforeChangePartSize: null
};
export const _constructorPartSizes = (partSizesType, x, y) => {
    return {
        x: partSizesType === "saw" ? x : null,
        y: partSizesType === "saw" ? y : null,
        x1: partSizesType === "gabarit" ? x : null,
        y1: partSizesType === "gabarit" ? y : null,
        x2: partSizesType === "w_pre_joint" ? x : null,
        y2: partSizesType === "w_pre_joint" ? y : null
    };
};
export const _getPartSizes = (partSizesType, part) => {
    switch (partSizesType) {
        case "saw":
            return {
                x: part.x,
                y: part.y
            };
        case "gabarit":
            return {
                x: part.x1,
                y: part.y1
            };
        case "w_pre_joint":
            return {
                x: part.x2,
                y: part.y2
            };
    }
};
const _constructorPartBand = (pre_joint) => {
    return {
        db_id: null,
        cut: null,
        t: null,
        w: null,
        laz: null,
        eva: null,
        pur: null,
        pre_joint: pre_joint
    };
};
export const _partGlassConstructor = (type_material) => {
    if (Number(type_material) !== GLASS_ID_TYPE_MATERIAL) return {};
    return {
        steklo: {
            l: {f: 0, p: 0, s: 0, curve: 0, w: 10},
            r: {f: 0, p: 0, s: 0, curve: 0, w: 10},
            b: {f: 0, p: 0, s: 0, curve: 0, w: 10},
            t: {f: 0, p: 0, s: 0, curve: 0, w: 10}
        }
    };
};
const _constructorPart = (id, partSizesType, material, pre_joint, form) => {
    return Object.assign({
        id: id,
        name: (form.name || form.name === "") ? form.name : "#" + random(1, 1, 1),
        count: form.count ? Number(form.count) : "",
        z: Number(material.z),
        material: Number(form.material),
        material_z: Number(material.z),
        texture: form.hasOwnProperty("texture") ? form.texture : 1,
        edge: {
            t: _constructorPartBand(pre_joint),
            b: _constructorPartBand(pre_joint),
            l: _constructorPartBand(pre_joint),
            r: _constructorPartBand(pre_joint)
        },
        edge_edit: {...INITIAL_DEFAULT_PART_EDGE_EDIT},
        srez: {
            t: {
                otstup: "",
                ugol: "",
                db_id: ""
            },
            b: {
                otstup: "",
                ugol: "",
                db_id: ""
            },
            l: {
                otstup: "",
                ugol: "",
                db_id: ""
            },
            r: {
                otstup: "",
                ugol: "",
                db_id: ""
            }
        },
        operations: {
            bore: [],
            paz: [],
            frez: [],
            hem: [],
            cut_to: [],
            trough: []
        },
        operations_inputs: {
            bore: [],
            paz: [],
            frez: [],
            hem: [],
            cut_to: [],
            trough: []
        },
        ..._partGlassConstructor(material.type_material),
        plane_operations: [],
        box: null,
        product: null,
        info_user: null
    }, _constructorPartSizes(partSizesType, form.x, form.y));
};

const checkOperationsAddStatus = (operations, added_operations) => {
    const count_now = operations ? operations.length : 0;
    const count_new = added_operations ? added_operations.length : 0;
    return Number(count_now) + Number(count_new) < 101;
};
const _addTemplateOperations = (operations, type, form, part, side) => {
    let new_operation_id = _getItemID(part.operations[type]);
    operations[type].map((o_frez) => {
        ++new_operation_id;
        o_frez.inputs = form;
        o_frez.id = new_operation_id;
        if (!o_frez.side) {
            o_frez.side = side;
        }
    });

    form.xnc_template = operations[type][0]?.xnc_template ?? operations[type][0]?.new_xnc_template;
    form.id = operations[type][0].id;

    if (!checkOperationsAddStatus(part.operations[type], operations[type])) {
        alert("Разрешено только 100 операций на одну деталь!");
    } else {
        part.operations[type] = part.operations[type].concat(operations[type]);
        part.operations_inputs[type] = part.operations_inputs[type].concat(form);
    }

    return part;
};

const _ediTemplateOperations = (operations, type, form, part, side, frezId) => {
    let operationsUpdate = part.operations[type]?.filter(e => Number(e?.id) !== Number(frezId));
    let new_operation_id = _getItemID(operationsUpdate);
    operations[type].map((o_frez) => {
        ++new_operation_id;
        o_frez.inputs = form;
        o_frez.id = new_operation_id;
        if (!o_frez.side) {
            o_frez.side = side;
        }
    });
    if (operations[type]?.[0]?.xnc_template || operations[type]?.[0]?.new_xnc_template) {
        form.xnc_template = operations[type][0]?.xnc_template ?? operations[type][0]?.new_xnc_template;
    }

    form.id = operations[type][0].id;

    if (!checkOperationsAddStatus(part.operations[type], operations[type])) {
        alert("Разрешено только 100 операций на одну деталь!");
    } else {
        part.operations[type] = operationsUpdate.concat(operations[type]);
        part.operations_inputs[type] = part.operations_inputs[type]?.filter(e => Number(e?.id) !== Number(frezId)).concat(form);
    }

    return part;
};

const _constructorCalculateDiscountsAndTypes = (orderData, calculateData, type) => {
    return orderData.length === 0 ? calculateData.map(e => {
        switch (type) {
            case "calculate_types":
                return {
                    "id": e.id,
                    "calc_type": e.calc_type
                };
            case "calculate_discounts_service":
                return {
                    "id": e.service_id,
                    "price": null,
                    "currency": null,
                    "isOpen": false
                };
            default:
                return {
                    "id": e.id,
                    "price": null,
                    "currency": null,
                    "isOpen": false
                };
        }

    }) : orderData.concat(calculateData.filter(e => {
        let check = true;
        orderData.forEach(y => {
            if (Number(e.id) === Number(y.id)) {
                check = false;
            }
        });
        return check;
    }).map(e => {
        switch (type) {
            case "calculate_types":
                return {
                    "id": e.id,
                    "calc_type": e.calc_type
                };
            case "calculate_discounts_service":
                return {
                    "id": e.service_id,
                    "price": null,
                    "currency": null,
                    "isOpen": false
                };
            default:
                return {
                    "id": e.id,
                    "price": null,
                    "currency": null,
                    "isOpen": false
                };
        }
    }));
};

export const onChangeColorBandAC = ({band, color}) => {
    return {
        type: CHANGE_COLOR_BAND,
        payload: {band, color}
    };
};
export const onChanePartCombineMaterial = (partsId, goodsId) => {
    return {
        type: PART_CU_INCLUDE_COMBINE_MATERIAL,
        payload: {partsId, goodsId}
    };
};
export const onChaneCombineMaterialPositionPart = ({parts_by_step, goods_id}) => {
    return {
        type: COMBINE_MATERIAL_CHANGE_POSITION_PART,
        payload: {parts_by_step, goods_id}
    };
};
export const orderChangePositionIndexMaterialOrBand = (key, value) => {
    return {
        type: ORDER_CHANGE_POSITION_INDEX_MATERIAL_BAND,
        payload: {key, value}
    };
};

export const updateBeforeChangePartSize = (sizePart) => {
    return {
        type: ORDER_CURRENT_PART_SIZE_SAVE,
        payload: sizePart
    };
};
// export const onGlobalUpdateGR = ({goods_id, parts}) => {
//     return {
//         type: GLOBAL_UPDATE_GR,
//         payload: {
//             goods_id, parts
//         }
//     };
// };
export const onDragPartInMaterial = ({material, result}) => {
    return {
        type: MATERIAL_DRAG_PART,
        payload: {
            material, result
        }
    };
};
const actionTypeTemplatePaz = {
    create: "TEMPLATE_PAZ/CREATE",
    update: "TEMPLATE_PAZ/UPDATE",
    delete: "TEMPLATE_PAZ/DELETE",
    pazDetailCU: "TEMPLATE_PAZ/DETAIL_CU",
    pazDetailDelete: "TEMPLATE_PAZ/DETAIL_DELETE",
    mirroringOperation: "TEMPLATE_PAZ/MIRRORING_OPERATION",
    createPartPazGb: "TEMPLATE_PAZ/CREATE_PART_PAZ_GIBLAB",
    deleteAllPrtIdGb: "TEMPLATE_PAZ/DELETE_ALL_PART_ID_GIBLAB",
    cleanPartGiblabByPartsIds: "TEMPLATE_PAZ/CLEAN_PART_GIBLAB_BY_PARTS_IDS",
    // copyPartIdGb: "TEMPLATE_PAZ/COPY_PART_ID_GIBLAB"
};
export const actionTemplatePaz = {
    create: (form) => ({type: actionTypeTemplatePaz?.create, payload: form}),
    update: (form) => ({type: actionTypeTemplatePaz?.update, payload: form}),
    delete: (paz) => ({type: actionTypeTemplatePaz?.delete, payload: paz}),
    pazInDetailCU: ({form, partId}) => ({type: actionTypeTemplatePaz?.pazDetailCU, payload: {form, partId}}),
    deletePazInDetail: ({operation, partId}) => ({
        type: actionTypeTemplatePaz?.pazDetailDelete,
        payload: {operation, partId}
    }),
    mirroringOperation: ({partId, operation, type, mirroringType}) => ({
        type: actionTypeTemplatePaz?.mirroringOperation,
        payload: {
            partId, operation, type, mirroringType
        }
    }),
    createPartPazGiblab: (tools) => ({type: actionTypeTemplatePaz?.createPartPazGb, payload: tools}),
    deleteAllPartForIdGiblab: (partId) => ({type: actionTypeTemplatePaz?.deleteAllPrtIdGb, payload: partId}),
    cleanPartGiblabByPartsIds: (partsIds = []) => {
        return {
            type: actionTypeTemplatePaz?.cleanPartGiblabByPartsIds,
            payload: partsIds
        }
    }
    // copyPartIdGiblab: (partId) => ({type: actionTypeTemplatePaz?.copyPartIdGb, payload: partId})
};


const OrderReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHECKOUT_ADDRESS_DELIVERY_SET_FIELD_DATA: {
            return {
                ...state,
                confirmAddressDelivery: {
                    ...action.payload,
                }
            }
        }
        case ORDER_PART_OPERATION_CHANGE_FREZ_INFO: {
            const {partId, frezId, update} = action.payload;
            const part = state.order.part.map(p => {
                if (+p.id === +partId) {
                    const frez = p.operations.frez.map(f => {
                        if (+f.id === +frezId) {
                            return {
                                ...f, ...update
                            }
                        }
                        return f;
                    })
                    return {
                        ...p,
                        operations: {
                            ...p.operations,
                            frez: frez
                        }
                    }
                }
                return p;
            })
            return {
                ...state,
                order: {
                    ...state.order,
                    part: part
                }
            }
        }
        case ORDER_PART_CHANGE_PROCESSING_EDGE_SIDE_IN_PART: {
            return {
                ...state,
                order: {
                    ...state.order,
                    part: action.payload.part
                }
            }
        }
        case ORDER_PART_CHANGE_PROCESSING_EDGE: {
            let {side, change_data, part_id} = action.payload;
            let current_key = change_data?.type_key_edge;
            let prev_key = change_data.initial_type_key_edge;
            return {
                ...state,
                order: {
                    ...state.order,
                    part: state.order.part?.map(e => {

                        if (Number(e.id) !== Number(part_id)) return e;
                        let part_edge = {...e.edge}
                        let edit_edge_copy = {
                            ...e.edge_edit,
                        }
                        if (current_key && change_data.edge) {
                            edit_edge_copy = {
                                ...edit_edge_copy,
                                [current_key]: {
                                    ...edit_edge_copy[current_key],
                                    [side]: change_data.edge
                                }
                            }
                        }
                        if (prev_key !== current_key && edit_edge_copy?.[prev_key]) {
                            let remove_side = Object.keys(edit_edge_copy[prev_key]).filter(key =>
                                key !== side).reduce((obj, key) => {
                                    obj[key] = edit_edge_copy[prev_key][key];
                                    return obj;
                                }, {}
                            );
                            edit_edge_copy = {
                                ...edit_edge_copy,
                                [prev_key]: remove_side
                            }
                        }
                        if (change_data.edge && (Number(change_data?.edge?.normal_edge_in_same_side_enabled) === 0)) {

                            part_edge[side] = {
                                ...part_edge[side],
                                db_id: null,
                                color: null,
                                eva: null,
                                laz: null,
                                pur: null,
                                radius: null,
                                t: 0,

                            }
                        }
                        return {
                            ...e, edge_edit: edit_edge_copy, edge: part_edge
                        }
                    })
                }
            }
        }
        case ORDER_MULTI_CHOICE_MATERIAL_BAND_DELETE_IN_ORDER: {
            return {
                ...state,
                order: {
                    ...state.order,
                    ...action.payload
                }
            }
        }
        case ORDER_SET_ADDITIONAL_GOODS_SERVICE_FOR_ORDER: {
            return {
                ...state, order: {
                    ...state.order,
                    goods_service_added_order_firm: [...state.order.goods_service_added_order_firm, action.payload]
                }
            }
        }
        case ORDER_REMOVE_ADDITIONAL_GOODS_SERVICE_FOR_ORDER: {
            return {
                ...state, order: {
                    ...state.order,
                    goods_service_added_order_firm: state.order.goods_service_added_order_firm?.filter(item => Number(item?.goods_service_for_order_by_firm_id) !== Number(action?.payload))
                }
            }
        }
        case ORDER_PART_SET_GOODS_SERVICE_TYPE: {
            let {part_id, goods_service} = action.payload;
            let part = [...state.order.part].map(part => {
                let is_part = Number(part.id) === Number(part_id);
                if (is_part) {
                    if (!part.hasOwnProperty('goods_service_added_firm')) {
                        return {
                            ...part,
                            goods_service_added_firm: [...goods_service]
                        }
                    }
                    return {
                        ...part,
                        goods_service_added_firm: [...goods_service]
                    }
                }
                return part
            })
            return {
                ...state,
                order: {
                    ...state.order,
                    part: part,
                }
            }
        }

        case ORDER_TOGGLE_CURRENCY_GOODS_SERVICE_FOR_ORDER_TYPE:
        case  ORDER_TOGGLE_CURRENCY_GOODS_SERVICE_TYPE: {

            return {
                ...state,
                order: {
                    ...state.order,
                    ...action.payload
                }
            }
        }

        case ORDER_SET_ADDITIONAL_GOODS_SERVICES: {
            return {
                ...state,
                additional_goods_service: action.payload
                // goods_service_part_order: action.payload
            }
        }
        case REMOVE_PRE_JOINT_CURVE_MATERIAL_PARTS_EDGE: {
            let {material_id} = action.payload;
            const parts = state.order.part?.map(part => {
                let part_of_material = Number(part.material) === Number(material_id);
                if (!part_of_material || !part.hasOwnProperty('edge')) return part;
                Object.keys(part.edge)?.map(side => {
                    part.edge[side] = {...part.edge[side], pre_joint: 1, curve: 0}
                });
                return part
            })
            return {
                ...state,
                order: {
                    ...state.order,
                    part: parts
                }
            };
        }
        case CHANGE_ORDER_PRODUCTION_CONST: {
            return {
                ...state,
                order: {
                    ...state.order,
                    production_constants: {
                        ...state.order.production_constants,
                        ...action.payload
                    }
                }
            };
        }
        case CHANGE_PART_DATA: {
            // Find the index of the part to be updated based on its id
            const parts = [...state.order.part];
            let part_action = {...action.part}
            let update = parts.map(item => {
                if (Number(item.id) === Number(part_action.id)) {
                    return {
                        ...part_action,
                    }
                }
                return item
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    part: [...update]
                }
            };
        }
        case FILTER_PARTS_OF_MATERIAL: {
            return {
                ...state,
                selectedMaterialGoodsIdOfDetailing: {
                    ...state?.selectedMaterialGoodsIdOfDetailing,
                    pagination_page: 1
                },
                filterPartsOfMaterial: {...action.payload}
            }
        }
        case SELECTED_MATERIAL_GOODS_ID_OF_DETAILING: {
            return {
                ...state,
                selectedMaterialGoodsIdOfDetailing: {
                    ...state?.selectedMaterialGoodsIdOfDetailing,
                    ...action.payload,
                }
            }
        }
        case MATERIAL_COMBINE_UPDATE_DEPENDENCIES: {
            if (!action.payload?.length) return {
                ...state
            };
            return {
                ...state,
                order: {
                    ...state.order,
                    material: action.payload
                }
            };
        }
        case OPERATIONS_SELECTED_DELETE: {
            let {operationSelected, partId} = action.payload;
            const gr = state.order.gr;
            let templatePazClean = gr;
            if (utilsTypeof.isObject(operationSelected) && "paz" in operationSelected) {
                templatePazClean = pazTemplateCleanByPartIdMapping([Number(partId)], gr)
            }

            let updatePart = state.order.part.map(part => {
                if (Number(part.id) === Number(partId)) {
                    let operations = {...part.operations};
                    for (const [key, value] of Object.entries(operationSelected)) {
                        if (key in operations && key !== "srez") {
                            operations = {
                                ...operations,
                                [key]: operations[key].filter(item => !value?.some(v => Number(v) === Number(item.id)))
                            };
                        }
                        if (key in operations && key === "srez") {
                            part = {
                                ...part,
                                [key]: {
                                    b: {otstup: "", ugol: "", db_id: ""},
                                    l: {otstup: "", ugol: "", db_id: ""},
                                    r: {otstup: "", ugol: "", db_id: ""},
                                    t: {otstup: "", ugol: "", db_id: ""}

                                }
                            };
                        }


                    }
                    return {
                        ...part,
                        operations: {
                            ...operations
                        }
                    };
                }
                return {
                    ...part
                };
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    part: updatePart,
                    gr: templatePazClean
                }
            };
        }
        case REMOVE_ALL_PARTS_IN_MATERIAL: {
            const updatePart = state.order.part.filter(part => Number(part.material) !== Number(action.payload))
                .map((part, index) => ({
                    ...part, id: index + 1
                }));
            return {
                ...state,
                order: {
                    ...state.order,
                    part: updatePart
                }
            };
        }
        case CUTTING_CARD_UPDATE_OWNER_UI_CUT_DROP_ID: {
            let cardId = action.payload.cardId;
            let ownerUiCutDropId = action.payload.ownerUiCutDropId;
            let updateCards = state.order.cutting_card.cards?.map(card => {
                if (+card.card_id === cardId) {
                    return {
                        ...card,
                        ownerUiCutDropId: ownerUiCutDropId,
                    }
                }
                return card;
            })

            return {
                ...state,
                order: {
                    ...state.order,
                    cutting_card: {
                        ...state.order.cutting_card,
                        cards: updateCards
                    }
                }
            }
        }
        case SET_CUTTING_CARD: {
            return {
                ...state,
                order: {
                    ...state?.order,
                    cutting_card: action.payload
                }
            };
        }
        case ADD_POSTFORM_OPERATION_FOR_PART: {
            const {partId, materialId, form, side} = action.payload;
            const findMaterial = state.order.material?.find(m => Number(m?.id) === Number(materialId));
            if (!findMaterial) {
                alert("Not found material", materialId);
                return {
                    ...state
                };
            }
            let parts_cut_to = [...state.order.part].map(e => {
                if (Number(partId) === Number(e.id)) {
                    e.operations.cut_to = [(_constructorCutTo(form, side, _getItemID(e.operations.cut_to)))];
                    e.y = findMaterial?.y;
                    e.y1 = findMaterial?.y;
                    e.y2 = findMaterial?.y;
                }
                return e;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    part: [
                        ...parts_cut_to
                    ]
                }
            };
        }

        case actionTypeTemplatePaz.cleanPartGiblabByPartsIds: {
            const partsIds = Array.isArray(action?.payload) ? action.payload : null;
            const gr = Array.isArray(state.order?.gr) ? state.order.gr : [];
            if (!partsIds) {
                console.info('не верный формат передачи индификаторов деталей, для очистки шаблонов пазования по номеру детали')
                return {
                    ...state,
                }
            }
            const grClean = pazTemplateCleanByPartIdMapping(partsIds, gr);

            return {
                ...state,
                order: {
                    ...state.order,
                    gr: grClean
                }
            }

        }
        case actionTypeTemplatePaz?.deleteAllPrtIdGb: {
            const partId = action?.payload;
            const gr = Array.isArray(state.order?.gr) ? state.order.gr : [];

            const grClean = pazTemplateCleanByPartIdMapping([partId], gr)

            return {
                ...state,
                order: {
                    ...state?.order,
                    gr: grClean
                }
            };
        }
        case actionTypeTemplatePaz?.createPartPazGb: {
            const tools = action?.payload;
            const gl = state?.order?.gr || [];
            const partClearPazTemplate = [...state?.order?.part]?.map(item => {
                const operationPaz = item?.operations?.paz?.filter(paz => !paz?.hasOwnProperty("id_template_paz"));
                let pazTemplateOperations = [];
                let idLast = _getItemID(operationPaz);
                gl?.forEach((itemGl, index) => {
                    let findPartGl = itemGl?.part_giblab?.filter(gb => Number(gb?.id) === Number(item?.id));
                    if (findPartGl?.length) {
                        for (let i = 0; i < findPartGl.length; i++) {
                            pazTemplateOperations.push({
                                id: Number(idLast) + Number(pazTemplateOperations?.length),
                                id_template_paz: Number(itemGl?.id),
                                template_name: itemGl?.name_ifp,
                                side: findPartGl[i]?.side,
                                side_from: findPartGl[i]?.side_from,
                                w: itemGl?.w || Number(tools?.[0]),
                                type_paz: 1,
                                w_paz: Number(itemGl?.w_paz),
                                d: Number(itemGl?.d),
                                d1: Number(itemGl?.d),
                                z: Number(itemGl?.z),
                                color_paz: itemGl?.color
                            });
                        }
                    }
                });

                return {
                    ...item,
                    operations: {
                        ...item?.operations,
                        paz: [...operationPaz, ...pazTemplateOperations]
                    }
                };
            });
            return {
                ...state,
                order: {
                    ...state?.order,
                    part: partClearPazTemplate
                }
            };
        }
        case actionTypeTemplatePaz?.mirroringOperation: {
            let {type, mirroringType, partId, operation} = action?.payload;
            return {
                ...state
            };

        }
        case actionTypeTemplatePaz?.pazDetailDelete: {
            const {operation, partId} = action?.payload;

            const grStore = [...state?.order?.gr ?? []]
            const partStore = [...state?.order?.part ?? []];

            const updateGr = grStore.map(temp => {
                if (Number(operation?.id_template_paz) === Number(temp?.id)) {
                    let part_giblab = Array.isArray(temp?.part_giblab) ? temp.part_giblab : [];
                    const remainingParts = part_giblab.reduce((acc, curr) => {
                        const isTargetPart = Number(curr?.id) === Number(partId);
                        const isEqualSideFrom = curr.side_from === operation.side_from;
                        if (isTargetPart && isEqualSideFrom) {
                            return acc
                        }
                        return [...acc, curr];
                    }, [])

                    return {
                        ...temp,
                        part_giblab: remainingParts
                    };
                }
                return temp;
            });


            const updateParts = partStore.map(part => {
                if (Number(part.id) === Number(partId)) {
                    const pazOperation = [...part?.operations?.paz ?? []]
                    const actualPaz = pazOperation.filter((paz) => {

                        const isTargetPazOperation = Number(paz?.id) === Number(operation.id);
                        const isTargetTemplateId = Number(paz?.id_template_paz) === Number(operation?.id_template_paz);
                        const isEqualSideFrom = paz.side_from === operation.side_from;

                        return !(isTargetPazOperation && isTargetTemplateId && isEqualSideFrom)
                    });

                    return {
                        ...part,
                        operations: {
                            ...part.operations,
                            paz: actualPaz
                        }
                    }
                }
                return part
            })

            return {
                ...state,
                order: {
                    ...state?.order,
                    gr: updateGr,
                    part: updateParts
                }
            };
        }
        case actionTypeTemplatePaz?.pazDetailCU: {
            const {form, partId} = action.payload;

            const removeCurrentPartGr = [...state?.order?.gr]?.map(temp => {
                let parts = temp?.part_giblab?.filter(part => Number(part?.id) !== Number(partId));
                return {
                    ...temp,
                    part_giblab: parts
                };
            });

            const updateGr = removeCurrentPartGr?.map(temp => {
                form?.forEach((field) => {
                    if (Number(temp?.id) === Number(field?.id_template_paz)) {
                        temp.part_giblab.push({
                            id: partId,
                            side: field?.side,
                            side_from: field?.side_from
                        });
                    }
                });
                return temp;
            });
            return {
                ...state,

                order: {
                    ...state.order,
                    gr: updateGr
                }
            };
        }
        case actionTypeTemplatePaz.create: {
            const grCopy = state?.order?.gr || [];
            let createGr = {
                ...action?.payload,
                id: _getItemID(grCopy),
                part_giblab: []
            };
            return {
                ...state,
                order: {
                    ...state.order,
                    gr: [...grCopy, createGr]
                }
            };
        }
        case actionTypeTemplatePaz.update: {
            const {id, name_ifp, w_paz, z, d, color} = action.payload;
            const grCopy = state?.order?.gr || [];
            // const targetGr = grCopy?.find(gr => Number(gr?.id) === Number(id));
            const preConditionIsStandardTemplate = (Number(id) >=100 || Number(id) <= 200);
            const grIds = grCopy?.map(gr =>Number(gr?.id));

            let newGrId = Number(id);

            
            if(preConditionIsStandardTemplate) {
                //generate new id for gr
                 newGrId = function getNewGrId(newId=1) {
                   const grHasCurrentId =  grIds.includes(Number(newId));
                  return grHasCurrentId ? getNewGrId((newId + 1)) : newId;
                }();

            }


            const updateGr = grCopy?.map(template => {
                if (Number(template?.id) === Number(id)) {
                    template.name_ifp = name_ifp;
                    template.d = d;
                    template.w_paz = w_paz;
                    template.z = z;
                    template.color = color;
                    template.id = newGrId
                }
                return template;
            });

            return {
                ...state,
                order: {
                    ...state.order,
                    gr: updateGr,
                }
            };
        }
        case actionTypeTemplatePaz.delete: {
            const {id, part_giblab} = action?.payload;
            const gr = [...state?.order?.gr];
            const updateGr = gr?.filter(paz => Number(paz?.id) !== Number(id));


            return {
                ...state,
                order: {
                    ...state.order,
                    gr: updateGr

                }
            };
        }
        case CHANGE_COLOR_BAND: {
            const {color, band} = action.payload;
            const updatePartColorEdge = state?.order?.part?.map(item => {
                for (const [key, value] of Object.entries(item?.edge)) {
                    if (Number(item?.edge?.[key]?.db_id) === Number(band?.id)) {
                        item.edge = {
                            ...item?.edge,
                            [key]: {...item?.edge?.[key], color: color}
                        };
                    }
                }

                return item;
            });
            const updateBand = state?.order?.band?.map(item => {
                if (Number(item?.id) === Number(band?.id)) {
                    return {
                        ...item, color: color
                    };
                }
                return item;
            });
            return {
                ...state,
                order: {
                    ...state?.order,
                    part: updatePartColorEdge,
                    band: updateBand
                }
            };
        }
        case COMBINE_MATERIAL_CHANGE_POSITION_PART: {
            const {goods_id = -1, parts_by_step = []} = action?.payload;
            if (goods_id === -1) {
                console.info("case change position combine material in parts. not passed an argument goods_id ");
                return false;
            }
            ;
            const updateMaterial = state?.order?.material?.map(item => {
                if (Number(item?.goods_id) === Number(goods_id)) {
                    item = {
                        ...item,
                        parts_by_step
                    };
                }
                return item;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    material: updateMaterial
                }
            };
        }
        case PART_CU_INCLUDE_COMBINE_MATERIAL: {
            const parts = [...state?.order?.part];
            const updateParts = parts?.map(part => {
                const includePartId = action?.payload?.partsId.find(({id}) => Number(id) === Number(part?.id));
                if (includePartId && Number(part?.combined_material_goods_id) !== Number(action.payload?.goodsId)) {
                    part["combined_material"] = true;
                    part["combined_material_goods_id"] = action?.payload?.goodsId;
                } else if (Number(part?.combined_material_goods_id) === Number(action.payload?.goodsId)) {
                    part.combined_material = false;
                    part["combined_material_goods_id"] = null;
                }
                return part;
            });
            return {
                ...state,
                order: {...state?.order, part: updateParts}
            };

        }
        case DELETE_PROJECT_ORDER_VERSION: {
            const deleteVersion = action.payload?.version?.map(v => Number(v.version)) ?? [];
            const actual = [...state.order?.versions].filter(item => !deleteVersion.includes(Number(item.version)));
            return {
                ...state,
                order: {
                    ...state.order,
                    versions: actual
                }
            };
        }
        case MATERIAL_DRAG_PART: {
            const partsPreview = state.partsPreview;
            const actualPartMaterial = state.order.part.filter(item => String(item?.material) === String(action.payload.material));
            const resetPart = state.order.part.filter(item => String(item?.material) !== String(action.payload.material));
            const findIddDragItem = actualPartMaterial.findIndex((part) => Number(part.id) === action.payload.result.source.index);
            const findIdAppendDragItem = actualPartMaterial.findIndex((part) => Number(part.id) === action.payload.result.destination.index);
            const removedItem = actualPartMaterial.splice(findIddDragItem, 1);
            actualPartMaterial.splice(findIdAppendDragItem, 0, ...removedItem);
            let parts = resetPart?.concat(...actualPartMaterial);
            const {
                part: updatePartsId,
                gr: grUpdate,
                material: materialUpdate,
                partsPreview: updatePartsPreview
            } = globalChangePositionGr({
                parts,
                goods_id: action.payload.material,
                material: state.order.material,
                gr: [...state?.order?.gr],
                partsPreview: partsPreview ?? []
            });

            return {
                ...state,
                partsPreview: updatePartsPreview,
                order: {
                    ...state.order,
                    part: updatePartsId,
                    gr: grUpdate,
                    material: materialUpdate
                }
            };
        }
        case ORDER_CURRENT_PART_SIZE_SAVE: {
            return {
                ...state,
                order: {
                    ...state.order,
                    beforeChangePartSize: action.payload
                }
            };
        }
        case ORDER_CHANGE_POSITION_INDEX_MATERIAL_BAND: {
            return {
                ...state,
                order: {
                    ...state.order,
                    [action.payload.key]: action.payload.value
                }

            };
        }
        case SET_PROJECT_DATA: {
            const materials = action.order.material;
            const filter_state = state.filterPartsOfMaterial;
            const select_material_state = state.selectedMaterialGoodsIdOfDetailing;

            const is_has_select_material_of_materials = materials.find(material => material.goods_id === select_material_state?.goods_id && action.order?.part?.some(p => Number(p?.material) === Number(select_material_state?.goods_id)))
            let material_selected_initial = action.order?.material?.find(m => {
                return action.order?.part?.some(p => Number(p?.material) === Number(m?.goods_id))
            });
            const defSelectedMaterialGoodsIdOfDetailing = material_selected_initial?.goods_id || action.order.material?.[0]?.goods_id || null;
            const selectedMaterials = is_has_select_material_of_materials ? {
                ...select_material_state
            } : {
                pagination_page: 1,
                itemsPerPage: 20,
                goods_id: defSelectedMaterialGoodsIdOfDetailing
            }
            const setting_production_phone_code = action.order?.production_constants?.['react.phone_code_one']
            const phone_code = Number(setting_production_phone_code) !== 9999 && !!setting_production_phone_code
                ? "+" + setting_production_phone_code
                : action.order?.client_phone_code ? action.order?.client_phone_code
                    : state.confirm?.phone_code;

            const region = findKeyByCodePhone(phone_code, SELECT_OPTIONS_PHONE);

            return {
                ...state,
                order: action.order,
                confirm: {
                    ...state.confirm,
                    name: action?.order?.client_name ? action.order?.client_name : state.confirm?.name,
                    email: action?.order?.client_email ? action.order?.client_email : state.confirm?.email,
                    phone: action?.order?.client_phone ? action.order?.client_phone : state.confirm?.phone,
                    phone_code: phone_code,
                    region: region
                },
                selectedMaterialGoodsIdOfDetailing: {
                    ...state.selectedMaterialGoodsIdOfDetailing,
                    ...selectedMaterials

                },
                filterPartsOfMaterial: is_has_select_material_of_materials ? {...filter_state} : {...INITIAL_FILTER_PARTS_OF_MATERIAL},


            }
        }
        case CHANGE_ORDER_TITLE:
            return {
                ...state,
                order: {
                    ...state.order,
                    title: action.title
                }
            };
        case ADD_ORDER_MATERIAL:

            return {
                ...state,
                order: {
                    ...state.order,
                    material: [
                        ...state.order.material,
                        {...action.material}
                    ],
                    bands_type: [
                        ...state.order.bands_type,
                        {
                            band: action.material.id,
                            type: "EVA",
                            pre_joint: 1,
                            glue: "transparent"
                        }
                    ]
                }
            };
        case ADD_ORDER_BAND:
            return {
                ...state,
                order: {
                    ...state.order,
                    band: [
                        ...state.order.band,
                        {...action.material}
                    ]
                }
            };
        case
        ADD_ORDER_BOX:
            return {
                ...state,
                order: {
                    ...state.order,
                    boxes: [
                        ...state.order.boxes,
                        {...action.box}
                    ]
                }
            };
        case
        SET_INSTRUCTION_BOX: {
            let {box_article_id, article_id, count, firm, values} = action.payload;
            let boxes = state.order.boxes;
            let boxesUpdate = [...boxes].map(b => {
                if (b["new_box"].box_article_id === box_article_id && article_id == b["new_box"].article_id) {
                    b = {
                        ...b,
                        html: action.html
                    };
                }
                return b;
            });

            return {
                ...state,
                order: {
                    ...state.order,
                    boxes: boxesUpdate
                }
            };
        }
        case
        ADD_NEW_ORDER_EXTRA_DATA:
            return {
                ...state,
                order: {
                    ...state.order,
                    extra: [
                        {...action.extra},
                        ...state.order.extra
                    ]
                }
            };
        case
        DELETE_ORDER_EXTRA_DATA:
            const delete_extras = [...state.order.extra].filter(e => Number(e.id) !== Number(action.id));
            return {
                ...state,
                order: {
                    ...state.order,
                    extra: delete_extras
                }
            };
        case
        DELETE_ORDER_BOX:
            const delete_boxes = [...state.order.boxes].filter(e => Number(e.id) !== Number(action.id));
            return {
                ...state,
                order: {
                    ...state.order,
                    boxes: delete_boxes
                }
            };
        case
        CHANGE_ORDER_URGENT_DATA:
            return {
                ...state,
                order: {
                    ...state.order,
                    urgent: action.status
                }
            };
        case
        CHANGE_ORDER_UNIVERSAL_DATA:
            return {
                ...state,
                order: {
                    ...state.order,
                    universal_calculate: action.status
                }
            };
        case
        SET_PRODUCTION_CONSTANTS_DATA:
            return {
                ...state,
                order: {
                    ...state.order,
                    production_constants: action.data
                }
            };
        case
        ADD_ORDER_GOODS:

            return {
                ...state,
                order: {
                    ...state.order,
                    goods: [
                        ...state.order.goods,
                        {
                            count: 1,
                            ...action.material
                        }
                    ]
                }
            };
        case ADD_NEW_ARRAY_PARTS: {
            const partsState = [...state.order.part]?.sort((a, b) => a.id - b.id);

            let index = action.id ?
                partsState.findIndex(item => {
                    return Number(item.id) === Number(action.id);
                }) : partsState?.length - 1;
            let append_index = index;
            partsState?.splice((append_index), 0, ...action.parts);

            const {part: parts, gr: grUpdate, material: materialUpdate} = globalChangePositionGr({
                parts: partsState,
                goods_id: 0,
                material: state.order.material,
                gr: [...state?.order?.gr]
            });

            return {
                ...state,
                addPartForm: {...initialState.addPartForm},
                order: {
                    ...state.order,
                    part: parts,
                    gr: grUpdate,
                    material: materialUpdate
                }
            };
            break;
        }
        case ADD_NEW_PART: {
            const partsState = [...state.order.part]?.sort((a, b) => a.id - b.id);
            let new_part = {
                ...action.part,
                ...getUiIdForPart()
            }

            let index = action.id ?
                partsState.findIndex(item => {
                    return Number(item.id) === Number(action.id);
                }) : partsState?.length - 1;
            let indexPart = action?.isNext ? index + 1 : index;
            partsState?.splice((indexPart), 0, new_part);

            const {part: parts, gr: grUpdate, material: materialUpdate} = globalChangePositionGr({
                parts: partsState,
                goods_id: 0,
                material: state.order.material,
                gr: [...state?.order?.gr]
            });

            return {
                ...state,
                addPartForm: {...initialState.addPartForm},
                order: {
                    ...state.order,
                    part: parts,
                    gr: grUpdate,
                    material: materialUpdate
                }
            };
            break;
        }
        case
        ADD_NEW_PART_BOX: {
            return {
                ...state,
                addPartForm: {...initialState.addPartForm},
                order: {
                    ...state.order,
                    part: [...state?.order?.part, ...action?.part]
                }
            };
        }
        case
        DELETE_ORDER_PART_DATA:

            const parts_delete = [...state.order.part].filter(e => Number(e.id) !== Number(action.id));
            const {part: updatePartsId, gr: grUpdate, material: materialUpdate} = globalChangePositionGr({
                parts: parts_delete,
                goods_id: "",
                material: state.order.material,
                gr: [...state?.order?.gr]
            });

            return {
                ...state,
                order: {
                    ...state.order,
                    part: updatePartsId,
                    material: materialUpdate,
                    gr: grUpdate
                }
            };
        case
        DELETE_ORDER_PART_BOX_DATA:

            const parts_deletes = [...state.order.part].filter(e => Number(e.box_id) !== Number(action.id));
            return {
                ...state,
                order: {
                    ...state.order,
                    part: [...parts_deletes]
                }
            };
        case
        DELETE_GOODS_ORDERS_DATA:
            const goods_delete = [...state.order.goods].filter(e => Number(e.goods_id) !== Number(action.id));
            return {
                ...state,
                order: {
                    ...state.order,
                    goods: [...goods_delete]
                }
            };
        case
        CHANGE_ORDER_CURRENCY_DATA:
            return {
                ...state,
                order: {
                    ...state.order,
                    currency: action.currency
                }
            };
        case DELETE_MATERIALS_ORDER_DATA:
            return {
                ...state,
                order: {
                    ...state.order,
                    part: state.order.part.filter((e) => Number(e.material) !== Number(action.material)),
                    material: [...state.order.material].filter((e) => Number(e.goods_id) !== Number(action.material)),
                    bands_type: [...state.order.bands_type].filter((e) => Number(e.band) !== Number(action.material))
                }
            };
        case DELETE_BANDS_ORDER_DATA:
            return {
                ...state,
                order: {
                    ...state.order,
                    bands_type: [...state.order.bands_type].filter((e) => Number(e.band) !== Number(action.band)),
                    band: [...state.order.band].filter((e) => Number(e.goods_id) !== Number(action.band)),
                    part: [...state.order.part].map((e) => {
                        if (Number(e.edge.t.db_id) === Number(action.band)) {
                            e.edge.t = _constructorPartBand(1);
                        }
                        if (Number(e.edge.b.db_id) === Number(action.band)) {
                            e.edge.b = _constructorPartBand(1);
                        }
                        if (Number(e.edge.l.db_id) === Number(action.band)) {
                            e.edge.l = _constructorPartBand(1);
                        }
                        if (Number(e.edge.r.db_id) === Number(action.band)) {
                            e.edge.r = _constructorPartBand(1);
                        }
                        for (const [side, srez] of Object.entries(e.srez)) {
                            if (Number(srez?.db_id) == Number(action?.band)) {
                                e.srez = {
                                    ...e.srez,
                                    [side]: {...srez, db_id: null}
                                }
                            }

                        }
                        let operations_paz = Array.isArray(e?.operations?.paz) ? e?.operations?.paz?.map(paz => {
                            let is_clear_band_id = paz?.hasOwnProperty('band_db_id') && (Number(paz?.band_db_id) === Number(action.band));
                            if (is_clear_band_id) {
                                return {
                                    ...paz, band_db_id: null
                                }
                            }
                            return paz;
                        }) : [];
                        let edge_edit = e?.edge_edit ? Object.entries(e?.edge_edit)?.reduce((acc, [key_type, edges]) => {
                            if (!edges) return {...acc, [key_type]: edges}
                            let sides_edge = Object.entries(edges)?.reduce((sides_acc, [side, edge]) => {
                                let is_has_band_db_id = edge?.hasOwnProperty('band_db_id') && (Number(edge?.band_db_id) === Number(action.band));
                                if (is_has_band_db_id) {
                                    return {
                                        ...sides_acc, [side]: {
                                            ...edge, band_db_id: null
                                        }
                                    }
                                }
                                return {...sides_acc, [side]: edge}
                            }, {});
                            return {...acc, [key_type]: sides_edge}
                        }, {}) : {...INITIAL_DEFAULT_PART_EDGE_EDIT}
                        return {
                            ...e,
                            operations: {
                                ...e.operations, paz: operations_paz
                            },
                            edge_edit: edge_edit
                        };
                    })
                }
            };
        case
        DELETE_ALL_MATERIALS_BANDS_ORDER_DATA: {
            return {
                ...state,
                order: {
                    ...state.order,
                    part: [],
                    bands_type: [],
                    band: [],
                    material: []
                }
            }
        }
        case SET_PARTS_WITH_SIZES:
            return {
                ...state,
                order: {
                    ...state.order,
                    part: action.parts,
                    partSizesType: action.size_type
                }
            };
        case
        CHANGE_ORDER_SIZE_TYPE:
            return {
                ...state,
                order: {
                    ...state.order,
                    partSizesType: action.size_type
                }
            };
        case PART_CHANGE_ALL_DATA: {
            let update_parts = action.payload;
            if (!Array.isArray(update_parts)) {
                return {...state}
            }
            return {
                ...state, order: {
                    ...state.order, part: update_parts
                }
            }
        }
        case UPDATE_ALL_PART: {
            if (!Array.isArray(action.payload)) {
                alert("Mistake payload. Waiting is array");
                return {
                    ...state
                };
            }

            const {
                part: updatePartsId,
                gr: grUpdate,
                material: materialUpdate,
                partsPreview: partsPreview
            } = globalChangePositionGr({
                parts: action.payload,
                goods_id: "",
                material: state.order.material,
                gr: state.order?.gr,
                partsPreview: state?.partsPreview
            });
            return {
                ...state,
                partsPreview: partsPreview,
                order: {
                    ...state.order,
                    part: updatePartsId,
                    material: materialUpdate,
                    gr: grUpdate
                }
            };
        }


        case
        ADD_NEW_BORE:
            let parts_bore = [...state.order.part].map(e => {
                if (Number(action.part_id) === Number(e.id)) {
                    e.operations.bore.push(_constructorBore(action.form, action.side, _getItemID(e.operations.bore)));
                }
                return e;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    part: [
                        ...parts_bore
                    ]
                }
            };
        case
        ADD_NEW_PAZ:
            let parts_paz = [...state.order.part].map(e => {
                if (Number(action.part_id) === Number(e.id)) {
                    e.operations.paz.push(_constructorPaz(action.form, action.side, _getItemID(e.operations.paz)));
                }
                return e;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    part: [
                        ...parts_paz
                    ]
                }
            };
        case
        ADD_NEW_HEM:
            let parts_hem = [...state.order.part].map(e => {
                if (Number(action.part_id) === Number(e.id)) {
                    const hem = _constructorHem(action.form, action.side, action.material_z, _getItemID(e.operations.hem));
                    e.z = Number(e.z) + Number(action.material_z);
                    e.operations.hem.push(hem);
                }
                return e;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    part: [
                        ...parts_hem
                    ]
                }
            };
        case ADD_NEW_CUT_TO: {

            let parts_cut_to = [...state.order.part].map(e => {
                if (Number(action.part_id) === Number(e.id)) {
                    let cut_to = _constructorCutTo(action.form, action.side, _getItemID(e.operations.cut_to));
                    e.operations.cut_to = [cut_to];
                }
                return e;
            });

            return {
                ...state,
                order: {
                    ...state.order,
                    part: [
                        ...parts_cut_to
                    ]
                }
            };
        }
        case
        ADD_NEW_TROUGH:
            let parts_trough = [...state.order.part].map(e => {
                if (Number(action.part_id) === Number(e.id)) {
                    e.operations.trough.push(_constructorTrough(action.form, action.side, _getItemID(e.operations.trough)));
                }
                return e;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    part: [
                        ...parts_trough
                    ]
                }
            };
        case
        CHANGE_FREZ_OPERATION_BANDS_DATA:
            let part_editFrezBand = [...state.order.part].map(e => {
                if (Number(action.part_id) === Number(e.id)) {
                    e.operations.frez.map(f => {
                        if (Number(f.id) === Number(action.operation_id)) {
                            f.db_id = action.band.result;
                            f.inputs = {
                                ...f.inputs,
                                db_id: action.band.result,
                                t: action.band.t
                            };
                        }
                        return f;
                    });
                }
                return e;
            });

            return {
                ...state,
                order: {
                    ...state.order,
                    part: [
                        ...part_editFrezBand
                    ]
                }
            };
        case
        CHANGE_FREZ_OPERATION_GLASS_EDGE_TYPE_DATA: {
            let part_editFrezGlass = [...state.order.part].map(e => {
                if (Number(action.part_id) === Number(e.id)) {
                    e.operations.frez.map(f => {
                        if (Number(f.id) === Number(action.operation_id)) {
                            f.glass_edge_type = action.glass.result;
                            f.inputs = {
                                ...f.inputs,
                                glass_edge_type: action.glass.result
                            };
                        }
                        return f;
                    });
                }
                return e;
            });

            return {
                ...state,
                order: {
                    ...state.order,
                    part: [
                        ...part_editFrezGlass
                    ]
                }
            };
        }
            ;
        case
        ADD_ORDER_PRODUCT_DATA:
            return {
                ...state,
                order: {
                    ...state.order,
                    product: [
                        ...state.order.product,
                        {
                            id: _getItemID(state.order.product),
                            name: action.name,
                            count: action.count
                        }
                    ]
                }
            };
        case DELETE_ORDER_PRODUCT_DATA:
            const delete_product_data = [...state.order.product].filter(e => Number(e.id) !== Number(action.id));
            let sizePart = state.order.partSizesType;
            return {
                ...state,
                order: {
                    ...state.order,
                    product: [...delete_product_data],
                    part: [...state.order.part]?.map(part => {
                        let find_product = delete_product_data.find(prod => Number(prod.id) === Number(part.product))
                        return {
                            ...part, product: find_product ? find_product.id : null,
                            goods_service_added_firm: getCalculateAdditionalGoodsServiceOfPart(part, sizePart, find_product?.count || 1)
                        }
                    })
                }
            };
        case
        CHANGE_OPERATION_DATA:
            let parts_bore_edit = [...state.order.part].map(e => {
                if (Number(action.part_id) === Number(e.id)) {
                    switch (action.operation_type) {
                        case "bore":
                            e.operations.bore = e.operations.bore.map(e => {
                                if (Number(action.operation.id) === Number(e.id)) {
                                    return {
                                        ...e,
                                        ..._constructorBore(action.operation, action.side, action.operation.id)
                                    };
                                } else {
                                    return e;
                                }
                            });
                            break;
                        case "paz":
                            e.operations.paz = e.operations.paz.map(e => {
                                if (Number(action.operation.id) === Number(e.id)) {
                                    return {
                                        ...e,
                                        ..._constructorPaz(action.operation, action.side, action.operation.id)
                                    };
                                } else {
                                    return e;
                                }
                            });
                            break;
                        case "hem":
                            e.operations.hem = e.operations.hem.map(hem => {
                                if (Number(action.operation.id) === Number(hem.id)) {
                                    e.z = Number(hem.material_z) + Number(action.material_z);
                                    return {...hem, ..._constructorHem(action.operation, action.side, action.material_z, action.operation.id)};
                                } else {
                                    return hem;
                                }
                            });
                        case "cut_to":
                            e.operations.cut_to = e.operations.cut_to.map(e => {
                                if (Number(action.operation.id) === Number(e.id)) {

                                    return {...e, ..._constructorCutTo(action.operation, action.side, action.operation.id)}
                                } else {
                                    return e;
                                }
                            });
                            break;
                        case "trough":
                            e.operations.trough = e.operations.trough.map(e => {
                                if (Number(action.operation.id) === Number(e.id)) {
                                    let result = {...e, ..._constructorTrough(action.operation, action.side, action.operation.id)};
                                    let {f_y, f_x, ...reset} = result;

                                    return {
                                        ...reset,
                                        // f_y1: result.formula_y,
                                        // f_x1: result.formula_x,
                                    }
                                } else {
                                    return e;
                                }
                            });
                            break;
                    }

                }
                return e;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    part: [
                        ...parts_bore_edit
                    ]
                }
            };
        case DELETE_All_OPERATION_DATA: {
            let part_ids = action.payload;
            let store_part = state.order.part;
            let initial_operation = {
                bore: [],
                cut_to: [],
                frez: [],
                hem: [],
                paz: [],
                through: []
            }
            let parts_update = store_part?.map(part => {
                let idInclude = part_ids?.find(id => Number(part.id) === Number(id));
                if (idInclude) {
                    return {
                        ...part,
                        srez: {
                            b: {otstup: "", ugol: "", db_id: ""},
                            l: {otstup: "", ugol: "", db_id: ""},
                            r: {otstup: "", ugol: "", db_id: ""},
                            t: {otstup: "", ugol: "", db_id: ""}
                        },
                        operations: {
                            ...initial_operation
                        },
                        operations_inputs: {
                            ...initial_operation
                        }
                    }
                }
                return part
            })
            return {
                ...state,
                order: {
                    ...state.order,
                    part: parts_update
                }
            }
        }
        case DELETE_OPERATION_DATA: {
            let parts_operation_delete = [...state.order.part].map(e => {
                if (Number(action.part_id) === Number(e.id)) {
                    switch (action.operation_type) {
                        case "bore":
                            e.operations.bore = e.operations.bore.filter(e => Number(action.operation_id) !== Number(e.id));
                            break;
                        case "srez":
                            if (Number(e.id) == Number(action.part_id)) {
                                e.srez = {
                                    b: {otstup: "", ugol: "", db_id: ""},
                                    l: {otstup: "", ugol: "", db_id: ""},
                                    r: {otstup: "", ugol: "", db_id: ""},
                                    t: {otstup: "", ugol: "", db_id: ""}
                                };
                            }
                            break;
                        case "paz":
                            e.operations.paz = e.operations.paz.filter(e => Number(action.operation_id) !== Number(e.id));
                            break;

                        case "hem":
                            const hem = e.operations.hem.filter(e => Number(action.operation_id) === Number(e.id))[0];
                            e.operations.hem = e.operations.hem.filter(e => Number(action.operation_id) !== Number(e.id));
                            e.z = Number(e.z) - Number(hem.material_z);
                            break;
                        case "cut_to":
                            e.operations.cut_to = e.operations.cut_to.filter(e => {
                                return Number(action.operation_id) !== Number(e.id);
                            });
                            break;
                        case "trough":
                            e.operations.trough = e.operations.trough.filter(e => Number(action.operation_id) !== Number(e.id));
                            break;
                        case "template": {
                            let operationIdForDelete = Number(action.operation_id);
                            let itemRemovedFrez = e.operations.frez.find(e => operationIdForDelete === Number(e.id))
                            let remainingFrez = e.operations.frez.filter(e => operationIdForDelete !== Number(e.id));
                            if (itemRemovedFrez?.code && Array.isArray(e.operations.frez_newgo)) {
                                e.operations.frez_newgo = e.operations.frez_newgo.filter((item) => item.code !== itemRemovedFrez.code);
                            }
                            e.operations.frez = e.operations.frez.filter(e => operationIdForDelete !== Number(e.id));
                            e.operations_inputs.frez = e.operations_inputs.frez.filter(e => operationIdForDelete !== Number(e.id));
                            let edgePart = {...e.edge};
                            let updateEdgeSide = getUpdateKeyEdgeSettingsAfterFrezRemoval(itemRemovedFrez, remainingFrez);
                            if (Array.isArray(updateEdgeSide) && updateEdgeSide?.length) {
                                updateEdgeSide?.forEach(side => {
                                    let edge = {...edgePart[side]};
                                    let {ifp_curve, ...reset} = edge
                                    edgePart[side] = {
                                        ...reset,
                                        curve: 0
                                    }
                                })
                            }
                            e.edge = edgePart;
                            break;
                        }
                    }
                }

                return e;
            });
            // const gr = state.order.gr;
            // let pazTemplateClean = gr;
            // if (action.operation_type === "paz") {
            //     pazTemplateClean = pazTemplateCleanByPartIdMapping([Number(action.part_id)], gr)
            // }

            return {
                ...state,
                order: {
                    ...state.order,
                    part: [
                        ...parts_operation_delete
                    ],
                    // gr: pazTemplateClean
                }
            };
        }

        case ADD_NEW_TEMPLATE_OPERATIONS: {

            const parts_template_operations = [...state.order.part].map((part) => {
                if (part.id === action.part_id) {
                    if (action.operations.frez) {
                        part = _addTemplateOperations(action.operations, "frez", action.form, part, action.side);
                    }
                    if (action.operations.bore) {
                        part = _addTemplateOperations(action.operations, "bore", action.form, part, action.side);
                    }
                    if (action.operations.paz) {
                        part = _addTemplateOperations(action.operations, "paz", action.form, part, action.side);
                    }
                    if (action.operations.trough) {
                        part = _addTemplateOperations(action.operations, "trough", action.form, part, action.side);
                    }

                    return part;
                } else {
                    return part;
                }
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    part: [
                        ...parts_template_operations
                    ]
                }
            };
        }
        case
        EDIT_NEW_TEMPLATE_OPERATIONS: {

            const parts_template_operations = [...state.order.part].map((part) => {
                if (part.id === action.part_id) {
                    if (action.operations.frez) {
                        part = _ediTemplateOperations(action.operations, "frez", action.form, part, action.side, action.frezId);
                    }
                    // if (action.operations.bore) {
                    //   part = _addTemplateOperations(action.operations, "bore", action.form, part, action.side);
                    // }
                    // if (action.operations.paz) {
                    //   part = _addTemplateOperations(action.operations, "paz", action.form, part, action.side);
                    // }
                    // if (action.operations.trough) {
                    //   part = _addTemplateOperations(action.operations, "trough", action.form, part, action.side);
                    // }
                    return part;
                } else {
                    return part;
                }
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    part: [
                        ...parts_template_operations
                    ]
                }
            };
        }

        case
        CHANGE_ADD_PART_FORM_VALUE:
            let add_part_form = {...state.addPartForm};
            add_part_form[action.input] = action.value;
            return {
                ...state,
                addPartForm: add_part_form
            };
        case CHANGE_ORDER_CONFIRM_FORM_VALUE_DATA:
            let confirm = {...state.confirm};
            confirm[action.input] = action.value;
            return {
                ...state,
                confirm: confirm
            };
        case CHANGE_DATA_ORDER_CONFIRM_FORM_VALUE_DATA: {
            return {
                ...state,
                confirm: {
                    ...state.confirm,
                    ...action.payload
                }
            };
        }
        case CHANGE_ORDER_MATERIAL_VALUE_DATA:
            const change_materials = [...state.order.material].map(e => {
                if (Number(e.goods_id) === Number(action.id)) {
                    e[action.input] = action.value;
                }
                ;
                return e;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    material: change_materials
                }
            };
        case
        CHANGE_ORDER_MATERIAL_ADDITIONAL_DATA:
            const materials_additional = [...state.order.material].map(e => {
                if (Number(e.goods_id) === Number(action.id)) {
                    e.additional = {
                        ...e.additional,
                        [action.input]: action.value
                    };
                }
                ;
                return e;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    material: materials_additional
                }
            };
        case
        CHANGE_ORDER_BAND_ADDITIONAL_DATA:
            const bands_additional = [...state.order.band].map(e => {
                if (Number(e.goods_id) === Number(action.id)) {
                    e.additional[action.input] = action.value;
                }
                ;
                return e;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    band: bands_additional
                }
            };
        case
        SET_DEFAULT_MATERIAL_ADITIONAL_DATA:
            const materials_additional_default = [...state.order.material].map(e => {
                if (Number(e.goods_id) === Number(action.id)) {
                    if (!e.hasOwnProperty("additional") || Array.isArray(e?.additional)) {
                        e.additional = {
                            trimL: "",
                            trimW: "",
                            rez_napr: "",
                            active: 1,
                            turn: "",
                            hard_cut: ""
                        };
                    } else {
                        e.additional = {
                            ...e.additional,
                            active: 1
                        };
                    }
                }
                return e;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    material: materials_additional_default
                }
            };
        case
        SET_DEFAULT_BAND_ADITIONAL_DATA:
            const bands_additional_default = [...state.order.band].map(e => {
                if (Number(e.goods_id) === Number(action.id)) {
                    if (!e.additional) {
                        e.additional = {
                            per: "",
                            rest: "",
                            min: ""
                        };
                    }
                }
                ;
                return e;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    band: bands_additional_default
                }
            };
        case CHANGE_PRODUCTS_VALUES_DATA:
            let products_change = [...state.order.product].map(e => {
                if (Number(e.id) === Number(action.id)) {
                    e[action.input] = action.value;
                }
                return e;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    product: products_change
                }
            };
        case SET_PARTS_PREVIEWS:
            return {
                ...state,
                partsPreview: action.preview
            };
        case
        SET_PART_PREVIEW_ITEM: {
            let updatePreview = [...state.partsPreview].map(item => {
                if (Number(item.id) === Number(action.preview.id)) {
                    return {

                        ...action.preview
                    };
                }
                return {
                    ...item
                };
            });

            return {
                ...state,
                partsPreview: updatePreview
            };
        }
        case
        SET_CUTTING_PREVIEWS:
            return {
                ...state,
                partsPreviewCutting: action.preview
            };
        case
        NOT_FETCHING_IMG:
            return {
                ...state,
                notFetchingImg: action.payload
            };
        case
        SET_PART_WITH_SIZES:

            let parts_with_sizes = [...state.order.part].map(e => Number(action.part.id) === Number(e.id) ?
                {
                    ...e,
                    ...action.part
                } : e);
            return {
                ...state,
                order: {
                    ...state.order,
                    part: [
                        ...parts_with_sizes
                    ]
                }
            };
        case
        SET_ORDER_CALCULATE_DATA:
            let calc_types = _constructorCalculateDiscountsAndTypes(state.order.calculate_types, action.calculate.material, "calculate_types");
            let calc_discount_material = _constructorCalculateDiscountsAndTypes(state.order.calculate_discounts.material, action.calculate.material, "calculate_discounts");
            let calc_discount_service = _constructorCalculateDiscountsAndTypes(state.order.calculate_discounts.service, action.calculate.service, "calculate_discounts_service");
            let calc_discount_goods = _constructorCalculateDiscountsAndTypes(state.order.calculate_discounts.goods, action.calculate.goods, "calculate_discounts");
            let materials_after_calculate = state.order.material.map(e => {
                action.calculate.material.forEach(m => {
                    if (Number(e.goods_id) === Number(m.id)) {
                        if (!e.hasOwnProperty("res_to_client")) {
                            e.res_to_client = 1;
                        } else {
                            if (e.res_to_client !== 0 && e.res_to_client !== 1) {
                                if (m.calc_type === "m") {
                                    e.res_to_client = 0;
                                } else {
                                    e.res_to_client = 1;
                                }
                            }
                        }
                        return e;
                    }
                });
                return e;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    goods: action.calculate.goods_update,
                    material: materials_after_calculate,
                    calculate: action.calculate,
                    calculate_types: calc_types,
                    calculate_discounts: {
                        material: calc_discount_material,
                        service: calc_discount_service,
                        goods: calc_discount_goods
                    },

                    calc: action.calculate.calc,
                    calculate_vals: action.calculate.vals
                },
                pack: action.calculate.hasOwnProperty("pack") ? action.calculate.pack : {}
            };
        case
        CHANGE_MATERIALS_CALCULATE_TYPE:
            let calculate_materials_type = [...state.order.calculate_types].map((e) => {
                if (Number(e.id) === Number(action.material_id)) {
                    e.calc_type = action.calculate_type;
                }
                return e;
            });
            let materials = [state.order.calculate.material].map(e => {
                return {
                    id: e.id,
                    calc_type: e.calc_type ? e.calc_type : "sheet"
                };
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    calculate_types: calculate_materials_type,
                    calculate: {
                        ...state.calculate,
                        material: materials
                    }
                }
            };
        case
        CHANGE_BANDS_TYPE_DATA:
            const bands_type = [...state.order.bands_type].map(e => {
                if (Number(e.band) === Number(action.material.band)) {
                    return action.material;
                }
                ;
                return e;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    bands_type: bands_type
                }
            };
        case
        CHANGE_MATERIALS_CALCULATE_RES_TO_CLIENT:
            let calculate_materials_rest = state.order.material.map(e => {
                if (Number(e.goods_id) === Number(action.material_id)) {
                    e.res_to_client = action.status;
                }
                return e;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    material: calculate_materials_rest
                }
            };
        case
        CHANGE_ORDER_CALCULATE_DISCOUNTS_DATA:
            const change_calculate_discounts_data = state.order.calculate_discounts[action.data_type].map(e => {
                if (Number(e.id) === Number(action.id)) {
                    e[action.input] = action.value;
                    if (action.input === "price") {
                        e.currency = state.order.currency;
                    }
                }
                return e;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    calculate_discounts: {
                        material: action.data_type === "material" ? change_calculate_discounts_data : [...state.order.calculate_discounts.material],
                        goods: action.data_type === "goods" ? change_calculate_discounts_data : [...state.order.calculate_discounts.goods],
                        service: action.data_type === "service" ? change_calculate_discounts_data : [...state.order.calculate_discounts.service]
                    }
                }
            };
        case
        CHANGE_FOR_SALE_MATERIALS_COUNT:
            // eslint-disable-next-line default-case
            switch (action.data_type) {
                case "material":
                    return {
                        ...state,
                        order: {
                            ...state.order,
                            material: [...state.order.material].map(e => {
                                if (Number(e.goods_id) === Number(action.id)) {
                                    e.forSale = action.count;
                                }
                                return e;
                            })
                        }
                    };
                case "band":
                    return {
                        ...state,
                        order: {
                            ...state.order,
                            band: [...state.order.band].map(e => {
                                if (Number(e.goods_id) === Number(action.id)) {
                                    e.forSale = action.count;
                                }
                                return e;
                            })
                        }
                    };
                case "goods":
                    return {
                        ...state,
                        order: {
                            ...state.order,
                            goods: [...state.order.goods].map(e => {
                                if (Number(e.goods_id) === Number(action.id)) {
                                    e.count = action.count;
                                }
                                return e;
                            })
                        }
                    };
            }
            break;
        case CLEAR_ALL_CALCULATE_VALS_DATA: {
            return {
                ...state,
                order: {
                    ...state.order,
                    goods: state?.order?.goods?.filter(e => e?.type !== "band"),
                    calculate_vals: [],
                    calculate: [],
                    calc: null,
                    // calculate_project: null,
                    // calculate_discounts: {
                    //     "material": [],
                    //     "service": [],
                    //     "goods": []
                    // },
                    // calculate_types: [],

                }
            };
        }
        case
        CLEAR_CALCULATE_VALS_DATA:
            return {
                ...state,
                order: {
                    ...state.order,
                    // calculate_vals: [],
                    // calculate: [],
                    // calc: null,
                    // // calculate_project: null,
                    // calculate_discounts: {
                    //     "material": [],
                    //     "service": [],
                    //     "goods": []
                    // },
                    // calculate_types: [],
                    calculate_vals: null,
                    calc: null,
                    calculate_project: null
                }
            };
        case
        COPY_OPERATION_ITEM: {
            let {partId, operation, type} = action.payload;
            let part_copy_operation = state.order.part.map(part => {
                if (part.id == partId) {
                    part.operations[type].push(operation.operations);
                    if (operation.operations_inputs != null) {
                        part.operations_inputs[type].push(operation.operations_inputs);
                    }
                }
                return part;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    part: [...part_copy_operation]
                }
            };
            break;
        }
        case MIRRORING_OPERATION_ITEM: {

            let {type, partId, operation, mirroringType} = action.payload;

            let change_part_operation = state.order.part.map(p => {
                if (Number(p.id) === Number(partId)) {
                    switch (type) {
                        case "trough":
                        case "hem":
                        case "frez":
                        case "bore":
                        case "cut_to": {
                            for (let i = 0; i < p.operations[type].length; i++) {
                                if (p.operations[type][i].id === operation.id) {
                                    p.operations[type][i] = {
                                        ..._setMirroringOperations(p.operations[type][i], mirroringType)
                                    };
                                }
                            }
                            break;
                        }
                        case "srez": {
                            p[type] = {
                                ...p[type],
                                ..._setMirroringOperations(p[type], mirroringType)
                            };
                            break;
                        }
                        case "paz": {
                            for (let i = 0; i < p.operations[type].length; i++) {
                                if (p.operations[type][i].id === operation.id) {
                                    let operations = _setMirroringOperations(p.operations[type][i], mirroringType);
                                    p.operations[type][i] = {
                                        ...operations
                                    };
                                }
                            }
                            break;
                        }
                    }


                }
                return p;
            });

            return {
                ...state,
                order: {
                    ...state.order,
                    part: [
                        ...change_part_operation
                    ]
                }
            };

        }
        case GLOBAL_CHANGE_MIRRORING: {
            let {partId, mirroringType, typeChange} = action.payload;
            const partsOrder = Array.isArray(state.order.part) ? state.order.part : [];
            const parts = partsOrder.map(part => {
                if (Number(part.id) === Number(partId)) {
                    for (let property in part.operations) {
                        const operationByKey = Array.isArray(part.operations[property]) ? part.operations[property] : [];
                        const copy = operationByKey.map(p => {
                            if (property === "paz" && p?.hasOwnProperty("id_template_paz")) {
                                return _setMirroringOperations(p, mirroringType);
                            }
                            return _setMirroringOperations(p, mirroringType);
                        });

                        part.operations[property] = [
                            ...copy
                        ];
                    }
                    part.srez = {
                        ...part.srez,
                        ..._setMirroringOperations(part.srez, mirroringType)
                    };

                    let copyEdgeL = {
                        ...part.edge["l"]
                    };
                    let copyEdgeR = {
                        ...part.edge["r"]
                    };
                    let copyEdgeT = {
                        ...part.edge["t"]
                    };
                    let copyEdgeB = {
                        ...part.edge["b"]
                    };
                    switch (mirroringType) {
                        case "hor": {
                            part.edge = {
                                ...part.edge,
                                l: {
                                    ...copyEdgeR
                                },
                                r: {
                                    ...copyEdgeL
                                }

                            };


                            break;
                        }
                        case "ver": {
                            part.edge = {
                                ...part.edge,
                                b: {
                                    ...copyEdgeT
                                },
                                t: {
                                    ...copyEdgeB
                                }

                            };

                        }
                    }

                }
                return part;

            });
            const {part: partsUp, gr: grUpdate, material: materialUpdate} = globalChangePositionGr({
                parts,
                goods_id: 0,
                material: state.order.material,
                gr: [...state?.order?.gr]
            });

            return {
                ...state,
                order: {
                    ...state.order,
                    part: partsUp,
                    gr: grUpdate,
                    material: materialUpdate

                }

            };
        }
        case
        CHANGE_TURNS_PART: {
            let {part, turns} = action.payload;
            const parts = state.order.part.map(p => {
                if (Number(p.id) === Number(part.id)) {
                    return {
                        ...p,
                        turn: turns ?? 0
                    };
                }
                return p;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    part: [...parts]
                }
            };
            break;
        }
        case
        MIRRORING_ACTIONS_OPERATION_ITEM: {
            let {partId, types, mirroringType} = action.payload;
            const parts = state.order.part.map(p => {

                if (Number(p.id) === Number(partId)) {
                    let partUpdate = changeMirroringTypeInPart({part: p, types, mirroringType});
                    return {
                        ...partUpdate
                    };
                }
                return p;
            });

            const {part: partsUp, gr: grUpdate, material: materialUpdate} = globalChangePositionGr({
                parts,
                goods_id: 0,
                material: state.order.material,
                gr: [...state?.order?.gr]
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    part: [...parts],
                    gr: grUpdate,
                    material: materialUpdate

                }
            };
            break;
        }
        case
        CHANGE_FOR_MATERIALS_PARTS_SALE_COUNT: {
            let parts_forSale = state.order.parts_forSale || [];
            let forSale = [];
            switch (action.operation) {
                case "BUY": {
                    if (parts_forSale.length == 0 || parts_forSale.every(s => Number(s.goods_stock_id) != Number(action.part.goods_stock_id))) {
                        parts_forSale.push({...action.part});
                    }
                    forSale = [...parts_forSale].map(s => {
                        if (Number(s.goods_stock_id) === Number(action.part.goods_stock_id)) {
                            return {
                                ...action.part,
                                forSale: action.value
                            };
                        }
                        return s;
                    }).filter(s => Number(s.forSale) != 0 || Number(s.forSale) < 0);

                    break;
                }
                case "DELETE": {
                    forSale = [];
                }

            }


            return {
                ...state,
                order: {
                    ...state.order,
                    parts_forSale: forSale
                }

            };
        }
        case
        CHANGE_BATCH_MACHINING_HOLES_FORM_VALUES: {
            let fields = state.batchMachiningHolesForm;
            fields[action.input] = action.value;
            if (action.input == "replacement") {
                fields = {
                    ...fields,
                    depth_from: "",
                    depth_to: "",
                    diameter_from: "",
                    diameter_to: "",
                    plane_from: "",
                    plane_to: ""
                };
            }
            return {
                ...state,
                batchMachiningHolesForm: {
                    ...state.batchMachiningHolesForm,
                    ...fields
                }
            };
        }
        case
        UPDATE_BATCH_MACHINING_HOLES_PART: {
            return {
                ...state,
                order: {
                    ...state.order,
                    part: [...action.payload]
                }
            };
        }
        case
        RESET_BATCH_MACHINING_FORM: {
            return {
                ...state,
                batchMachiningHolesForm: {
                    material: [{
                        goods_id: ""
                    }],
                    parts: [{id: ""}],
                    side: "",
                    replacement: "",
                    depth_from: "",
                    depth_to: "",
                    plane_from: "",
                    plane_to: "",
                    diameter_to: "",
                    diameter_from: "",
                    operations: "bore"
                }
            };
        }
        case
        CLIENT_ON_CHANGE_MATERIAL_SIZES: {
            let material = state.order.material;
            let mt = material.map(m => {
                if (Number(m.goods_id) === Number(action.payload.goods_id)) {
                    return {
                        ...m,
                        x: Number(action.payload.x),
                        y: Number(action.payload.y),
                        z: Number(action.payload.z)
                    };
                }
                return m;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    material: mt
                }
            };
        }
        case
        CLIENT_ON_CHANGE_BAND_SIZES: {
            let band = state.order.band;
            let bd = band.map(b => {
                if (Number(b.id) === Number(action.payload.id)) {
                    return {
                        ...b,
                        z: Number(action.payload.z),
                        y: Number(action.payload.y)
                    };
                }
                return b;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    band: bd
                }
            };
        }
        case
        CLIENT_MATERIAL_CHANGE_COUNT: {
            let material = state.order.material;
            let mt = material.map(m => {
                if (Number(m.goods_id) === Number(action.payload.id)) {
                    return {
                        ...m,
                        count: Number(action.payload.count)
                    };
                }
                return m;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    material: mt
                }
            };
        }
        case
        ON_CLEAR_DETAIL_NOTES: {
            return {
                ...state,
                order: {
                    ...state.order,
                    part: [...state.order.part].map(p => ({...p, info_user: null}))
                }
            };
        }
        case
        SUCCESS_SAVE_ORDER_PROJECT : {
            return {
                ...state,
                successSaveOrder: action.value
            };
        }
        case
        INCONFORMOTY_DATA_MEMORY_STORE : {
            return {
                ...state,
                memoryChangeDtOrder: action.value
            };
        }
        case SET_ORDER_COPY : {
            return {
                ...state,
                orderCopy: action.value
            };
        }
        case
        SET_EDGE_TYPE_PART : {
            let {value, id} = action.payload;
            const edgeTypes =
                {
                    eva: "eva",
                    pur: "pur",
                    laz: "laz"
                };

            let parts = state.order.part;
            let partsUpdate = parts.map(p => {
                if (Number(p.id) === Number(id)) {
                    p.edge_type_part = value;

                    for (const [key, obj] of Object.entries(p.edge)) {

                        p.edge[key] = {
                            ...p.edge[key],
                            [edgeTypes.eva]: value?.trim()?.toUpperCase() === edgeTypes.eva.trim().toUpperCase() ? 1 : 0,
                            [edgeTypes.pur]: value?.trim()?.toUpperCase() === edgeTypes.pur.trim().toUpperCase() ? 1 : 0,
                            [edgeTypes.laz]: value?.trim()?.toUpperCase() === edgeTypes.laz.trim().toUpperCase() ? 1 : 0
                        };

                    }
                }
                return p;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    part: partsUpdate

                }
            };
        }
        case
        SET_ALL_EDGE_TYPE_PARTS: {
            let parts = state.order.part,
                bands_type = state.order.bands_type;
            let partsUpdate = parts.map(p => {
                for (let b of bands_type) {
                    if (Number(b.band) === Number(p.material)) {
                        p.edge_type_part = b.type;
                    }
                }
                const edgeTypes =
                    {
                        eva: "eva",
                        pur: "pur",
                        laz: "laz"
                    };
                for (const [key, obj] of Object.entries(p.edge)) {

                    p.edge[key] = {
                        ...p.edge[key],
                        [edgeTypes.eva]: p.edge_type_part?.trim()?.toUpperCase() === edgeTypes.eva.trim().toUpperCase() ? 1 : 0,
                        [edgeTypes.pur]: p.edge_type_part?.trim()?.toUpperCase() === edgeTypes.pur.trim().toUpperCase() ? 1 : 0,
                        [edgeTypes.laz]: p.edge_type_part?.trim()?.toUpperCase() === edgeTypes.laz.trim().toUpperCase() ? 1 : 0
                    };

                }
                return p;
            });
            return {
                ...state,
                order: {
                    ...state.order,
                    part: partsUpdate
                }
            };
        }
        case CLEAR_TRANSACTIONS_HISTORY_PART: {
            let parts = state.order.part;
            const gr = Array.isArray(state.order?.gr) ? state.order.gr : [];
            const grClean = pazTemplateCleanByPartIdMapping([action.part_id], gr)
            let partsCleanCnc = [...parts].map(p => {
                if (Number(p.id) === Number(action.part_id)) {
                    const result = cleanCncPartMapping(p, gr)
                    return result;
                }
                return p;

            });

            return {
                ...state,
                order: {
                    ...state.order,
                    part: partsCleanCnc,
                    gr: grClean
                }
            };
        }
        case
        ORDER_CHANGE_LANG: {
            return {
                ...state,
                order: {
                    ...state.order,
                    lang: action.payload
                }
            };
        }
        case ORDER_DETAIL_UPDATE: {
            const updateParts = state.order.part.map(p => {
                if (Number(p.id) === Number(action.payload.partId)) {
                    return {
                        ...p,
                        ...action.payload.part[0],
                        id: action.payload.partId
                    };
                }
                return p;
            });
            const {part: partsUp, gr: grUpdate, material: materialUpdate} = globalChangePositionGr({
                parts: updateParts,
                goods_id: 0,
                material: state.order.material,
                gr: [...state?.order?.gr]
            });

            return {
                ...state,
                order: {
                    ...state.order,
                    part: updateParts,
                    gr: grUpdate,
                    material: materialUpdate
                }
            };
        }
        case
        CHANGE_MATERIAL_OPTION_VALUE: {
            const {goods_id, input, value} = action?.payload;
            const updateMaterial = state?.order?.material?.map(material => {
                if (Number(material?.goods_id) === Number(goods_id)) {
                    return {
                        ...material,
                        [input]: value
                    };
                }
                return material;
            });

            return {
                ...state,
                order: {
                    ...state?.order,
                    material: updateMaterial
                }
            };
        }
        case
        CHANGE_CURRENT_MATERIAL_PARTS_EDGE_CONNECTED: {
            const {goods_id, band_only_connected} = action?.payload;
            const currentMaterial = state?.order?.material?.find(material => Number(material?.goods_id) === Number(goods_id));
            const updateParts = state?.order?.part?.map(part => {
                if (Number(part?.material) === Number(currentMaterial?.goods_id)) {
                    const partHem = part?.operations?.hem?.[0];
                    for (const [key, value] of Object.entries(part?.edge)) {
                        let findConnectedBand = currentMaterial?.connected_band?.find(band => Number(value?.db_id) === Number(band?.id));
                        if (!findConnectedBand && (band_only_connected)) {

                            part = {
                                ...part,
                                edge: {
                                    ...part?.edge,
                                    [key]: {
                                        db_id: null,
                                        cut: null,
                                        curve: 0,
                                        eva: null,
                                        laz: null,
                                        pre_joint: 1,
                                        pur: 1,
                                        t: 0,
                                        w: null
                                    }
                                }
                            };
                        }
                    }
                }
                return part;
            });

            return {
                ...state,
                order: {
                    ...state?.order,
                    part: updateParts
                }
            };
        }

        case
        REMOVE_ORDER_GOODS: {
            return {
                ...state
            };
        }
        case ORDER_MATERIAL_TOGGLE_IS_BAND_CONNECTED: {
            let band_state = state.order.band;
            let material_state = state.order.material;
            let material = action.payload;
            const un_connected_bands = material?.connected_band?.filter(band => !band_state?.find(item => Number(item.goods_id) === Number(band.id)));

            return {
                ...state,
                order: {
                    ...state?.order,
                    band: [...band_state, ...un_connected_bands],
                    material: material_state?.map(item => {
                        let is_find = Number(item.goods_id) === Number(material.goods_id);
                        if (is_find) {
                            return {
                                ...item, band_only_connected: Number(!item.band_only_connected)
                            }
                        }
                        return {...item}
                    })
                }
            }
        }

        default:
            return state;
    }
}

export const materialCombineUpdateDependencies = (material) => {
    return {
        type: MATERIAL_COMBINE_UPDATE_DEPENDENCIES,
        payload: material
    };
};
export const onOperationsSelectedDeleteInPart = (payload) => ({
    type: OPERATIONS_SELECTED_DELETE, payload: payload
});
export const removeAllPartsInMaterial = (materialGoodsId) => ({
    type: REMOVE_ALL_PARTS_IN_MATERIAL, payload: materialGoodsId
});
export const setCuttingCardInProject = (cutting_card = []) => ({
    type: SET_CUTTING_CARD, payload: cutting_card
});
export const updateCuttingOwnerUiCutDropById = (cardId, ownerUiCutDropId) => {
    return {
        type: CUTTING_CARD_UPDATE_OWNER_UI_CUT_DROP_ID,
        payload: {cardId: cardId, ownerUiCutDropId: ownerUiCutDropId}
    }
}
export const addPlatformOperationPartCutTo = ({partId, materialId, form, side}) => ({
    type: ADD_POSTFORM_OPERATION_FOR_PART,
    payload: {
        partId, materialId, form, side
    }
});

export const onChangeMaterialPartsEdgeConnected = ({goods_id, band_only_connected}) => {
    return {
        type: CHANGE_CURRENT_MATERIAL_PARTS_EDGE_CONNECTED,
        payload: {goods_id, band_only_connected}
    };
};
export const onRemovePreJointCurvePartsOfMaterial = ({material_id}) => {
    return {
        type: REMOVE_PRE_JOINT_CURVE_MATERIAL_PARTS_EDGE,
        payload: {material_id}
    };
};
export const onChangeMaterialOptionValue = ({goods_id, input, value}) => {
    return {
        type: CHANGE_MATERIAL_OPTION_VALUE,
        payload: {goods_id, input, value}
    };
};
export const setOrderProjectDataAC = (order) => {
    return {
        type: SET_PROJECT_DATA,
        order: order
    };
};
export const changeOrderTitleAC = (title) => {
    if (!title || title.length === 0) title = " ";
    return {
        type: CHANGE_ORDER_TITLE,
        title: title
    };
};
export const addOrderMaterialAC = (material) => {
    switch (material.type) {
        case "part":
            return {
                type: ADD_ORDER_MATERIAL,
                material: material
            };
        case "band":
            return {
                type: ADD_ORDER_BAND,
                material: material
            };
        case "product":
            return {
                type: ADD_ORDER_GOODS,
                material: material
            };
    }
};
export const deleteOrderMaterialAC = (material) => {
    switch (material.type) {
        case "part":
            return {
                type: DELETE_MATERIALS_ORDER_DATA,
                material: material.id
            };
        case "band":
            return {
                type: DELETE_BANDS_ORDER_DATA,
                band: material.id
            };
        case "product":
            return {
                type: DELETE_GOODS_ORDERS_DATA,
                id: material.id
            };
    }
};
export const changeAddPartFormValuesAC = (input, value) => {
    return {
        type: CHANGE_ADD_PART_FORM_VALUE,
        input: input,
        value: value
    };
};
export const addBoxGoodsDataAC = (material) => {
    return {
        type: ADD_ORDER_GOODS,
        material: material
    };
};
export const addOrderBoxAC = (box) => {
    return {
        type: ADD_ORDER_BOX,
        box: box
    };
};
export const addNewPartAC = (part, id, isNext = false, mirroringType = false, operationType = false,) => {
    return {
        type: ADD_NEW_PART,
        part: part,
        id: id,
        isNext: isNext,
        mirroringType: mirroringType, operationType: operationType
    };
};
export const addNewArrayPartsAC = (parts, id) => {
    return {
        type: ADD_NEW_ARRAY_PARTS,
        parts: parts,
        id: id,
    };
};
export const addNewPartBoxAC = (part) => {
    return {
        type: ADD_NEW_PART_BOX,
        part: part
    };
};
export const setPartsPreviewAC = (preview) => {
    return {
        type: SET_PARTS_PREVIEWS,
        preview: preview
    };
};
export const setPartPreviewItemAC = (preview) => {
    return {
        type: SET_PART_PREVIEW_ITEM,
        preview: preview
    };
};
export const setCuttingPreviewAC = (preview) => {
    return {
        type: SET_CUTTING_PREVIEWS,
        preview: preview
    };
};
export const notFetchingImg = (payload) => {
    return {
        type: NOT_FETCHING_IMG,
        payload: payload
    };
};
export const changePartDataAC = (part) => {
    return {
        type: CHANGE_PART_DATA,
        part: part
    };
};
export const changeAllPartAC = (parts) => {
    return {
        type: UPDATE_ALL_PART,
        payload: parts
    };
};
export const updateAllPartInOrderAC = (payload) => {
    return {
        type: PART_CHANGE_ALL_DATA,
        payload: payload
    };
};

export const addNewBoreAC = (part_id, side, form) => {
    return {
        type: ADD_NEW_BORE,
        part_id: part_id,
        side: side,
        form: form
    };
};
export const addNewPazAC = (part_id, side, form) => {
    return {
        type: ADD_NEW_PAZ,
        part_id: part_id,
        side: side,
        form: form
    };
};
export const addNewHemAC = (part_id, side, form, material_z) => {
    return {
        type: ADD_NEW_HEM,
        part_id: part_id,
        side: side,
        form: form,
        material_z: material_z
    };
};
export const addNewCutAC = (part_id, side, form) => {
    return {
        type: ADD_NEW_CUT_TO,
        part_id: part_id,
        side: side,
        form: form
    };
};

export const addNewTroughAC = (part_id, side, form) => {
    return {
        type: ADD_NEW_TROUGH,
        part_id: part_id,
        side: side,
        form: form
    };
};
export const editFrezOperationBandDataAC = (part_id, operation_id, band) => {
    return {
        type: CHANGE_FREZ_OPERATION_BANDS_DATA,
        part_id: part_id,
        operation_id: operation_id,
        band: band
    };
};
export const editFrezOperationGlassDataAC = (part_id, operation_id, glass) => {
    return {
        type: CHANGE_FREZ_OPERATION_GLASS_EDGE_TYPE_DATA,
        part_id: part_id,
        operation_id: operation_id,
        glass: glass
    };
};
export const changeOrderProductDataAC = (id, input, value) => {
    return {
        type: CHANGE_PRODUCTS_VALUES_DATA,
        id: id,
        input: input,
        value: value
    };
};
export const changeOrderConfirmFormDataAC = (input, value) => {
    return {
        type: CHANGE_ORDER_CONFIRM_FORM_VALUE_DATA,
        input: input,
        value: value
    };
};
export const changeDataOrderConfirmFormDataAC = (payload) => {
    return {
        type: CHANGE_DATA_ORDER_CONFIRM_FORM_VALUE_DATA,
        payload: payload
    };
};
export const changeOrderMaterialValueDataAC = (id, input, value) => {
    return {
        type: CHANGE_ORDER_MATERIAL_VALUE_DATA,
        id: id,
        input: input,
        value: value
    };
};
export const changeOrderAdditionalMaterialDataAC = (id, input, value) => {

    return {
        type: CHANGE_ORDER_MATERIAL_ADDITIONAL_DATA,
        id: id,
        input: input,
        value: value
    };
};
export const changeOrderAdditionalBandDataAC = (id, input, value) => {
    return {
        type: CHANGE_ORDER_BAND_ADDITIONAL_DATA,
        id: id,
        input: input,
        value: value
    };
};
export const setOrderMaterialAditionalDataAS = (id) => {
    return {
        type: SET_DEFAULT_MATERIAL_ADITIONAL_DATA,
        id: id
    };
};
export const setOrderBandAditionalDataAS = (id) => {
    return {
        type: SET_DEFAULT_BAND_ADITIONAL_DATA,
        id: id
    };
};

export const editOrderOperationsAC = (part_id, operation, operation_type, side, material_z) => {
    return {
        type: CHANGE_OPERATION_DATA,
        part_id: part_id,
        operation: operation,
        operation_type: operation_type,
        material_z: material_z,
        side: side
    };
};
export const deleteOrderOperationsAC = (part_id, operation_id, operation_type) => {
    return {
        type: DELETE_OPERATION_DATA,
        part_id: part_id,
        operation_id: operation_id,
        operation_type: operation_type
    };
};

export const setPartWithSizesAC = (part) => {
    return {
        type: SET_PART_WITH_SIZES,
        part: part
    };
};
export const changeOrderUrgentStatusAC = (status) => {
    return {
        type: CHANGE_ORDER_URGENT_DATA,
        status: status
    };
};
export const changeOrderUniversalStatusAC = (status) => {
    return {
        type: CHANGE_ORDER_UNIVERSAL_DATA,
        status: status
    };
};
export const addNewTemplateOperationsAC = (operations, part_id, side, form) => {
    return {
        type: ADD_NEW_TEMPLATE_OPERATIONS,
        operations: operations,
        part_id: part_id,
        side: side,
        form: form
    };
};
export const editNewTemplateOperationsAC = (operations, part_id, side, form, frezId) => {
    return {
        type: EDIT_NEW_TEMPLATE_OPERATIONS,
        operations: operations,
        part_id: part_id,
        side: side,
        form: form,
        frezId: frezId
    };
};
export const clearCalculateValsDataAC = () => {
    return {
        type: CLEAR_CALCULATE_VALS_DATA
    };
};
export const clearALLFieldsCalculateValsDataAC = () => {
    return {
        type: CLEAR_ALL_CALCULATE_VALS_DATA
    };
};
export const setCalculateOrderDataAC = (calculate) => {
    return {
        type: SET_ORDER_CALCULATE_DATA,
        calculate: calculate,

    };
};
export const changeOrderCurrencyDataAC = (currency) => {
    return {
        type: CHANGE_ORDER_CURRENCY_DATA,
        currency: currency
    };
};
export const changeMaterialCalculateTypeAC = (material_id, calculate_type) => {
    return {
        type: CHANGE_MATERIALS_CALCULATE_TYPE,
        material_id: material_id,
        calculate_type: calculate_type
    };
};
export const changeMaterialCalculateResToClientAC = (material_id, status) => {
    return {
        type: CHANGE_MATERIALS_CALCULATE_RES_TO_CLIENT,
        material_id: material_id,
        status: status
    };
};
export const changeCalculateDataDiscountsAC = (input, value, id, data_type) => {
    return {
        type: CHANGE_ORDER_CALCULATE_DISCOUNTS_DATA,
        input: input,
        value: value,
        id: id,
        data_type: data_type
    };
};
export const changeOrderSizeTypeAC = (size_type) => {
    return {
        type: CHANGE_ORDER_SIZE_TYPE,
        size_type: size_type
    };
};
export const setPartsWithSizesAC = (parts, size_type) => {
    return {
        type: SET_PARTS_WITH_SIZES,
        parts: parts,
        size_type
    };
};
export const deleteBoxAC = (id) => {
    return {
        type: DELETE_ORDER_BOX,
        id: id
    };
};
export const changeMaterialBandsTypeDataAC = (material) => {
    return {
        type: CHANGE_BANDS_TYPE_DATA,
        material: material
    };
};
export const deleteOrderPartDataAC = (id) => {
    return {
        type: DELETE_ORDER_PART_DATA,
        id: id
    };
};
export const deleteOrderPartBoxDataAC = (id) => {
    return {
        type: DELETE_ORDER_PART_BOX_DATA,
        id: id
    };
};
export const deleteOrderGoodsDataAC = (id) => {
    return {
        type: DELETE_GOODS_ORDERS_DATA,
        id: id
    };
};
export const addNewOrderExtraDataAC = (extra) => {
    return {
        type: ADD_NEW_ORDER_EXTRA_DATA,
        extra: extra
    };
};
export const deleteOrderExtraData = (id) => {
    return {
        type: DELETE_ORDER_EXTRA_DATA,
        id: id
    };
};
export const addNewOrderProductAC = (name, count) => {
    return {
        type: ADD_ORDER_PRODUCT_DATA,
        name: name,
        count: count
    };
};
export const deleteOrderProductAC = (id) => {
    return {
        type: DELETE_ORDER_PRODUCT_DATA,
        id: id
    };
};
export const changeOrderForSaleCountAC = (id, data_type, count) => {
    return {
        type: CHANGE_FOR_SALE_MATERIALS_COUNT,
        id: id,
        data_type: data_type,
        count: count
    };
};
export const changeOrderMaterialPartForSaleCountAC = ({part, value, operation = "BUY"}) => {
    return {
        type: CHANGE_FOR_MATERIALS_PARTS_SALE_COUNT,
        operation,
        value,
        part
    };
};
export const deleteMaterialOrderDataAC = (material) => {
    return {
        type: DELETE_MATERIALS_ORDER_DATA,
        material: material
    };
};
export const deleteBandOrderDataAC = (band) => {
    return {
        type: DELETE_BANDS_ORDER_DATA,
        band: band
    };
};
export const deleteAllMaterialBandOrderDataAC = () => {
    return {
        type: DELETE_ALL_MATERIALS_BANDS_ORDER_DATA,
    };
};
export const setProductionConstantsDataAC = (data) => {
    return {
        type: SET_PRODUCTION_CONSTANTS_DATA,
        data: data
    };
};

export const compareCurrentOrdersThunk = () => (dispatch, getState) => {
    const state = getState().order;
    const order = state.order;
    const orderCopy = state.orderCopy;
    const isMemoryChangeDtOrder = state.memoryChangeDtOrder;
    if (order && Number(order.status) !== 1) {
        return;
    }

    if (order && orderCopy) {
        let isCompareOrders = compareOrders(orderCopy, order); // if equal return true

        if (!isCompareOrders !== isMemoryChangeDtOrder) {
            dispatch(inconformityMemoryChangeValue(!isCompareOrders));
        }
        return;

    }
};

export const setOrderProjectDataThunk = (memoryTd = false, call = false, get_version = null, clear_goods_for_hand_edge = false) => async (dispatch, getState) => {
    const store = getState();
    const version = store.commons.data.version;
    const code = store.commons.data.code;
    const client = store.auth.user.id;
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Загружаем данные заказа и формируем проект...")));
    return new Promise(async (resolve, reject) => {
        const processing_edge_point = await getFetchingProcessingEdgeData();
        api.getProject(version, code, client, localStorage.getItem(LS_FIRM), true, get_version, processing_edge_point)
            .then(async (response) => {
                if (response) {

                    let res = response;

                    if (clear_goods_for_hand_edge) {
                        res = {
                            ...res, goods: res?.goods?.filter(e => e?.type !== "band")
                        }
                    }
                    let is_generate_pack = res.hasOwnProperty("message_calculate") && res?.message_calculate
                    if (res?.id && !document.title?.includes(res?.id)) document.title = res?.id + " - " + document.title;
                    if (is_generate_pack) {
                        let pack = await generationOfPackagingDispatched(res, dispatch);
                        res = {
                            ...res,
                            packing: pack
                        }
                    }

                    dispatch(setOrderProjectDataAC(res));
                    dispatch(setOrderCopyProject(res));
                    dispatch(processingEdgeSetPointAC(processing_edge_point));
                    // dispatch(getMachineToolPostProcessorFilesThunk());
                    /** получить  доп услуги для заказа и деталей*/
                    await dispatch(orderSetGoodServicePartThunk(res.currency));
                    /** получить  доп услуги для заказа*/
                    await dispatch(getAllAdvancedGoodsServiceByOrderIdThunk(res.id));


                    if (Array.isArray(res?.gr) && res?.gr?.length) {
                        dispatch(initialDetailTemplatePaz());
                    }

                    if (memoryTd || call) {
                        dispatch(inconformityMemoryChangeValue(false));
                        dispatch(changeMemoryDTOrderProjectModalValue("isOpen", false));
                    }

                    if (res.hasOwnProperty("message_calculate") && res?.message_calculate !== "" && !Number(res.production_constants?.["production.old_interface_on"])) {
                        await manipulateSessionCalculate.set(res.message_calculate);
                        let calculate = await manipulateSessionCalculate.get();
                        dispatch(wrapperSendCalcShoppingGb(calculate));
                        dispatch(changeLoaderStatusAC(false));
                        dispatch(changeLoaderMessageAC(false));
                        return resolve()
                    }
                    if (res.hasOwnProperty("message_calculate") && res?.message_calculate !== "" && Number(res.production_constants?.["production.old_interface_on"]) && !Array.isArray(res?.cutting_card)) {
                        await manipulateSessionCalculate.set(res.message_calculate);
                        await dispatch(saveOrderProjectDataThunk(true, false, true));
                        apiImages.getCuttingPreviews(res).then(res => dispatch(setCuttingPreviewAC(res)));
                        dispatch(actionsCuttingChart.onToggleCuttingPage(true));
                        dispatch(changeLoaderStatusAC(false));
                        dispatch(changeLoaderMessageAC(false));
                        return resolve()
                    }
                    dispatch(changeLoaderStatusAC(false));
                    dispatch(changeLoaderMessageAC(false));
                    resolve({order: res});
                } else {
                    if (api._errors) {
                        dispatch(changeAlertStatusAC(true, api._getErrors()));
                    } else {
                        dispatch(changeAlertStatusAC(true, `Error`));
                    }
                    if (client) {
                        window.location.href = '/account'
                    }
                    dispatch(changeLoaderStatusAC(false));
                    dispatch(changeLoaderMessageAC(false));
                    resolve()
                }
            }).catch(e => {
            checkErrorsAPI(typeErrorApi.save, "api:/projects/{version}/?client={client}&order={code}&firm={firm}{need_clean}, M:G");
            dispatch(changeLoaderStatusAC(false));
            dispatch(changeLoaderMessageAC(false));
            resolve()
        });
    })

};
export const getOrderProjectDataThunk = (version, code, client) => (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Загружаем данные заказа и формируем проект...")));
    api.getProject(version, code, client)
        .then(res => {
            if (res) {
                dispatch(setOrderProjectDataAC(res));

            } else {
                if (api._errors) {
                    dispatch(changeAlertStatusAC(true, api._getErrors()));
                }
            }
            dispatch(changeLoaderStatusAC(false));
        })
        .catch(e => {
            checkErrorsAPI(typeErrorApi.save, "api:/projects/{version}/?client={client}&order={code}&firm={firm}{need_clean}, M:G");
            dispatch(changeLoaderStatusAC(false));
        });
};
export const successSaveOrderProject = (value) => {
    return {
        type: SUCCESS_SAVE_ORDER_PROJECT,
        value: value
    };
};
/**
 * saveOrderProjectDataThunk. await request at save project
 *
 * @param {boolean} withSet The number to raise.
 * @param {boolean} memoryDT The power, must be a natural number.
 * @param {boolean} call The power, must be a natural number.
 * @return {promise} true - success update project, false - error.
 */
export const saveOrderProjectDataThunk = (withSet = true, memoryDT = false, call = false) => async (dispatch, getState) => {
    const version = getState().commons.data.version;
    const order = getState().order.order;
    let LS = localStorage.getItem(order.id) ?? null,
        order_save = {
            ...order
        };
    if (LS && memoryDT) {
        let parseLs = JSON.parse(LS).order
        order_save = {
            ...parseLs,
            cutting_card: [],

        };

    }
    try {
        dispatch(changeLoaderStatusAC(true));
        dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Сохраняем данные проекта на сервере...")));

        const response = await api.saveProject(version, order_save);

        if (api._errors) {
            let error_msg = JSON.stringify(api._getErrors())
            throw new Error(error_msg)
        }

        if (!response.is_save) {
            throw new Error("Project not save")
        }

        withSet && await dispatch(setOrderProjectDataThunk(memoryDT, call));

        dispatch(successSaveOrderProject(true));
        dispatch(changeMemoryDTOrderProjectModalValue("isOpen", false));
        dispatch(inconformityMemoryChangeValue(false));

        localStorage.removeItem(order.id);
        dispatch(changeLoaderStatusAC(false));
        if (!withSet) await dispatch(getAllAdvancedGoodsServiceByOrderIdThunk(order.id))
        return Promise.resolve(true)


    } catch (e) {
        dispatch(checkErrorsAPI(typeErrorApi.save, "api:/projects/${version}/, M:PUT", `Сохранение заказа. Message = ${e?.message}.`));
        dispatch(changeLoaderStatusAC(false));
        return Promise.resolve(false)
    }
};

//@param cbShopping - Произвольное  значение указывает на то, что при переходе на новою версии запустить калькуляцию
export const saveOrderProjectVersionDataThunk = (cbShopping = null) => (dispatch, getState) => {
    const version = getState().commons.data.version;
    const order = getState().order.order;
    const code = getState().commons.data.code;
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Сохраняем данные версии проекта на сервере...")));
    api.saveProjectVersion(version, order)
        .then(res => {
            if (res) {
                if (cbShopping) {
                    localStorage.setItem('dispatch_call_shopping', res.version);
                }
                window.location.href = `/project/${code}/${res.version}`;
                dispatch(changeLoaderStatusAC(false));

            } else {
                if (api._errors) {
                    dispatch(changeAlertStatusAC(true, api._getErrors()));
                }
                dispatch(changeLoaderStatusAC(false));
            }
        })
        .catch(e => {
            checkErrorsAPI(typeErrorApi.save, "api:/projects/${version}/, M:P");

            dispatch(changeLoaderStatusAC(false));
            dispatch(changeLoaderStatusAC(false));
        });

};
export const saveOrderProjectFileDataThunk = (order) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Формируем файл проекта...")));
    api.saveProjectFile(order)
        .then(res => {
            if (res) {
                api._downloadFile(res);
                dispatch(changeLoaderStatusAC(false));
            } else {
                if (api._errors) {
                    dispatch(changeAlertStatusAC(true, api._getErrors()));
                }
            }
        })
        .catch(e => {
            checkErrorsAPI(typeErrorApi.save, "api:/import, M:P,  action: save_project");
            dispatch(changeLoaderStatusAC(false));
            dispatch(changeLoaderStatusAC(false));
            dispatch(changeLoaderStatusAC(false));
        });

};
export const createOrderProjectDataThunk = (client, firm, title_project) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Идет создание нового заказа...")));
    api.createOrder(client, firm, title_project)
        .then(res => {
            if (res) {
                window.location.pathname = `/project/${res}/1`;

            } else {
                if (api._errors) {
                    dispatch(changeAlertStatusAC(true, api._getErrors()));
                } else {
                    dispatch(changeAlertStatusAC(true, getTranslateMessageAPI('Не удалось создать проект')));
                }
            }
            dispatch(changeLoaderStatusAC(false));
        })
        .catch(e => {
            checkErrorsAPI("", "api:/orders/, M:P");
            dispatch(changeAlertStatusAC(true, e?.message));
            dispatch(changeLoaderStatusAC(false));
            dispatch(changeLoaderStatusAC(false));
            dispatch(changeLoaderStatusAC(false));
        });

};
export const sendProjectFileDataThunk = (order, files, client_id) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Отправляем файл проекта на обработку...")));
    try {
        api.sendProjectFiles(order, files)
            .then(res => {

                if (res?.hasOwnProperty('error')) {
                    dispatch(dispatcherErrorThunk({error: res.error}));
                    return
                }
                if (api._errors) {
                    dispatch(dispatcherErrorThunk({error: api._getErrors()}));
                    return
                }

                if (res) {
                    res.code = order.code;
                    res.title = "new_project_" + order.code;
                    res.client = client_id;
                    res.firm = localStorage.getItem("ifurn_service_main_user_firm");
                    res.id = order.id;
                    res.calculate = [];
                    res.calculate_types = [];
                    res.calculate_discounts = {
                        "material": [],
                        "service": [],
                        "goods": []
                    };

                    const productions = Array.isArray(res.production_constants) ? res.production_constants : []
                    const band = Array.isArray(res.band) ? res.band : []
                    const mappingParts = [...res.part].map(partItem => {
                        return mappingPartialPart(partItem, band, productions)
                    })

                    res.part = mappingParts;

                    dispatch(setOrderProjectDataAC(res));
                    dispatch(changeUploadProjectModalValue("isOpen", false));
                    dispatch(changeLoaderStatusAC(false));
                }
                dispatch(changeLoaderStatusAC(false));

            })
            .catch(e => {
                console.log("e", e?.message);
                checkErrorsAPI("", "api:/import, M:P", `import project ${e?.message}`);
                dispatch(dispatcherErrorThunk(e));
            });
    } catch (e) {
        console.log(e?.message);
        dispatch(dispatcherErrorThunk(e));
    }

};
/* подтверждение заказа
*  amount -
* confirm_data
* delivery - метод доставки заказа
* **/
export const confirmOrderDataThunk = (amount, confirm_data, delivery) => (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Отправляем заказ на подтверждение...")));
    const order = getState().order.order;
    const {saveAddress = 0, ...deliveryForm} = delivery;
    const isNewDeliveryAddressSave = !!Number(saveAddress);
    api.confirmOrder(confirm_data, order, amount, deliveryForm)
        .then(async res => {
            if (res && res.send.mes.status === 2) {
                if (isNewDeliveryAddressSave) {
                    UserAddressesApi.postUserAddress(deliveryForm, order.client).catch(res => {
                        const message = res?.message ?? res?.error;
                        dispatch(dispatcherErrorThunk({error: message}));
                    })

                }
                dispatch(changeOrderConfirmFormDataAC("step", "success"));
                gaEvents.purchase({order, total: amount});

            } else {
                if (api._errors) {
                    dispatch(changeAlertStatusAC(true, api._getErrors()));
                }
                if (res && res.notify) {
                    dispatch(changeAlertStatusAC(true, res.notify));
                }
            }
            dispatch(changeLoaderStatusAC(false));
        })
        .catch(e => {
            console.log(e.message);
            dispatch(dispatcherErrorThunk({error: e.message}));
            checkErrorsAPI("", "api:/confirm, M:P");
        });

};

export const addNewPartThunk = (generateName = false, partId = false, isNext = false, defForm = null) => (dispatch, getState) => {
    const form = defForm ? defForm : getState().order.addPartForm;
    const CONST = getState().order.order.production_constants;

    if (CONST.hasOwnProperty("production.paz_side_torec_off") && Number(CONST["production.paz_side_torec_off"]) === 1) {
        form.name = "";
    } else if (Number(CONST["production.new_detail_name_no_create"]) === 1) {
        form.name = "";
    } else {
        if (generateName) form.name = "#" + random(1, 1, 1);
    }
    const partSizesType = getState().order.order.partSizesType;
    const parts = getState().order.order.part;
    const band_type = getState().order.order.bands_type.find(e => Number(e.band) === Number(form.material));
    const edge_type_part = band_type?.type ?? null;
    const pre_joint = band_type?.pre_joint;
    const material = getState().order.order.material.find(e => Number(e.goods_id) === Number(form.material));
    const materials = getState().order.order.material;
    const id = [...parts].length === 0 ? 1 : Math.max.apply(null, [...parts].map((e) => e.id)) + 1;
    if (material && (Number(material.type_material) === GLASS_ID_TYPE_MATERIAL)) {
        form.texture = 0;

    }
    let part = _constructorPart(id, partSizesType, material, pre_joint, form);
    if (edge_type_part) {
        part = {...part, edge_type_part: edge_type_part}
    }
    dispatch(addNewPartAC(part, partId, isNext));
    dispatch(changeAddPartModalValue("isOpen", false));
};


let isFetchingPartPreview = false;
export const getCurrentPartPreviewThunk = (part) => async (dispatch, getState) => {
    let order = getState().order.order;
    dispatch(changeLoaderStatusAC(true));
    let images_internal = !!Number(localStorage.getItem(LS_IMAGES_INTERNAL));
    if (images_internal) {
        const bands_type = order.bands_type;
        const materials = order.material;
        const bands = order.band;
        const client = order?.client;
        const part_size = await api.getPartSizes(part, bands_type, materials, bands, client);
        if (api._errors) {
            const result = {error: api._getErrors()};
            dispatch(dispatcherErrorThunk(result));
            return;
        }
        let order_data = {...order, part: [part_size], screen_size: {x: 123, y: 123}, type_svg: 'small'};
        const images_res = blueprints.wholeBlueprint(part.id, JSON.parse(JSON.stringify(order_data)));
        dispatch(setPartPreviewItemAC({
            id: part.id, svg: btoa(unescape(encodeURIComponent(images_res)))
        }));
        dispatch(changeLoaderStatusAC(false));
        return
    }
    if (!isFetchingPartPreview) {
        const partSizesType = order.partSizesType;
        const partSizes = _getPartSizes(partSizesType, part);
        let update = Object.assign(part, _constructorPartSizes(partSizesType, partSizes.x, partSizes.y));
        order = {
            ...order,
            part: [update]
        };
        isFetchingPartPreview = true;
        apiImages.getPartsSmallPreviews(order)
            .then(res => {
                dispatch(setPartPreviewItemAC(res?.length ? {
                    id: part.id, svg: res[0]?.svg
                } : []));
                dispatch(changeLoaderStatusAC(false));
            })
            .catch(e => {
                dispatch(dispatcherErrorThunk(e));
                // checkErrorsAPI("", "api:/images/, M:P");
                // dispatch(changeLoaderStatusAC(false));
            });
        isFetchingPartPreview = false;
    }
};


export const getPartSizesThunk = (part, timesPartSize = null) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Просчитываем размеры детали...")));
    const bands_type = getState().order.order.bands_type;
    const materials = getState().order.order.material;
    const bands = getState().order.order.band;
    const client = getState().order.order?.client;
    const product_of_part_count = getState().order.order.product?.find(prod => Number(prod?.id) === Number(part?.product))?.count || 1
    await api.getPartSizes(part, bands_type, materials, bands, client)
        .then(res => {
            if (res) {
                if (timesPartSize) {
                    let goods_service_added_of_part = getCalculateAdditionalGoodsServiceOfPart(res, timesPartSize, product_of_part_count);
                    res = {...res, goods_service_added_firm: goods_service_added_of_part}
                }
                dispatch(setPartWithSizesAC(res));

            } else {
                if (api._errors) {
                    dispatch(changeAlertStatusAC(true, api._getErrors()));
                }
            }
            dispatch(changeLoaderStatusAC(false));
        })
        .catch(e => {
            checkErrorsAPI("", "api:/sizes, M:P");
            dispatch(changeLoaderStatusAC(false));
        });


};
export const getAllPartSizesThunk = (size_type = null, prev_size_type = null) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Просчитываем размеры детали...")));
    let prevPartSizeType = prev_size_type ?? getState().order.order.partSizesType;
    utilLogger('prevPartSizeType', prevPartSizeType)
    if (!size_type) {
        size_type = getState().order.order.partSizesType;
    }

    const bands_type = getState().order.order.bands_type;
    const materials = getState().order.order.material;
    const bands = getState().order.order.band;
    const parts = getState().order.order.part;
    const client = getState().order.order?.client;

    // dispatch(changeOrderSizeTypeAC(size_type));

    api.getAllPartSizes(parts, bands_type, materials, bands, client, null, null, localStorage.getItem("ifurn_service_main_user_firm"), prevPartSizeType, size_type)
        .then(res => {
            if (res) {
                const partsWithSizes = res.map(e => {

                    return {
                        ...e,
                        ..._constructorPartSizes(size_type, _getPartSizes(size_type, e).x, _getPartSizes(size_type, e).y)
                    };
                });
                dispatch(setPartsWithSizesAC(partsWithSizes, size_type));
                dispatch(changeLoaderStatusAC(false));
            } else {
                if (api._errors) {
                    dispatch(changeAlertStatusAC(true, api._getErrors()));
                }
            }
        })
        .catch(e => {
            checkErrorsAPI("", "api:/sizes, M:P,  type:all");

            dispatch(changeLoaderStatusAC(false));
            dispatch(changeLoaderStatusAC(false));
            dispatch(changeLoaderStatusAC(false));
        });


};

export const getProductionConstantsThunk = () => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Получаем настройки компании и произвдственные" +
        " ограничения...")));
    api.getProductionConstants()
        .then(res => {
            if (res) {
                dispatch(setProductionConstantsDataAC(res));
            } else {
                if (api._errors) {
                    dispatch(changeAlertStatusAC(true, api._getErrors()));
                }
            }
            dispatch(changeLoaderStatusAC(false));
        })
        .catch(e => {
            checkErrorsAPI("", "api:/settings/firm, M:G");
        });

};


export const setCalculateOrderThunk = (order, clear = null) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Происходит калькуляция проекта...")));
    if (clear) {
        dispatch(clearCalculateValsDataAC());
    }


    if (getState().commons.errors) {
        dispatch(changeCheckProjectModalValue("isOpen", true));
        dispatch(changeLoaderStatusAC(false));
        dispatch(changeLoaderMessageAC(false));
        return false;
    }
    let update_calculate_goods_order = await calculateNumberOfProfilesHandlerAndEdge(order, dispatch);
    api.calculateOrder(update_calculate_goods_order)
        .then(res => {
            if (api?._errors) {
                dispatch(changeAlertStatusAC(true, api._getErrors()));
                dispatch(changeLoaderStatusAC(false));
                dispatch(changeLoaderMessageAC(false));

                return false;
            }
            if (res.error) {
                dispatch(getCheckProjectErrorsThunk(true, true));
                dispatch(changeLoaderStatusAC(false));
                dispatch(changeLoaderMessageAC(false));

                return false;
            }
            if (res.api_error_calc) {
                dispatch(setProjectCalcErrorsAC(res.api_error_calc));
                dispatch(changeCheckProjectModalValue("isOpen", true));
                dispatch(changeLoaderStatusAC(false));
                dispatch(changeLoaderMessageAC(false));

                return false;
            }
            if (res.service) {
                res.service = res.service.filter((e) => e.service_id && e.count > 0);
                res.service = res.service.map((e) => {
                    if (!e.hasOwnProperty("id")) {
                        e.id = e.service_id;
                    }
                    if (!e.price) {
                        e.price = 0;
                    }
                    return e;
                });
                let processing_edge_calculate = getCalculateShippingProcessingEdge({
                    ...order,
                    calculate: {material: res?.material}
                });

                dispatch(setProjectCalcErrorsAC(null));
                dispatch(setCalculateOrderDataAC({
                    ...res,
                    goods_update: update_calculate_goods_order?.goods, ...processing_edge_calculate
                }));
                dispatch(changeShopingCardModalValue("isOpen", true));
                dispatch(changeLoaderStatusAC(false));
                dispatch(changeLoaderMessageAC(false));


            }
        })
        .catch(e => {
            checkErrorsAPI(typeErrorApi.save, "api:/calculate/, M:P");
            dispatch(changeLoaderStatusAC(false));
            dispatch(changeLoaderStatusAC(false));
            dispatch(changeLoaderMessageAC(false));

        });

};


//  ------------------------------  EVENTS HISTORY OPERATION    --------------------------------
export const _setMirroringOperations = (operation, type, value) => {
    switch (type) {
        case "hor" : {
            return {
                ...operation,
                mir_hor: Number(operation?.mir_hor) === 1 ? 0 : 1
                // side_from: operation?.side_from === 't' ? 'b' : 't'
            };
            break;
        }
        case "ver": {
            return {
                ...operation,
                mir_ver: Number(operation?.mir_ver) === 1 ? 0 : 1
                // side_from: operation?.side_from === 'r' ? 'l' : 'r'
            };
            break;
        }
    }
    return {...operation};
};

const _createNewOperationItems = (operations, operation, type) => {
    let id = _getItemID(operations);

    return {
        ...operation, id: `${id}`
    };
};
export const copyOperationItemAC = (payload) => {
    return {
        type: COPY_OPERATION_ITEM,
        payload
    };
};
export const globalChangeMirroringOperation = (part, mirroringType) => {
    return {
        type: GLOBAL_CHANGE_MIRRORING,
        payload: {
            partId: part.id,
            mirroringType
        }
    };
};
export const mirroringOperationItemAC = (payload) => {
    return {
        type: MIRRORING_OPERATION_ITEM,
        payload
    };
};
export const copyOperationItemThunk = (part, operation, type, mirroring = false) => (dispatch, getState) => {
    const isFrezNewgo = isNewgoFrezOperation(operation);


    if (isFrezNewgo) {
        operation["code"] = getUiIdForPart().uuid
    }

    let payload = {
        partId: part.id,
        operation: {
            operations: null,
            operations_inputs: null
        },
        type
    };

    switch (type) {
        case "bore":
        case "paz":
        case "hem":
        case "trough":
        case "frez": {
            if (mirroring && mirroring.mir_hor != undefined) {
                operation = {
                    ...operation,
                    mir_hor: mirroring.mir_hor
                };
            } else if (mirroring && mirroring.mir_ver != undefined) {
                operation = {
                    ...operation,
                    mir_ver: mirroring.mir_ver
                };
            }
            payload.operation.operations = _createNewOperationItems(part.operations[type], operation);
            dispatch(onOperationToggleFilter({type, action: "add", values: [payload.operation.operations.id]}));

            if (type === "frez") {
                let operations_inputs = part.operations_inputs[type].find(o => Number(o.id) === Number(operation.id));
                if (operations_inputs) {
                    payload.operation.operations_inputs = {
                        ...operations_inputs,
                        id: payload.operation.operations.id
                    };
                }
            }
            dispatch(changeLoaderStatusAC(true));
            dispatch(copyOperationItemAC(payload));
            dispatch(changeLoaderStatusAC(false));

            break;
        }
        default:
    }

};

export const mirroringOperationItemThunk = (part, operation, type, mirroringType) => (dispatch) => {
    const isFrezNewgo = isNewgoFrezOperation(operation);


    if (isFrezNewgo) {
        operation["code"] = getUiIdForPart().uuid
    }
    let payload = {
        partId: part.id,
        mirroringType,
        operation,
        type
    };
    dispatch(changeLoaderStatusAC(true));
    dispatch(mirroringOperationItemAC(payload));
    dispatch(actionTemplatePaz?.mirroringOperation({partId: part?.id, operation, type, mirroringType}));
    dispatch(changeLoaderStatusAC(false));
};
export const mirroringActionOperationItemAC = (part, mirroringType, types) => {
    return {
        type: MIRRORING_ACTIONS_OPERATION_ITEM,
        payload: {
            partId: part.id,
            mirroringType,
            types
        }
    };
};


export const clearTransactionsHistoryAC = part_id => {
    return {
        type: CLEAR_TRANSACTIONS_HISTORY_PART,
        part_id

    };
};

export const mirroringActionOperationForPreview = (part, mirroringType, types) => async (dispatch, getState) => {
    dispatch(mirroringActionOperationItemAC(part, mirroringType, types));
    let partUpdate = getState().order.order.part.find(item => Number(item.id) === Number(part.id));
    dispatch(getCurrentPartPreviewThunk(partUpdate));

};
//  ------------------------------  EVENTS TURNS OPERATION    --------------------------------
export const onChangeTurnsPartAC = (payload) => {
    return {
        type: CHANGE_TURNS_PART,
        payload
    };
};


// ---------------------------  BATCH MACHINING HOLES    ----------

export const onChangeBatchMachiningHolesValues = (input, value) => {
    return {
        type: CHANGE_BATCH_MACHINING_HOLES_FORM_VALUES,
        input,
        value
    };
};

export const updateBatchMachiningPartAC = (payload) => {
    return {
        type: UPDATE_BATCH_MACHINING_HOLES_PART,
        payload
    };
};
export const updateBatchMachiningPartThunck = ({form, order}, call) => (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Отправляем данные...")));
    api._sendBatchMachiningPart({form, order})
        .then(res => {
            if (res) {
                if (res.error_api) {
                    dispatch(changeAlertStatusAC(true, res.error_api.api_error[0].error));
                } else {
                    dispatch(updateBatchMachiningPartAC(res));
                    // dispatch(resetBatchMachiningFormAC());
                    dispatch(changeBatchMachiningModalValue({isOpen: false, processing_current_material: null}));
                    dispatch(changeLoaderMessageAC(getTranslateMessageAPI("Групповая обработка успешно" +
                        " сохранилась...")));
                    if (call) {
                        call();
                    }
                }
            } else if (api._errors) {
                dispatch(changeAlertStatusAC(true, api._getErrors()));
            }
            dispatch(changeLoaderStatusAC(false));
        }).catch(e => {
        checkErrorsAPI("", "api:/templates/, M:PUT");
        dispatch(changeLoaderStatusAC(false));
    });
    dispatch(changeLoaderStatusAC(false));
};

export const resetBatchMachiningFormAC = () => {
    return {
        type: RESET_BATCH_MACHINING_FORM
    };
};


export const clientChangeMaterialSizes = (payload) => {
    return {
        type: CLIENT_ON_CHANGE_MATERIAL_SIZES,
        payload
    };
};
export const clientChangeBandlSizes = (payload) => {
    return {
        type: CLIENT_ON_CHANGE_BAND_SIZES,
        payload
    };
};
export const clearDetailNotes = () => {
    return {
        type: ON_CLEAR_DETAIL_NOTES
    };
};
export const clientMaterialChangeCount = (payload) => {
    return {
        type: CLIENT_MATERIAL_CHANGE_COUNT,
        payload
    };
};

export const clearDetailNotesTh = () => dispatch => {
    dispatch(changeLoaderStatusAC(true));
    dispatch(clearDetailNotes());
    dispatch(changeLoaderStatusAC(false));
};
export const inconformityMemoryChangeValue = (value) => {
    return {
        type: INCONFORMOTY_DATA_MEMORY_STORE,
        value: value
    };
};
export const setOrderCopyProject = (value) => {
    return {
        type: SET_ORDER_COPY,
        value: value
    };
};
export const setEdgeTypePart = (payload) => {
    return {
        type: SET_EDGE_TYPE_PART,
        payload
    };
};
export const setAllEdgeTypeParts = (payload) => {
    return {
        type: SET_ALL_EDGE_TYPE_PARTS,
        payload
    };
};

//
export const setInstructionBoxAC = (newBox, html) => {
    return {
        type: SET_INSTRUCTION_BOX,
        payload: newBox,
        html
    };
};

export const setInstructionBoxTh = (box, call) => (dispatch) => {
    try {
        dispatch(changeLoaderStatusAC(true));
        api.constructBox(box).then(res => {

            dispatch(setInstructionBoxAC(box, res.html));
            if (call) {
                call();
            }
            dispatch(changeLoaderStatusAC(false));
        });
    } catch (e) {
        checkErrorsAPI("", "api:/boxes, M:P, (setInstructionBoxTh)");
        dispatch(changeLoaderStatusAC(false));
        dispatch(changeLoaderStatusAC(false));
    }
};
export const setLangOrderState = (payload) => {
    return {
        type: ORDER_CHANGE_LANG,
        payload
    };
};

export const detailOrderUpdate = (part, partId) => {
    return {
        type: ORDER_DETAIL_UPDATE,
        payload: {
            part, partId
        }
    };
};
export const onChangeTurnDetail = (partId, isFetchingImage = true) => async (dispatch, getState) => {
    const order = getState().order.order;
    const updateOrder = {
        ...order,
        part: order.part.filter(p => Number(p.id) === Number(partId))
    };
    dispatch(changeLoaderStatusAC(true));
    try {
        const response = await api._changeTurnDetail(updateOrder);
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()));
        }

        if (response) {
            dispatch(detailOrderUpdate(response.order.part, partId));
        }
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        checkErrorsAPI("", "api:/turn, M:P, (onChangeTurnDetail)");
        dispatch(changeLoaderStatusAC(false));
    }
};


const deleteActionOrderVersion = ({id, version}) => {
    return {
        type: DELETE_PROJECT_ORDER_VERSION,
        payload: {id, version}
    };
};
export const deleteProjectOrderVersion = ({id, type, v}) => async (dispatch, getState) => {
    const codeProject = getState().order.order.code;
    dispatch(changeLoaderStatusAC(true));
    try {
        const response = await api._deleteOrderVersion({type: type, code: codeProject, v: v});
        const transformResponse = response?.data?.filter(item => {
            if (item.db && item.ok === 1) {
                return item;
            }
        }).map(item => item?.db_arr) ?? [response?.db_arr];
        if (Number(response?.ok) === 1) {
            dispatch(deleteActionOrderVersion({id, version: transformResponse}));
        }

    } catch (e) {
        console.log(e.message);
        dispatch(checkErrorsAPI("", "api: /deleteorders/${type}/${code}/${v}, method: get"));
    }
    dispatch(changeLoaderStatusAC(false));
};
export const onCreateMaterialCombineThunk = (material, cb = false) => (dispatch) => {

    try {
        dispatch(changeLoaderStatusAC(true));
        const {parts_by_step} = material;
        dispatch(onChanePartCombineMaterial(parts_by_step, material?.goods_id));
        dispatch(addOrderMaterialAC(material));
        dispatch(changeLoaderStatusAC(false));
        if (cb) cb();
    } catch (e) {
        dispatch(changeLoaderStatusAC(false));
        console.error(e.message);
    }

};
export const initialDetailTemplatePaz = () => async (dispatch, getState) => {
    const tools = getState().operations.tools?.filter?.((el) => Number(el.equipment.paz) === 1)
        .filter((el) => Number(el.w) !== 0)
        .sort((a, b) => a.w - b.w).map(e => Number(e.w));
    dispatch(actionTemplatePaz?.createPartPazGiblab(tools));

};


export const onBeforeOpenOperationChangePartSizeType = (isActivateChange = true,
                                                        beforePartSize = null,
                                                        part = null,
                                                        isOpen = false,
                                                        afterPartSize = CONSTANT_PART_SIZE_GABARIT, cb = false) =>
    async (dispatch, getState) => {
        if (isActivateChange) {
            if (isIncludeChangePartSizeBeforeOpenOperation.includes(beforePartSize)) {
                dispatch(changeOrderSizeTypeAC(afterPartSize));
                // await  dispatch(getPartSizesThunk(part));
            } else if (afterPartSize) {
                dispatch(changeOrderSizeTypeAC(afterPartSize));
                dispatch(getAllPartSizesThunk(afterPartSize));
            }
        }
        dispatch(updateBeforeChangePartSize(beforePartSize));
        dispatch(changeOperationsModalValue("part", part ? part.id : null));
        dispatch(changeOperationsModalValue("isOpen", isOpen));
        if (cb) cb();

    };


export function globalChangePositionGr({parts, goods_id, material, gr, partsPreview = []}) {
    const updateMaterial = material?.map(matItem => {
        let findCombine = parts?.filter(part => Number(part?.combined_material_goods_id) === Number(matItem?.goods_id));
        if (findCombine?.length) {
            let {parts_by_step} = matItem;
            let updatePartsStep = parts_by_step.map(step => {
                let newIndex = parts.map(part => Number(part.id)).indexOf(Number(step.id));
                return {
                    ...step,
                    id: Number(newIndex) + 1
                };
            });
            return {
                ...matItem,
                parts_by_step: updatePartsStep
            };
        }
        return matItem;
    });
    let updatePartsId = parts.map((items, index) => ({
        ...items,
        id: index + 1
    }));

    let updateIdsPartsPreview = partsPreview.map((item) => {
        let findIndex = parts.findIndex(part => Number(part.id) === Number(item.id));
        return {
            ...item, id: findIndex + 1,
        };

    });


    let grCreateParts = gr?.map(itemGr => ({...itemGr, part_giblab: []}))?.map(item => {
        let createParts = [];
        updatePartsId?.forEach((part) => {
            let findPaz = part?.operations?.paz?.filter(paz => Number(item?.id) === Number(paz?.id_template_paz));
            let sideFrom = (paz) => {
                let side = paz?.side_from;
                let hor = (paz?.mir_hor ?? 0);
                let ver = (paz?.mir_ver ?? 0);
                if (side === "t" || side === "b") {
                    return Number(ver) === 0 ? side : (side === "t") ? "b" : "t";
                }
                if (side === "r" || side === "l") {
                    return Number(hor) === 0 ? side : (side === "r") ? "l" : "r";
                }
                return side;
            };
            if (findPaz?.length) {
                findPaz?.forEach(paz => {
                    createParts.push({
                        id: part?.id,
                        side: paz?.side,
                        side_from: sideFrom(paz)

                    });
                });
            }

        });

        return {
            ...item,
            part_giblab: createParts
        };
    });

    return {
        part: updatePartsId,
        gr: grCreateParts,
        material: updateMaterial,
        partsPreview: updateIdsPartsPreview
    };
}


export const onToggleSelectedMaterialGoodsIdOfDetailing = (payload) => {
    return {
        type: SELECTED_MATERIAL_GOODS_ID_OF_DETAILING,
        payload: payload
    }
}
export const onFilterPartsOfMaterial = (payload) => {
    return {
        type: FILTER_PARTS_OF_MATERIAL,
        payload: payload
    }
}
export const onChangeOrderProduction = (payload) => {
    return {
        type: CHANGE_ORDER_PRODUCTION_CONST,
        payload: payload
    }
}

export const initialProjectDt = ({isManager = false, client, firm, manager}) => async (dispatch, getState) => {
    dispatch(changeLoaderStatusAC(true));
    try {
        if (isManager) {
            dispatch(setManagerAuthDataThunk(client, firm, manager));
            dispatch(getMachineToolPostProcessorFilesThunk());

        } else {
            await dispatch(setOrderProjectDataThunk(false, false, null, true));
        }
        await dispatch(getFirmToolsThunk());


    } catch (e) {
        dispatch(changeLoaderStatusAC(false));
    }
    dispatch(changeLoaderStatusAC(false));

}


export const togglePageOperationPartsError = () => async (dispatch, getState) => {
    const order = getState().order.order;
    try {
        const res = await api.checkProject(order);
        if (res) {
            const part_errors = Object.keys(res.parts).map(Number);
            dispatch(onSetOperationPartsError(part_errors))
            return
        }
        dispatch(onSetOperationPartsError(null))
    } catch (e) {

    }
}

export const createPostformingTemplate = ({firstPartId, materialId, data}) => async (dispatch) => {
    dispatch(changeLoaderStatusAC(true));
    try {
        let res = await api._createPostformingTempate({materialId, data});
        if (api._errors) {
            dispatch(changeAlertStatusAC(true, api._getErrors()));
        }
        if (res && Array.isArray(res)) {
            dispatch(addNewArrayPartsAC(res, firstPartId));
            dispatch(onTogglePostformingModal({isOpen: false, list: null, materialId: null}));
        }
        dispatch(changeLoaderStatusAC(false));
    } catch (e) {
        console.log(e.message)
        dispatch(changeAlertStatusAC(true, e.message));
        dispatch(changeLoaderStatusAC(false));
    }
}
export default OrderReducer;

