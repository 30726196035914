import axios from "axios";
import {LS_LANG} from "../constants";

export let instances_help_article = axios.create({
    baseURL: `${process.env.REACT_APP_API_HELP_ARTICLE_URL}/api/v1`,
    // baseURL: 'https://test-help.ifurn.pro/api/v1',
    withCredentials: true,

});

export const HelpArticleAPI={
    getById: (id)=>{
        let lang_val =  localStorage.getItem(LS_LANG);
        let lang = lang_val === 'ua' ? 'uk' : lang_val;
        let url = `/prompt/${id}/${lang}`;
        return instances_help_article.get(url).then(res=> {
        return  id ?{
            id: id,
            title: `(№${id}) ${res.data.title}`,
            description: res.data.description,
            related_articles: res?.data?.["related articles"] ?? null,
            url: res.data?.url ?? null
        } : res.data;
        }).catch(e => Promise.reject(e))
    }
}