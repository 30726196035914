import MainApi from "./MainApi";
import {findErrorResponse, resolveTransform} from "./utils_api";
import {LS_CLIENT_TOKEN, LS_FIRM, LS_LANG, LS_LINK_T0_3D_CARD, LS_USER_TOKEN} from "../constants";
import {transformInitProject} from "./helpers/transformProject";

class ApiService extends MainApi {

    constructor() {
        super();
    }

    _withCredentials = "include";

    getProject = async (version, code, client, firm = localStorage.getItem("ifurn_service_main_user_firm"), clean = true, get_version = null, processing_edge_point) => {
        if (!code) return false;
        const need_clean = clean ? "&clean=1" : "";
        let url = `/projects/${version}/?client=${client}&order=${code}&firm=${firm}${need_clean}`;
        if (get_version) {
            url += `&get_version=${get_version}`
        }
        const res = await this._send(
            url,
            "GET",
            {},
            {},
            "json"
        );
        if (res && res?.link_to_cad) {
            localStorage.setItem(LS_LINK_T0_3D_CARD, res?.link_to_cad)
        }
        if (res && res.order && res.order.id) {
            return transformInitProject(res.order, processing_edge_point);
            // return this._transformProject(res.order, processing_edge_point);
        } else {
            return false;
        }
    };

    saveProject = async (version, order) => {
        if (!version || !order) return false;
        let time1 = performance.now();
        const res = await this._send(
            `/projects/${version}/`,
            "PUT",
            {
                order: order
            },
            {},
            "json"
        );
        let time2 = performance.now();
        let timeFetch = (time2 - time1).toFixed(4) * 0.001;
        if (timeFetch > 3 && res.status !== "Data updated.") {
            return {
                is_save: false,
                res: res
            };
        }
        if (res && res.status && res.status === "Data updated.") {
            return {
                is_save: true,
                res: res
            };
        } else {
            return {
                is_save: false,
                res: res
            };
        }
    };

    saveProjectVersion = async (version, order) => {
        if (!version || !order) return false;

        const res = await this._send(
            `/projects/${version}/`,
            "POST",
            {
                order: order
            },
            {},
            "json"
        );
        if (res && res.status && res.status === "complete" && res.version) {
            return res;
        } else {
            return false;
        }
    };
    saveProjectFile = async (order) => {
        if (!order) return false;

        const res = await this._send(
            `/import/`,
            "POST",
            {
                action: "save_project",
                order: order
            },
            {},
            "json"
        );
        if (res && res.link) {
            return res.link;
        } else {
            return false;
        }
    };

    createOrder = async (client, firm, title_project) => {

        if (!firm) firm = localStorage.getItem("ifurn_service_main_user_firm");

        if (!client || !firm) return false;

        const res = await this._send(
            `/orders/`,
            "POST",
            {
                client: client,
                firm: firm, title: title_project
            },
            {},
            "json"
        );
        if (res && res.data && res.status === "complete" && res.data.code) {
            return res.data.code;
        } else {
            return false;
        }
    };

    async sendProjectFiles(order, files) {

        const formData = new FormData();

        formData.append("action", "import_order");
        formData.append("order", order);
        formData.append("firm", localStorage.getItem("ifurn_service_main_user_firm"));

        for (let i = 0; i < files.length; i++) {
            formData.append("import_porject_" + i, files[i]);
        }

        const res = await fetch(`${this._baseUrl}/import`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            redirect: "follow",
            referrerPolicy: "no-referrer",
            headers: {
                'User-Token': localStorage.getItem('ifurn_service_main_user_token'),
                'Client-Token': localStorage.getItem('ifurn_service_main_client_token'),
                'X-Api-Key': this._apiKey,
                'Language': localStorage.getItem('lang') || 'ua',

            },
            body: formData
        });

        let response = await res.json();

        if (response && response.hasOwnProperty('error_api')) {

            let api_error = response.error_api?.api_error?.reduce((acc, item) => {
                return `${acc} ${item?.error}`
            }, '')
            return {error: api_error}
        }
        if (response && response.order) {
            return response.order;
        } else {
            return false;
        }

    }

    getTools = async (firm = localStorage.getItem("ifurn_service_main_user_firm")) => {

        if (!firm) return false;

        const res = await this._send(
            `/tools/equipment/?firm=${firm}`,
            "GET",
            {},
            {},
            "json"
        );
        if (res && res.data) {
            return this._transformTools(res.data);
        } else {
            return false;
        }
    };

    _transformTools = (data) => {
        return data.map(e => {
            return e;
        });
    };

    getBoxes = async () => {

        const res = await this._send(
            `/boxes`,
            "GET",
            {},
            {},
            "json"
        );
        if (res && res.data) {
            return res.data;
        } else {
            return false;
        }
    };

    constructBox = async (box, deletePartsDetail = [], updateGeneral = []) => {

        if (!box) return false;

        const res = await this._send(
            `/boxes`,
            "POST",
            {
                ...box,
                deleteDetail: deletePartsDetail,
                general: updateGeneral

            },
            {},
            "json"
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    };

    getBoxParams = async (id) => {
        if (!id) return false;
        const res = await this._send(
            `/boxes/` + id,
            "GET",
            {},
            {},
            "json"
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    };

    getHemTypes = async (firm = localStorage.getItem("ifurn_service_main_user_firm")) => {

        if (!firm) return false;

        const res = await this._send(
            `/doubles`,
            "GET",
            {},
            {},
            "json"
        );
        if (res && res.data) {
            return this._transformHemTypes(res.data);
        } else {
            return false;
        }
    };

    _transformHemTypes = (data) => {
        return data.map(e => {
            return e;
        });
    };

    getPartSizes = async (part, bands_type, materials, bands, client, firm = localStorage.getItem("ifurn_service_main_user_firm")) => {

        if (!part) return false;

        const res = await this._send(
            `/sizes`,
            "POST",
            {
                part: part,
                materials: materials,
                bands: bands,
                bands_type: bands_type,
                firm: firm,
                client: client
            },
            {},
            "json"
        );
        if (res && res.part) {
            return res.part;
        } else {
            return false;
        }
    };

    getAllPartSizes = async (parts, bands_type, materials, bands, client, allMaterials = null, allBands = null, firm = localStorage.getItem("ifurn_service_main_user_firm"),
                             prevPartSizeType, currentPartSizeType
    ) => {

        if (!parts) return false;

        const res = await this._send(
            `/sizes`,
            "POST",
            {
                type: "all",
                parts: parts,
                materials: materials,
                bands: bands,
                bands_type: bands_type,
                firm: firm,
                allMaterials: allMaterials,
                allBands: allBands,
                client: client,
                prevPartSizeType: prevPartSizeType,
                currentPartSizeType: currentPartSizeType
            },
            {},
            "json"
        );
        if (res && res.parts) {
            return res.parts;
        } else {
            return false;
        }
    };


    getTemplatesTree = async (glassTree = false) => {
        let url = glassTree ? "glass_tree/" : "tree/";
        const res = await this._send(
            `/templates/${url}`,
            "GET",
            {},
            {credentials: "include"},
            "json"
        );
        if (res && res.data) {
            return this._transformTemplatesTree(res.data);
        } else {
            return false;
        }
    };

    _transformTemplatesTree = (data) => {
        return data.map(e => {
            return {
                id: e.id,
                name: e.name,
                childs: e.parents,
                templates: e.templates
            };
        });
    };

    getProducers = async () => {

        const res = await this._send(
            `/materials/producers/`,
            "GET",
            {},
            {},
            "json"
        );
        if (res && res.data) {
            return this._transformProducers(res.data);
        } else {
            return false;
        }
    };

    _transformProducers = (data) => {
        return data.map(e => {
            return {
                id: e.producers_id,
                name: e.name
            };
        });
    };


    getTemplateForm = async (id, material = null, firm = localStorage.getItem(LS_FIRM)) => {

        if (!id) return false;

        let url = `/templates/${id}/?firm=${firm}`
        if (material) {
            url += `&id=${material.id}&y=${material.y}&x=${material.x}&z=${material.z}`
        }
        const res = await this._send(
            url,
            "GET",
            {},
            {},
            "json"
        );
        if (res && res.data) {
            return res;
        } else {
            return false;
        }
    };

    getTemplateOperations = async (id, band, part, form, material = null, firm = localStorage.getItem("ifurn_service_main_user_firm")) => {

        if (!id || !band || !part || !form) return false;

        const res = await this._send(
            `/templates/`,
            "POST",
            {
                formData: form,
                id: id,
                band: band,
                partData: part,
                firm: firm,
                material: {
                    id: material?.id ?? null,
                    x: material?.x ?? null,
                    z: material?.z ?? null,
                    y: material?.y ?? null
                }
            },
            {},
            "json"
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    };

    checkProject = async (order) => {

        if (!order) return false;

        const res = await this._send(
            `/check-project/`,
            "POST",
            {
                order: order
            },
            {},
            "json"
        );
        if (res && res.project) {
            return {
                parts: res.project?.error ?? null,
                xnc: res.xnc_error,
                alert: res.project?.alert ?? null,
            };
        } else {
            return false;
        }
    };

    getGiblabData = async (order, action, part_id = null) => {

        if (!order || !action) return false;

        const res = await this._send(
            `/giblab/`,
            "POST",
            {
                action: action,
                part_id: part_id,
                order: order
            },
            {},
            "json"
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    };

    calculateOrder = async (order) => {

        if (!order) return false;

        const res = await this._send(
            `/calculate/`,
            "POST",
            {
                order: order,
                materials: order.calculate_types,
                discounts: order.calculate_discounts,
                calc: order.calc
            },
            {},
            "json"
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    };

    getAuth = async (email, password, from = '') => {
        if (!email || !password) return false;
        let data = await fetch(`${this._baseUrl}/users/auth/?email=${email}&pass=${password}&from=${from}`, {
            method: "GET",
            mode: 'cors',
            cache: 'no-cache',
            credentials: this._withCredentials,
            headers: {
                'Content-Type': 'application/json',
                'User-Token': localStorage.getItem(LS_USER_TOKEN),
                'Client-Token': localStorage.getItem(LS_CLIENT_TOKEN),
                'X-Api-Key': this._apiKey,
                'Language': localStorage.getItem(LS_LANG) || 'ua',

            },
            redirect: 'follow',
            referrerPolicy: 'unsafe-url',
        })

        if (data.status === 404) {
            return {
                status: 404
            };
        }
        let res = await data.json();
        if (res && res.data && res.data.auth_data && res.data.firms) {
            return this._transformAuth(res.data);
        } else {
            return {
                status: 403
            };
        }
    };

    _transformAuth = (data) => {
        return {
            status: "success",
            client: data?.client ?? null,
            id: data.auth_data.client_id,
            token: data.auth_data.user_data.token,
            firm: data.auth_data?.firm ?? null,
            firms: data.firms.map(e => {
                return {
                    id: e.firm,
                    name: e.firm_name
                };
            })
        };
    };

    importProject = async (files, url = "import", data = null) => {

        if (!files) return false;

        const res = await this._sendFileData(
            `/${url}`,
            files,
            data
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    };

    importProjectChanges = async (projects, new_goods, order, array_name, projects_name, arr) => {

        if (!order || !projects || !new_goods) return false;

        const res = await this._send(
            `/import/`,
            "POST",
            {
                projects: projects,
                projects_name: projects_name,
                new_goods: new_goods,
                current_project: order,
                name_part_put: {
                    main: array_name,
                    arr: arr
                }
            },
            {},
            "json"
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    };

    getRegions = async () => {
        return {
            ua: {id: "ua", label: "UA +38", code: "+38", mask: "(111) 111-11-11"},
            he: {id: "he", label: "HE +972", code: "+972", mask: "111-111-1111"},
            // ru: {id: 'ua', label: 'RU +7', code: '+7', mask: '(111) 111-11-11'},
            by: {id: "by", label: "BY +375", code: "+375", mask: "(11) 111-11-11"},
            bg: {id: "bg", label: "BG +359", code: "+359", mask: "(11) 111-11-11"},
            pl: {id: "pl", label: "PL +48", code: "+48", mask: "(11) 111-11-11"}
        };
    };

    getStores = async (firm = localStorage.getItem("ifurn_service_main_user_firm")) => {

        if (!firm) return false;

        const res = await this._send(
            `/confirm/store/` + firm,
            "GET",
            {},
            {},
            "json"
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    };

    confirmOrder = async (confirm_data, order, amount, deliveryForm) => {

        if (!confirm_data || !order) return false;

        const res = await this._send(
            `/confirm/`,
            "POST",
            {
                data: {
                    ...confirm_data,
                    check_contact: "ok"
                },
                order_code: order.code,
                amount: amount,
                calculate: order.calculate,
                materials: order.material,
                extra: order.extra,
                currency: order.currency,
                goods_service_added_part_firm: order?.goods_service_added_part_firm || null,
                goods_service_added_order_firm: order?.goods_service_added_order_firm || null,
                form: deliveryForm,
                plugin: deliveryForm.plugin,
            },
            {},
            "json"
        );
        if (res) {
            return res;
        } else {
            return false;
        }
    };

    xmlTransform = async (order) => {

        if (!order) return false;

        const res = await this._send(
            `/project-transform/`,
            "POST",
            {
                order: order
            },
            {},
        );
        if (res && res?.xml && res?.session_id) {
            return res;
        } else {
            return false;
        }
    };

    getProductionConstants = async (firm = localStorage.getItem("ifurn_service_main_user_firm")) => {

        if (!firm) return false;

        const res = await this._send(
            `/settings/` + firm,
            "GET",
            {},
            {},
            "json"
        );
        if (res && res[0] && res[0].production) {
            return res[0].production;
        } else {
            return false;
        }
    };
    _getSettingsFirmProduction = async (firm = localStorage.getItem("ifurn_service_main_user_firm")) => {

        if (!firm) return false;

        const res = await this._send(
            `/settings/` + firm,
            "GET",
            {},
            {},
            "json"
        );
        if (res && res[0] && res[0]) {
            return res[0];
        } else {
            return false;
        }
    };

    getOrderDetail = async (id) => {

        if (!id) return false;

        const res = await this._send(
            `/orders/` + id,
            "GET",
            {},
            {},
            "json"
        );
        if (res && res.id) {
            return res;
        } else {
            return false;
        }

    };


    _downloadFile = (href) => {
        let link = document.createElement("a");

        link.setAttribute("href", href);
        link.setAttribute("target", "_blank");
        // link.setAttribute('rel', 'noopener noreferrer');
        link.setAttribute("download", "project.ifp");
        window.onload = link.click();
    };

    _getVarificationUser = async ({firm_id = "", payload}) => {
        return await this._send(
            `/registration/` + firm_id,
            "POST",
            {
                action: "qreg",
                // action: "check",
                ...payload
            },
            {},
            "json"
        );
    };
    _sendRegisterUser = async ({firm_id = "", payload}) => {
        return await this._send(
            `/registration/` + firm_id,
            "POST",
            {
                // action: "password_check",
                action: "qreg",
                ...payload
            },
            {},
            "json"
        );
    };
    _resetRegisterPassword = async (client_id, data) => {
        return await this._send(
            `/registration/` + client_id,
            "POST",
            {
                action: "forgot_password",
                login: data.login
            }
        );
    };
    _autorizedFirmRegister = async ({firm_id, payload}) => {
        return await this._send(
            `/registration/${firm_id}`,
            "POST",
            {
                action: "put_client_to_firm",
                ...payload
            },
            {},
            "json"
        );
    };


    _sendBatchMachiningPart = async ({order, form}) => {
        const res = await this._send(
            `/templates/`,
            "PUT",
            {
                order: order,
                request: form

            },
            {},
            "json"
        );

        if (res) {
            return res;
        } else {
            return false;
        }
        // return res;
    };

    getStockMaterialClientData = async (payload) => {
        return await this._send("/clients", "POST", payload);
    };

    sendWordTranslation = async (words) => {
        return await this._send("/translate/", "POST", {need_translate: words});
    };
    changeLanguage = async (lang) => {
        return await this._send("/translate/", "PUT", {lang: lang});
    };
    sendPullAllDT = async (words) => {
        return await this._send("/translate/", "PUT", {put_all: 1, put_all_data: words});
    };

    getRegisterFirmName = async (key = "") => {
        return await this._send(`/ping/Heccrbqrjhf,kmblbyf[eq/${key}`, "GET");
    };
    _getFilesListManager = async (idProject) => {
        return await this._send(`/files/${idProject}/`, "GET");
    };

    _deleteFilesListManager = async (url) => {
        return await fetch(url, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: this._withCredentials,
            headers: {
                "Content-Type": "application/json",
                // 'Language': localStorage.getItem('ifurn_store_main_language') ? localStorage.getItem('ifurn_store_main_language') : 'ru',
                "User-Token": localStorage.getItem("ifurn_service_main_user_token"),
                "Client-Token": localStorage.getItem("ifurn_service_main_client_token"),
                "X-Api-Key": this._apiKey,
                "Language": localStorage.getItem("lang") || "ua"

            },
            redirect: "follow",
            referrerPolicy: "unsafe-url"
        })
            .then(data => {
                return data.json();
            })
            .catch(data => {
                return data.json();
            });
    };

    _onSetParamsSettings = async (payload) => {
        return await this._send(
            "/settings/",
            "POST",
            {...payload}
        );
    };
    _onSendInstructionTools = async (payload) => {
        return await this._send(
            "/tools/",
            "POST",
            payload
        );
    };
    _getGodeUser = async (id) => {
        return await this._send(`/clients/get_user/${id}`);
    };
    _removeToolsSettingFirm = async (id) => {
        return await this._send(
            "/tools/" + id,
            "DELETE"
        );
    };
    _updateCabinetStatusOrder = async ({orderId, statusId, userId}) => {
        return await this._send(`/orders/${orderId}`, "PUT", {status: statusId, user: userId});
    };
    _getCellStorageFullInfo = async (id) => {
        return await this._send("/goods_stock_cells/" + id, "GET");
    };
    _getCellsStorageCU = async () => {
        return await this._send("/goods_stock_cells/", "GET");
    };
    _setCellsStorageCU = async (payload) => {
        return await this._send("/goods_stock_cells/", "POST", payload);
    };
    _removeCellsStorage = async (id) => {
        return await this._send("/goods_stock_cells/" + id, "DELETE");
    };

    _getRemainsStorageFullInfo = async (id) => {
        return await this._send("/goods_stock/" + id, "GET");
    };
    _getRemains = async () => {
        return await this._send("/goods_stock/", "GET");
    };
    _setRemainsCU = async (payload) => {
        return await this._send("/goods_stock/", "POST", payload);
    };
    _removeRemains = async (id) => {
        return await this._send("/goods_stock/" + id, "DELETE");
    };

    _getPlans = async () => {
        return await this._send("/goods_stock_rest_plan/", "GET");
    };
    _getCuurentPlan = async (id) => {
        return await this._send("/goods_stock_rest_plan/" + id, "GET");
    };
    _updatePlan = async (payload) => {
        return await this._send("/goods_stock_rest_plan/", "POST", payload);
    };
    _removePlan = async (id) => {
        return await this._send("/goods_stock_rest_plan/" + id, "DELETE");
    };

    _getStockOrders = async () => {
        return await this._send("/goods_stock/orders/", "GET");
    };

    _servicePriceCU = async (dt) => {
        return await this._send("/goods_firm_user/", "POST", dt);
    };
    _materialProductPriceDelete = async (id) => {
        return await this._send("/goods_firm_user/" + id, "DELETE");
    };
    _deleteFurniture = async (url) => {
        const res = await fetch(url + "&api=1", {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: this._withCredentials,
            headers: {
                "Content-Type": "application/json",
                "User-Token": localStorage.getItem("ifurn_service_main_user_token"),
                "Client-Token": localStorage.getItem("ifurn_service_main_client_token"),
                "X-Api-Key": this._apiKey,
                "Language": localStorage.getItem("lang") || "ua"

            },
            redirect: "follow",
            referrerPolicy: "unsafe-url"
        });
        const body = await res.json();

        if (body.error_api) {
            this._errors = body.error_api;
            return false;
        }

        return body;
    };

    _deletePhotoFurniture = async (pic_article_id) => {
        return this._send("/boxes_files/" + pic_article_id, "DELETE");
    };
    _onSendPayRequestInvoice = (text) => {
        return this._send("/mail/", "POST", {
            action: "send_form_for_payment",
            text
        });
    };
    _sendLetter = ({text}) => {
        return this._send("/mail", "POST", {action: "send_form", text});
    };


    _changeTurnDetail = (order) => {
        return this._send("/turn", "POST", {order});
    };
    _deleteOrderVersion = ({type = "v", code, v = ""}) => {
        return this._send(`/deleteorders/${type}/${code}/${v}`, "GET");
    };


    _fetchIfurnLables = async (orderId, part_id) => {
        const res = await fetch(`${this._baseUrl}/labels/${orderId}/${part_id}`, {
            method: "GET",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            redirect: "follow",
            referrerPolicy: "no-referrer"
        });
        if (res.ok) {
            return await res.text();
        } else {
            return false;
        }
    };

    _fetchGiblabLabels = async (order) => {
        return await this._send("/labels/", "POST", {order: order});

    };

    _sendDocInMail = async (data) => {
        return await this._send("/mail", "POST", data);
    };

    _getCalculateValues = async (md) => {
        return await this._send(`/front_storage/${md}`, "GET");
    };
    _setCalculateValues = async (calculate) => {
        return await this._send("/front_storage", "POST", {data: calculate});
    };
    _materialExportBasis = async () => {
        return await this._send("/materialexportbasis", "GET");

    };
    _getPostformingTemplate = async (materialId) => {
        return await this._send("/pftemplate/" + materialId, "GET");
    };
    _createPostformingTempate = async ({materialId, data}) => {
        return await this._send("/pftemplate/" + materialId, "POST", data);

    };
    _authGetForUriClientsFirms = async (from) => {
        const fromData = new FormData();
        fromData.append("from", from)

        const res = await fetch(`${this._baseUrl}/authi/get-firms`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            redirect: "follow",
            referrerPolicy: "no-referrer",
            headers: {
                'User-Token': localStorage.getItem('ifurn_service_main_user_token'),
                'Client-Token': localStorage.getItem('ifurn_service_main_client_token'),
                'X-Api-Key': this._apiKey,
                'Language': localStorage.getItem('lang') || 'ua',

            },
            body: fromData
        });
        if (res.ok) {
            let response = await res.json();
            let error = findErrorResponse(response);
            return !error ? response : {error: error}
        }
        return {error: `${res.statusText}`}

    };
    _authConnectedFirms = async (formData) => {
        const res = await fetch(`${this._baseUrl}/authi/append-firm`, {
            method: "POST",
            mode: "cors",
            cache: "no-cache",
            credentials: "include",
            redirect: "follow",
            referrerPolicy: "no-referrer",
            headers: {
                'User-Token': localStorage.getItem('ifurn_service_main_user_token'),
                'Client-Token': localStorage.getItem('ifurn_service_main_client_token'),
                'X-Api-Key': this._apiKey,
                'Language': localStorage.getItem('lang') || 'ua',

            },
            body: formData
        });

        if (res.ok) {
            let response = await res.json();
            let error = findErrorResponse(response);
            return !error ? response : {error: error}
        }
        return {error: `${res.statusText}`}

    };
    _authUpdateUserData = async (api_data) => {
        if (!Object.keys(api_data)) return Promise.reject('api_data empty');
        let id = api_data.client;
        let uri = `/clients/${id}`
        return await this._send(uri, "PUT", api_data);
    }

    _getMessageChannels = async () => {
        return this._send('/account/message-channels')
    }
    _setDefaultMessageChannel = async (form_data) => {
        return this._send_form_data({uri: '/account/set-default-message-channel', method: "POST", data: form_data})
    }
    _toggleEnabledMessageChannel = async (form_data) => {
        return this._send_form_data({uri: '/account/set-enabled-message-channel', method: "POST", data: form_data})
    }
    _confirmationMessageChannel = async (form_data) => {
        return this._send_form_data({uri: '/account/confirm-message-channel', method: "POST", data: form_data})
    }
    /** список подключенных соцсетей авторизованного пользователя */
    _getNetworkLogin = async () => {
        return this._send('/authi/network-login')

    }
    /**deleteNetworkLogin удалить привязку соцсети авторизованного пользователя
     * form-data:
     * network = fb/google
     * id = id из GET /api/authi/network-login.
     */
    _deleteNetworkLogin = async (api_data) => {
        let uri = `/authi/network-login?${new URLSearchParams(api_data)}`
        return this._send(uri, "DELETE")
    }
    /**
     * networkLogin авторизируем или регистрируем или подключаем сеть в ЛК пользователя.
     * data - зашифрованные данные в формате json - ниже распишу
     * network - fb/google
     * firms[] - массив фирм
     *
     * Данные в json следующие:
     * {
     *   "id" : "id пользователя в соцсети",
     *   "name": "Имя",
     *   "email" : "email",
     *   "phone":"phone",
     *   from: base64(uri)
     * }
     * @returns  {object}  - state.state = ok - все ок или ошибки
     */
    _networkLogin = async (form_data) => {
        return this._send_form_data({uri: '/authi/network-login', method: "POST", data: form_data})
    }
    _getNetworkLoginHash = async () => {
        return this._send('/authi/network-login-hash')
    }
    _authLookup = async (email_or_phone = null) => {
        return this._send_form_data({
            uri: '/authi/lookup', method: "POST", data: email_or_phone
        })
    }
    _authRegistration = async (form_data) => {
        return this._send_form_data({
            uri: '/authi/registration', method: "POST", data: form_data
        })
    }
    _logout = async () => {
        return this._send('/authn', 'DELETE')
    }
    _getNews = async (page = 1) => {
        return this._send(`/news?page=${page}`)
    }
    _getCuttingCard = async (order_id) => {
        return this._send(`/cuttingcard/${order_id}`)
    }
    _getGoodServicePartOrderFirm = async (currency = null) => {
        let url = `/goodsservicepartorderfirm`
        if (currency) url += `/${currency}`
        return this._send(url)
    }

}

export default ApiService;