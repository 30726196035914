import CounterClockwiseIcon from "../../../Icons/Direction/CounterClockwiseIcon";
import ClockwiseIcon from "../../../Icons/Direction/ClockwiseIcon";
import TrajectoryLeftIcon from "../../../Icons/Trajectory/TrajectoryLeftIcon";
import TrajectoryRightIcon from "../../../Icons/Trajectory/TrajectoryRightIcon";
import TrajectoryCenterIcon from "../../../Icons/Trajectory/TrajectoryCenterIcon";

export const FREZ_NEWGO_ASIDE_TYPE = "frez_newgo"


const Direction = {
    titleField: "направление движения",
    options: {
        '1': {
            title: "Против часовой стрелки", icon: CounterClockwiseIcon, value: 1
        },
        '0': {
            title: "По часовой стрелке", icon: ClockwiseIcon, value: 0
        },
    }
}
export type FrezTrajectoryOptionKeysType = keyof typeof Trajectory.options

const Trajectory = {
    titleField: "Траектория",
    options: {
        '1': {
            title: "Слева", icon: TrajectoryLeftIcon, value: 1
        },
        '2': {
            title: "Справа", icon: TrajectoryRightIcon, value: 2
        },
        '0': {
            title: "По центру", icon: TrajectoryCenterIcon, value: 0
        }
    }
}

const CurveType = {
    titleField: "тип дуги",
    options: {
        'big': {
            title: "Большая", icon: () => {
                return null
            }, value: "big"
        },
        'small': {
            title: "Маленькая", icon: () => {
                return null
            }, value: "small"
        },
    }


}

const Trough = {
    titleField: "выборка",
    options: {
        '1': {
            title: "Да", icon: "", value: "1"
        },
        '0': {
            title: "Нет", icon: "", value: "0"
        },
    }
}
const macOptions = {
    dir: Direction,
    curve_type: CurveType
}

const ma3pOptions = {
    // dir: Direction,
}
const msOptions = {
    c: Trajectory,
    through: Trough,
}

const maOptions = {
    dir: Direction, curve_type: CurveType,

}

export const frezMoveSettings = {
    ms: msOptions,
    mac: macOptions,
    ml: {},
    ma: maOptions,
    ma3p: ma3pOptions,
}


export const frezMoveFieldTitleSettings = {
    z: "Глубина фрезерования",
    coordinate: "Координата точки по оси",
    r: "Радиус",
    dir: "Направление движения",
    curve_type: "Тип дуги",
    c: "Траектория",
    trough: "Выборка",
    coordinateCenter: "Координата центра окружности",
    coordinateOfPoint: "Координата точки на дуге по оси"
}
