import React from 'react';
import UserAddressCreateWidget from "../../../widgets/userAddresses/UserAddressCreateWidget";
import UserAddressViewWidget from "../../../widgets/userAddresses/UserAddressViewWidget";

const UserAddressesContainer = () => {

    return (
        <div className={" w-100 d-flex flex-column gap-10"}>
            <UserAddressCreateWidget/>
            <UserAddressViewWidget/>
        </div>
    );
};

export default UserAddressesContainer;