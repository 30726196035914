import React, {useEffect} from 'react';
import {UserAddressFieldsType} from "../../../types/UserAddressesForms/FormFieldTypes";
import {utilsTypeof} from "../../../helpers/utils-typeof";

interface IFieldProxy {
    formValues: { [key: string]: any },
    fieldSetting: UserAddressFieldsType,
    children?: React.ReactNode;
    onFieldReset: () => void;
}

function FieldProxy({fieldSetting, children, formValues, onFieldReset}: IFieldProxy) {
    const refVisible = React.useRef(true);
    const [, forceRender] = React.useState(false); // Используем useState для триггера ререндера
    // const [isVisible, setVisible] = React.useState(true);

    useEffect(() => {
        if (!utilsTypeof.isObject(fieldSetting)) {
            console.error('fieldSetting must be an object', fieldSetting);
            // setVisible(false);
            refVisible.current = false;
            forceRender(prev => !prev);
            return;
        }

        if (!fieldSetting.hasOwnProperty('visibleOn')) {
            // setVisible(true);
            refVisible.current = true;
            forceRender(prev => !prev);
            return;
        }

        // Проверяем `visibleOn`
        const isVisibleOn = Object.entries(fieldSetting!.visibleOn ?? {})
            .some(([nameField, values]) => {
                const currentValue = formValues[nameField];
                return Array.isArray(values)
                    ? values.some(val => val.toString() === currentValue?.toString())
                    : values.toString() === currentValue?.toString();
            });

        if (refVisible.current !== isVisibleOn) {
            refVisible.current = isVisibleOn;
            forceRender(prev => !prev);
        }
        // refVisible.current = isVisibleOn;

        // setVisible(isVisibleOn);

        // Если поле скрывается — сбрасываем его значение
        if (!isVisibleOn && onFieldReset) {
            onFieldReset();
        }


    }, [fieldSetting, formValues]);
    if (!refVisible.current) {
        return <></>;
    }
    return (<>{children}</>);
};

export default FieldProxy;