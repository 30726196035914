import React, {useCallback, useState} from "react";
import {useSelector} from "react-redux";
import Left from "../../Icons/Cutting/Left";
import Top from "../../Icons/Cutting/Top";
import Right from "../../Icons/Cutting/Right";
import Bottom from "../../Icons/Cutting/Bottom";
import FieldWrap from "../FormUi/FieldWrap";
import {_getItemID} from "../../store/reducers/OrderReducer";
import {getOperationsEntrySide} from "../../selectors/operations-selector";
import PazImageLoader from "../PazSvg/PazImageLoader";
import {utilsTypeof} from "../../helpers/utils-typeof";

const TemplatePazDetailForm = ({
                                   onCreatePazHandler,
                                   getT, template,
                                   part,
                                   bSide = {},
                                   tSide = {},
                                   lSide = {},
                                   rSide = {}
                               }) => {

    const optionsSide = useSelector(getOperationsEntrySide)?.filter(side => side?.id === "f" || side?.id === "bb");
    const [form, setForm] = useState([
        {
            id_template_paz: tSide?.id_template_paz || null,
            side: tSide?.side || "",
            side_from: "t",
            icon: <Top/>

        },
        {
            id_template_paz: bSide?.id_template_paz || null,
            side: bSide?.side || "",
            side_from: "b",
            icon: <Bottom/>
        },
        {
            id_template_paz: lSide?.id_template_paz || null,
            side: lSide?.side || "",
            side_from: "l",
            icon: <Left/>
        },
        {
            id_template_paz: rSide?.id_template_paz || null,
            side: rSide?.side || "",
            side_from: "r",
            icon: <Right/>
        }

    ]);


    const onSubmitHandler = (e) => {
        e.preventDefault();
        const idLast = _getItemID(part?.operations?.paz);
        const errors = form?.filter(item => {
            const paz = utilsTypeof.isValidNumber(item?.id_template_paz) ? item.id_template_paz : null;
            const side = item?.side;
            return (paz && !side) || (!paz && side)
        });

        if (errors.length) {
            return false;
        }
        let formCreate =
            form.map((item, index) => {
                const currentTemplate = template?.find(temp => Number(temp?.id) === Number(item?.id_template_paz));
                return {
                    id: Number(idLast) + index,
                    id_template_paz: Number(item?.id_template_paz),
                    template_name: currentTemplate?.name_ifp,
                    side: item?.side,
                    side_from: item?.side_from,
                    w: Number(currentTemplate?.w),
                    type_paz: 1,
                    w_paz: Number(currentTemplate?.w_paz),
                    d: Number(currentTemplate?.d),
                    d1: Number(currentTemplate?.d),
                    z: Number(currentTemplate?.z),
                    color_paz: currentTemplate?.color
                };
            });
        onCreatePazHandler(formCreate);
    };
    const onChangeHandler = useCallback(({side_from}) => event => {
        setForm(prev => {
            return prev?.map(field => {
                if (field.side_from === side_from) {
                    field[event.target?.name] = event?.target?.value;
                }
                return field;
            });
        });
    }, [form, setForm]);


    const findTemplateById = (id, side_from) => {
        const result = template?.find(temp => {
            return Number(temp?.id) === Number(id)
        });
        return result ? {...result, side_from: side_from} : null;
    }
    return (<div className="" style={{width: "100%"}}>
            {template?.length ?
                <form className={"add-material template-modal"}
                      onSubmit={onSubmitHandler}>
                    {form?.map(field => {
                        const targetTemplate = findTemplateById(field.id_template_paz, field?.side_from);
                        const targetSide = field?.side ?? null;
                        const isErrorSide = targetTemplate && !targetSide;
                        const isErrorTemplate = targetSide && !targetTemplate;
                        return <div className="field filed-template-paz">
                            <span className="icon">{field?.icon}</span>
                            <div className="content">
                                <FieldWrap label={getT("Сторона операции")} error={isErrorSide}
                                           children={<select className={"form-input"} name="side" value={form?.side}
                                                             onChange={onChangeHandler({
                                                                 side_from: field?.side_from,
                                                             })}
                                           >
                                               <option value={""}>{getT("Выбрать")}</option>
                                               {optionsSide?.length && Array.isArray(optionsSide)
                                                   ? optionsSide?.map(side => <option
                                                       selected={field?.side === side?.id}
                                                       value={side?.id}>{getT(side?.name)}</option>)
                                                   : null
                                               }
                                           </select>}
                                />
                                <FieldWrap error={isErrorTemplate}
                                           label={
                                               <div
                                                   className={"template-paz-picture-wrapper d-flex items-center gap-10"}>
                                                   {getT("Шаблон пазования")}
                                                   {targetTemplate &&
                                                       <PazImageLoader isScaleHover={true} width={'25px'} part={part}
                                                                       operationPaz={targetTemplate}/>}
                                               </div>

                                           }
                                           children={<select className={"form-input"} name="id_template_paz"
                                                             value={form?.id_template_paz}
                                                             onChange={onChangeHandler({
                                                                 side_from: field?.side_from
                                                             })}
                                           >
                                               <option value={null}>{getT("Выбрать")}</option>
                                               {template?.length && Array.isArray(template) ? template?.map(template =>
                                                   <option
                                                       selected={Number(field?.id_template_paz) === Number(template?.id)}
                                                       value={template?.id}>[{template?.id}] {template?.name_ifp}</option>) : null}
                                           </select>}
                                />
                            </div>
                        </div>;
                    })}
                    <input type={"submit"} className={"submit form-input"} value={getT("Отправить")}/>
                </form>
                : <h3 style={{textAlign: "center", width: "100%"}}>{getT("Шаблоны пазования отсутствуют")}</h3>}

        </div>
    );
};

export default TemplatePazDetailForm;