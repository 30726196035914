export const MODAL_TOGGLE_CREATE_PROJECT = "MODAL/TOGGLE_CREATE_PROJECT";
export const MODAL_TOGGLE_ORDER_PART_GOODS_SERVICE = "MODAL/TOGGLE_ORDER_PART_GOODS_SERVICE";

export const MODAL_TOGGLE_OPERATION_FREZ_START_MENU = "MODAL/TOGGLE_OPERATION_FREZ_START_MENU";
export const MODAL_TOGGLE_TROUGH_CREATE_OPERATION = "MODAL/TOGGLE_TROUGH_CREATE_OPERATION";
export const MODAL_TOGGLE_FREZ_IMPORT_OPERATION_CREATE = "MODAL/TOGGLE_FREZ_IMPORT_OPERATION_CREATE";

export const MODAL_TOGGLE_TEMPLATES = "MODAL/TOGGLE_TEMPLATES";
export const MODAL_TOGGLE_ALERTS_PROJECT = "MODAL/TOGGLE_ALERTS_PROJECT";
export const MODAL_TOGGLE_FREZ_ERRORS_PROJECT = "MODAL/TOGGLE_FREZ_ERRORS_PROJECT";
export const modalToggleCreateProjectAC = ({isOpen = false, user_id = null, firm = null}) => {
    return {
        type: MODAL_TOGGLE_CREATE_PROJECT,
        payload: {isOpen: isOpen, user_id: user_id, firm: firm}
    }
}
/**
 * дополнительные услуги к деталям.
 * @param {boolean} isOpen  -
 * @param {object | null} part  - добавление к детали услуг
 */
export const modalToggleOrderPartGoodsServiceAC = ({
                                                       isOpen = false,
                                                       part = null,
                                                       sizeType = 'saw',
                                                       product_count = 1
                                                   }) => {
    return {
        type: MODAL_TOGGLE_ORDER_PART_GOODS_SERVICE,
        payload: {isOpen: isOpen, part: part, sizeType: sizeType, product_count: product_count}
    }
}


export const operationFrezStartMenuModalAc = (isOpen = false, currentPart) => {
    return {
        type: MODAL_TOGGLE_OPERATION_FREZ_START_MENU,
        payload: {isOpen: isOpen},
    }
}

export const throughCreateOperationModalAC = (isOpen = false, typeTrough) => {
    return {
        type: MODAL_TOGGLE_TROUGH_CREATE_OPERATION,
        payload: {isOpen: isOpen, typeTrough: typeTrough},
    }
}

export const frezImportCreateOperationModalAC = (isOpen = false) => {
    return {
        type: MODAL_TOGGLE_FREZ_IMPORT_OPERATION_CREATE,
        payload: {isOpen: isOpen},
    }
}
export const toggleTemplatesModalAC = (payload) => {
    return {
        type: MODAL_TOGGLE_TEMPLATES,
        payload: payload
    }
}

export const modalToggleAlertModalAc = (isOpen = false) => {
    return {
        type: MODAL_TOGGLE_ALERTS_PROJECT,
        payload: {isOpen: isOpen},
    }
}
export const modalToggleFrezErrorsProjectModalAc = (isOpen = false, errors) => {
    return {
        type: MODAL_TOGGLE_FREZ_ERRORS_PROJECT,
        payload: {isOpen: isOpen, errors: errors},
    }
}