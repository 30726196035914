import {IUserAddressMapView} from "../hook/useUserAddressesMapper";
import React from "react";
import IconTrash from "../../../Icons/IconTrash";
import "./address-card.css"
import Edit from "../../../Icons/TableActions/Edit";

interface IAddressCard {
    item: IUserAddressMapView;
    onEdit: (addressId: number) => void;
    onDelete: (addressId: number) => void;
}


function AddressCard({item, onDelete, onEdit}: IAddressCard) {

    const isDefaultAddress = !!Number(item.default);
    // const isDefaultAddress = true;
    return (<div className={`address-card ${isDefaultAddress ? "default-address" : ""}`}>
        <div className="address-card-header d-flex align-items-center justify-between">
            <div className="">
                <h4 className={'m-0 h4'}>{item.name}</h4>
                {isDefaultAddress ? <small className={"default-address-text"}>адрес по умолчанию</small> : null}
            </div>

            <div className="panel ">
                <Edit onClick={() => onEdit(item.id)}/>
                <IconTrash onClick={() => onDelete(item.id)}/>
            </div>
        </div>
        <div className="address-card-content">
            <ul>
                {item.address.map(address => {
                    return (<li><strong className={""}>{address.label} :</strong> <span>{address.value}</span></li>)
                })}
            </ul>
        </div>

    </div>)
}

export default AddressCard;