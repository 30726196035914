import {changeLoaderStatusAC} from "../../reducers/CommonReducer";
import {dispatcherErrorThunk} from "../common-thunk";
import {AppDispatch, RootState} from "../../store";
import {CheckoutApi} from "../../../api/Checkout/CheckoutApi";
import {ThunkAction} from "redux-thunk";
import {AnyAction} from "redux";
import {setOrderDeliveryVariantActions} from "../../actions/user-address-action";
import {IUserAddress} from "../../../types/UserAddressesForms/FormFieldTypes";
import {
    selectInitialValuesUserAddressesDeliveryStrategy
} from "../../../shared/stategy/initialValuesUserAddressDeliveryStrategies";
import {setCheckoutAddressDeliveryFieldDataAction} from "../../actions/checkout-address-delivery-action";
import {UserAddressesApi} from "../../../api/UserAddresses/UserAddressesApi";
import {utilsTypeof} from "../../../helpers/utils-typeof";

export const getOrderDeliveryVariantsForCheckout =
    (): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
        async (dispatch: AppDispatch, getState: any): Promise<void> => {
            try {
                dispatch(changeLoaderStatusAC(true));
                const orderId = getState().order.order.id;
                const clientId = getState().order.order.client;
                const result = await CheckoutApi.getCheckoutDeliveryFormByOrderId(orderId, clientId);

                if (result?.error && result.error?.length > 0) {
                    dispatch(dispatcherErrorThunk({error: result.error}));
                    return
                }
                if (result?.variants && Array.isArray(result.variants)) {
                    dispatch(setOrderDeliveryVariantActions(result.variants));
                    const clientAddresses = result.variants
                        .map((item) => item.clientAddresses) // Используем map для извлечения массивов адресов
                        .flat()
                    // Сплющиваем массив массивов в один массив
                    const defaultAddress = clientAddresses.find((address) => address.default);

                    if (defaultAddress) {
                        const values = selectInitialValuesUserAddressesDeliveryStrategy(defaultAddress);
                        const result = await UserAddressesApi.postValidator(values)
                        if (result?.error && result.error.length > 0) {
                            dispatch(dispatcherErrorThunk({error: result.error}));
                            return
                        }

                        dispatch(setCheckoutAddressDeliveryFieldDataAction({
                            valueForm: values,
                            formId: defaultAddress.id.toString(),
                            externalErrors: result?.errors ? result.errors : null,
                            defaultIndexForm: null
                        }));

                    } else {
                        let valueForm = null;
                        let formId = null;
                        let externalErrors = null;

                        const indexDefaultForm = result.variants.findIndex(v => v.plugin.is_default)
                        const formDefault = result.variants?.[indexDefaultForm] ?? null;
                        let defaultIndexForm = formDefault ? indexDefaultForm : null;

                        if (!clientAddresses.length && formDefault) {
                            formId = "new";
                            valueForm = selectInitialValuesUserAddressesDeliveryStrategy(formDefault);
                            const result = await UserAddressesApi.postValidator(valueForm)
                            if (result?.error && result.error.length > 0) {
                                dispatch(dispatcherErrorThunk({error: result.error}));
                                return
                            }
                            externalErrors = result?.errors ? result.errors : null
                        }

                        dispatch(setCheckoutAddressDeliveryFieldDataAction({
                            valueForm: valueForm,
                            formId: formId,
                            externalErrors: externalErrors,
                            defaultIndexForm: defaultIndexForm
                        }))
                    }
                }
                dispatch(changeLoaderStatusAC(false));
            } catch (error) {

                dispatch(dispatcherErrorThunk(error));

            }
        }

/**
 * метод для валидирования существующих адресов в контексе оформление заказ
 * и обновление даних поля  в confirmAddressDelivery store
 * */

export const selectOrderDeliveryAddressThunk =
    (form: IUserAddress): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
        async (dispatch: AppDispatch, getState: any): Promise<void> => {
            try {
                dispatch(changeLoaderStatusAC(true));
                const values = selectInitialValuesUserAddressesDeliveryStrategy(form);


                const result = await UserAddressesApi.postValidator(values)
                if (result?.error && result.error.length > 0) {
                    dispatch(dispatcherErrorThunk({error: result.error}));
                    return
                }

                dispatch(setCheckoutAddressDeliveryFieldDataAction({
                    valueForm: values,
                    formId: form.id.toString(),
                    externalErrors: result?.errors ? result.errors : null
                }))

                dispatch(changeLoaderStatusAC(false));
            } catch (error) {
                dispatch(dispatcherErrorThunk(error));

            }
        }


/**
 * метод для обновления существующего  адреса с ошибкой в контексе оформление заказ
 * и обновление даних поля  в confirmAddressDelivery store
 * */

export const updateOrderDeliveryAddressThunk =
    (values: { [key: string]: string }): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
        async (dispatch: AppDispatch, getState: any): Promise<void> => {
            try {
                const orderId = getState().order.order.id;
                const clientId = getState().order.order.client;
                dispatch(changeLoaderStatusAC(true));


                const result = await UserAddressesApi.putUserAddress(values, clientId)
                if (result?.error && result.error.length > 0) {
                    dispatch(dispatcherErrorThunk({error: result.error}));
                    return
                }
                if (utilsTypeof.isObject(result.errors) && Object.keys(result?.error ?? {}).length > 0) {
                    dispatch(setCheckoutAddressDeliveryFieldDataAction({
                        valueForm: values,
                        formId: values.id.toString(),
                        externalErrors: result?.errors ? result.errors : null
                    }))
                    dispatch(changeLoaderStatusAC(false));
                    return;
                }
                const addressesDelivery = await CheckoutApi.getCheckoutDeliveryFormByOrderId(orderId, clientId)

                if (result?.error && result.error.length > 0) {
                    dispatch(dispatcherErrorThunk({error: result.error}));
                    return
                }
                dispatch(setCheckoutAddressDeliveryFieldDataAction({
                    valueForm: values,
                    formId: values.id.toString(),
                    externalErrors: null
                }));
                if (Array.isArray(addressesDelivery.variants)) {
                    dispatch(setOrderDeliveryVariantActions(addressesDelivery.variants));
                }

                dispatch(changeLoaderStatusAC(false));
            } catch (error) {
                dispatch(dispatcherErrorThunk(error));

            }
        }

/**
 * метод для добавлние нового адреса в контексе оформление заказ
 * и обновление даних поля  в confirmAddressDelivery store
 * */

export const addOrderDeliveryAddressThunk =
    (values: {
        [key: string]: string,
        saveAddress: string
    }): ThunkAction<Promise<void>, RootState, unknown, AnyAction> =>
        async (dispatch: AppDispatch, getState: any): Promise<void> => {
            try {
                dispatch(changeLoaderStatusAC(true));

                const {saveAddress, ...reset} = values
                const result = await UserAddressesApi.postValidator(reset)
                if (result?.error && result.error.length > 0) {
                    dispatch(dispatcherErrorThunk({error: result.error}));
                    return
                }

                dispatch(setCheckoutAddressDeliveryFieldDataAction({
                    valueForm: {...reset, saveAddress},
                    formId: "new",
                    externalErrors: result?.errors ? result.errors : null
                }))
                dispatch(changeLoaderStatusAC(false));


                dispatch(changeLoaderStatusAC(false));
            } catch (error) {
                dispatch(dispatcherErrorThunk(error));

            }
        }