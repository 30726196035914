import React from 'react';
import Danger from "../../../Icons/Danger";
import {IGetT} from "../../Boxes/interfaces";
import HintTooltip from "../../ui/HintTooltip";

interface Props {
    hasErrors: boolean
    part: { operations: { frez: any[] } }[],
    onOpenErrorModal: (type: "common-error" | "frez-error") => void,
    getT: IGetT
}
/**
 *
 * */
const DangerProjectAction: React.FC<Props> = ({part, hasErrors, onOpenErrorModal, getT}) => {
    const isHasOperationFrez = part?.some(item => !!item.operations?.frez?.length)
    return (
        <div className="danger-project">
            {isHasOperationFrez ? (
                    <HintTooltip header={<Danger hasErrors={hasErrors} className={'cursor-pointer footer-danger'}/>}>
                        <div className={"danger-project-wrapper"}>
                            <div className="cursor-pointer danger-project-action"
                                 onClick={() => onOpenErrorModal("common-error")}>{getT("Ошибки в проекте")}</div>
                            <div className="cursor-pointer danger-project-action"
                                 onClick={() => onOpenErrorModal("frez-error")}>{getT("Ошибки фрезеровок в проекте")}</div>
                        </div>
                    </HintTooltip>
                )
                :
                <Danger onClick={() => onOpenErrorModal("common-error")}
                        hasErrors={hasErrors}
                        className={'footer-danger cursor-pointer'}/>

            }
        </div>
    );
};

export default DangerProjectAction;