import {UserAddressLookupField} from "../../../types/UserAddressesForms/FormFieldTypes";
import {UserAddressesApi} from "../../../api/UserAddresses/UserAddressesApi";
import AsyncSelect from "react-select/async";
import {utilsTypeof} from "../../../helpers/utils-typeof";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";

interface ILookupField {
    fieldSetting: UserAddressLookupField
    formValues: { [key: string]: string | number },
    onChangeFiled: (name: string, value: string) => void
    defaultValue: OptionType | null,
    extendFilter: { order: string | null, plugin: string | null },
}

type OptionsResult = {
    [key: string]: string
}

type ResultApi = {
    result: boolean,
    data: OptionsResult
}
type OptionType = {
    value: string;
    label: string;
};
type FilterType = {
    key: string;
    value: string;
}
const isResultOptions = (result: any): result is { [key: string]: string } => utilsTypeof.isObject(result);

function mapObjectToOption(objectEntry: any): OptionType[] {
    if (isResultOptions(objectEntry)) {
        return Object.entries(objectEntry).map(([key, value]) => ({
            value: key,
            label: value
        }))
    }

    return []
}


function LookupField({fieldSetting, formValues, onChangeFiled, defaultValue, extendFilter}: ILookupField) {
    const dependentKeys = useMemo(() => fieldSetting.options?.filters ?? [], [fieldSetting]);
    const prevValuesRef = useRef<{ [key: string]: string | number }>(dependentKeys.reduce((acc, key) => {
        acc[key] = formValues[key];
        return acc;
    }, {} as { [key: string]: string | number }));

    const [options, setOptions] = useState<OptionType[]>(mapObjectToOption(fieldSetting.options?.option));
    const [defValues, setDefValues] = useState<OptionType | null>(defaultValue);

    useEffect(() => {
        const hasChangedParent = dependentKeys.some(
            key => prevValuesRef.current[key] !== formValues[key]
        );

        if (hasChangedParent) {
            onChangeFiled(fieldSetting.name as string, '');
            prevValuesRef.current = dependentKeys.reduce((acc, key) => {
                acc[key] = formValues[key];
                return acc;
            }, {} as { [key: string]: string | number });

            fetchOptions("").then(res => {
                setOptions(mapObjectToOption(res.data))
                setDefValues(null)
            });

        }
    }, [formValues, dependentKeys]);


    const cleanActionMethod = () => {
        fetchOptions("").then(res => {
            setOptions(mapObjectToOption(res.data))
        });
    }

    const fetchOptions = useCallback((inputValue: string): Promise<ResultApi> => {
        return new Promise(async (resolve, reject) => {
            try {
                let filters: FilterType[] = dependentKeys.map(key => ({
                    key: `filters[${key}]`,
                    value: formValues[key]?.toString() || ''
                }));
                console.log('extendFilter', extendFilter)
                if (utilsTypeof.isObject(extendFilter) && Object.keys(extendFilter).length > 0) {
                    Object.keys(extendFilter).forEach((key) => {
                        //@ts-ignore
                        const v = extendFilter[key];
                        if (v) {

                            filters.push(
                                {
                                    key: `${key}`,
                                    value: v.toString() || ''
                                }
                            );
                        }
                    })
                }
                const result = await UserAddressesApi.getLookupDirection(fieldSetting.options.model, filters, inputValue);
                resolve(result);  // Resolve the promise with the fetched options
            } catch (error) {
                reject(error);  // Reject the promise if there's an error
            }
        });
    }, [dependentKeys, formValues]);


    const loadOptions = (
        inputValue: string,
        callback: (options: OptionType[]) => void
    ) => {
        fetchOptions(inputValue).then((result) => {
            if (utilsTypeof.isObject(result?.data)) {

                const options = mapObjectToOption(result?.data);
                callback(options);
            }
        })
            .catch(error => {
                console.error(error);
            });
    };
    return (
        <AsyncSelect cacheOptions
                     styles={{
                         menuList: (base) => ({
                             ...base,
                             maxHeight: "120px", // Ограничиваем высоту списка
                             overflowY: "auto", // Добавляем скролл, если элементов много
                         }),
                     }}
                     className={'react-select-default'}
                     classNamePrefix="custom"
                     isClearable={true}
                     name={fieldSetting.name}
                     onChange={(newValue, actionMeta) => {
                         onChangeFiled(actionMeta.name as string, newValue?.value ?? '');
                         setDefValues(newValue);
                         if (actionMeta.action === "clear") {
                             cleanActionMethod()
                         }
                     }}
                     loadOptions={loadOptions}
                     value={defValues}
                     defaultOptions={options}
                     required={fieldSetting.required}
        />
    );
}

export default LookupField;