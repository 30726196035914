import Button from "../../components/ui/Button";
import React, {useEffect, useState} from "react";
import {useDispatch, useSelector} from "react-redux";
import {userAddressesDeliveryEntriesSelector} from "../../selectors/user-addresses-selector";
import {AppDispatch} from "../../store/store";
import {getUserAddressesDeliveryThunk} from "../../store/thunks/userAddresses/case-view-addresses-thunk";
import UserAddressView from "../../features/userAddresses/viewCabinet/UserAddressView";
import UserAddressEditModal from "../../features/userAddresses/modal/UserAddressEditModal";
import {useLang} from "../../context/langProvider";

function UserAddressViewWidget() {
    const {getT} = useLang();
    const dispatch = useDispatch<AppDispatch>();
    const [isShow, setToggleShow] = useState<boolean>(false)
    const addresses = useSelector(userAddressesDeliveryEntriesSelector);

    useEffect(() => {
        dispatch(getUserAddressesDeliveryThunk());
    }, []);


    return (
        <div className={"settings-section w-100 bg-white"}>
            <div className="d-flex justify-between items-center">
                <h2 className="">{getT("Сохранённые адреса доставки")}</h2>
                <div className="">
                    {isShow ?
                        <Button theme={"light"} onClick={() => setToggleShow(false)}>
                            <svg width={"24"} height={"24"} viewBox="0 0 24 24" fill="none"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier">
                                    <path
                                        d="M20.7457 3.32851C20.3552 2.93798 19.722 2.93798 19.3315 3.32851L12.0371 10.6229L4.74275 3.32851C4.35223 2.93798 3.71906 2.93798 3.32854 3.32851C2.93801 3.71903 2.93801 4.3522 3.32854 4.74272L10.6229 12.0371L3.32856 19.3314C2.93803 19.722 2.93803 20.3551 3.32856 20.7457C3.71908 21.1362 4.35225 21.1362 4.74277 20.7457L12.0371 13.4513L19.3315 20.7457C19.722 21.1362 20.3552 21.1362 20.7457 20.7457C21.1362 20.3551 21.1362 19.722 20.7457 19.3315L13.4513 12.0371L20.7457 4.74272C21.1362 4.3522 21.1362 3.71903 20.7457 3.32851Z"
                                        fill="#0F0F0F"></path>
                                </g>
                            </svg>
                        </Button>
                        :
                        <Button theme={"light"} onClick={() => setToggleShow(true)}>
                            <svg width={"24"} height={"24"} viewBox="0 -4.5 20 20" version="1.1"
                                 xmlns="http://www.w3.org/2000/svg" fill="#000000">
                                <g id="SVGRepo_bgCarrier" stroke-width="0"></g>
                                <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g>
                                <g id="SVGRepo_iconCarrier"><title>arrow_down [#339]</title>
                                    <desc>Created with Sketch.</desc>
                                    <defs></defs>
                                    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
                                        <g id="Dribbble-Light-Preview" transform="translate(-180.000000, -6684.000000)"
                                           fill="#000000">
                                            <g id="icons" transform="translate(56.000000, 160.000000)">
                                                <path
                                                    d="M144,6525.39 L142.594,6524 L133.987,6532.261 L133.069,6531.38 L133.074,6531.385 L125.427,6524.045 L124,6525.414 C126.113,6527.443 132.014,6533.107 133.987,6535 C135.453,6533.594 134.024,6534.965 144,6525.39"
                                                    id="arrow_down-[#339]"></path>
                                            </g>
                                        </g>
                                    </g>
                                </g>
                            </svg>
                        </Button>
                    }
                </div>
            </div>
            {isShow &&
                <UserAddressView addresses={addresses}/>
            }
            <UserAddressEditModal key={'userAddressEditModal'}/>
        </div>
    )
}

export default UserAddressViewWidget;