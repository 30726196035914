import {UserAddressesApi} from "../../../api/UserAddresses/UserAddressesApi";
import {changeLoaderStatusAC} from "../../reducers/CommonReducer";
import {dispatcherErrorThunk} from "../common-thunk";
import {editUserAddressModalActions, userAddressSetFormsActions} from "../../actions/user-address-action";
import {UserAddressBaseForm} from "../../../types/UserAddressesForms/FormFieldTypes";
import {IUserAddressesFormSendRequestResult} from "../../../types/UserAddressesForms/UserAddressesInputOutput";
import {ThunkAction} from "redux-thunk";
import {AppDispatch, RootState} from "../../store";
import {AnyAction} from "redux";
import {getUserAddressesDeliveryThunk} from "./case-view-addresses-thunk";
import {utilsTypeof} from "../../../helpers/utils-typeof";

export const userAddressesGetForms = () => async (dispatch: any) => {
    try {
        dispatch(changeLoaderStatusAC(true));
        const result = await UserAddressesApi.getForms() as {
            error?: string,
            result: boolean,
            forms: UserAddressBaseForm
        };

        if (result?.error && result?.error?.length > 0) {
            dispatch(dispatcherErrorThunk({error: result.error}));
            return;
        }
        dispatch(userAddressSetFormsActions(result.forms));
        dispatch(changeLoaderStatusAC(false));


    } catch (error) {
        dispatch(dispatcherErrorThunk(error));
    }
}
type UserAddressesCreateInput = {
    form: string;
    plugin: string;
    [key: string]: string,

}

export const userAddressCreateThunk =
    (dto: UserAddressesCreateInput): ThunkAction<Promise<IUserAddressesFormSendRequestResult>, RootState, unknown, AnyAction> =>
        async (dispatch: AppDispatch, getState:any) => {
            try {
                dispatch(changeLoaderStatusAC(true));

                const clientId = getState().auth.user.client_id;
                const result = await UserAddressesApi.postUserAddress(dto, clientId);
                if (result.created) {
                    await dispatch(getUserAddressesDeliveryThunk());
                }
                dispatch(changeLoaderStatusAC(false));
                return {
                    errors: result.errors,
                    isOk: result.created
                };


            } catch (error) {

                dispatch(dispatcherErrorThunk(error));
                return {
                    errors: null,
                    isOk: false
                };
            }
        }

        /**
         * Обновить адрес в ЛК
         * */
export const userAddressUpdateThunk =
    (dto: UserAddressesCreateInput): ThunkAction<Promise<IUserAddressesFormSendRequestResult>, RootState, unknown, AnyAction> =>
        async (dispatch: AppDispatch, getState:any) => {
            try {
                dispatch(changeLoaderStatusAC(true));
                const clientId = getState().auth.user.client_id;
                const result = await UserAddressesApi.putUserAddress(dto, clientId);
                dispatch(changeLoaderStatusAC(false));
                if (result?.updated) {
                    await dispatch(getUserAddressesDeliveryThunk());
                    dispatch(editUserAddressModalActions(null, false));
                }

                return {
                    errors: result.errors,
                    isOk: result.updated
                };

            } catch (error) {

                dispatch(dispatcherErrorThunk(error));
                return {
                    errors: null,
                    isOk: false
                };
            }
        }

export const userAddressDeleteThunk = (addressId: number): ThunkAction<void, RootState, unknown, AnyAction> =>
    async (dispatch: AppDispatch, getState:any) => {
        try {
            dispatch(changeLoaderStatusAC(true));
            const clientId = getState().auth.user.client_id;
            const result = await UserAddressesApi.deleteUserAddress(addressId, clientId);
            if (result?.error && result?.error?.length > 0) {
                dispatch(dispatcherErrorThunk({error: result.error}));
                return;
            }
            await dispatch(getUserAddressesDeliveryThunk());

            dispatch(changeLoaderStatusAC(false));

        } catch (error) {
            console.log(error)
            dispatch(dispatcherErrorThunk(error));
        }
    }