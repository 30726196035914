import React from 'react';
import {IGetT} from "../../../Boxes/interfaces";
import NotificationIBoxItem from "./NotificationIBoxItem";

interface Notification {
    [key: string]: Record<string, string>
}

interface NotificationWrapperProps {
    notifications: Notification | null,
    parts: { id: number; x1: number; y1: number; z: number }[],
    onOpenOperation: (part: any) => void,
    getT: IGetT
}

const NotificationWrapper: React.FC<NotificationWrapperProps> = ({notifications, parts, onOpenOperation, getT}) => {
    if (!notifications || typeof notifications !== 'object') return null;
    return (
        <div className="notification-wrapper">
            {Object.entries(notifications)
                .map(([partId, value]) => {
                    const part = parts.find((part) => Number(part.id) === Number(partId));
                    const title = `${getT('Деталь')} №${partId}. [${part?.x1}]`;
                    const messages = Object.values(value);
                    return (
                        <div key={partId}>
                            <NotificationIBoxItem title={title} messages={messages} onClick={() => {
                                if (part) onOpenOperation(part);
                            }}/>
                        </div>
                    )
                })}
        </div>
    );
};

export default NotificationWrapper;