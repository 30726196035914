import MainApi from "./MainApi";
import {LS_FIRM} from "../constants";

const transformGetInitialData = (data) => {

    return {
        filter_data: {
            text: data?.text || '',
            type: data?.type,
            producer: data?.producers,
            x: data?.x, y: data?.y, z: data?.z,
            folders: data?.type_material
        },
        pagination: {
            from: data?.goods?.from,
            count: data?.goods?.count,
        },
        goods: data?.goods?.data
    }
}
const transformPostFiltered = (data) => {
    return {
        filter_data: {
            text: data?.text || '',
            type: data?.filter_data?.type,
            producer: data?.filter_data?.producers,
            x: data?.filter_data?.x,
            y: data?.filter_data?.y,
            z: data?.filter_data?.z,
            folders: data?.filter_data?.tree
        },
        pagination: {
            from: data?.from,
            count: data?.count,
        },
        goods: data?.data
    }
}
const transformMaterials = (data) => {
    return data.map(e => {
        if (e.type === 'part') e.part = 1;
        if (e.type === 'product') e.tovar = 1;
        if (e.type === 'band') e.band = 1;
        if (Number(e.part === 1)) {
            if (!e.hasOwnProperty('additional') || Array.isArray(e?.additional)) {
                e.additional = {
                    trimL: '',
                    trimW: '',
                    rez_napr: '',
                    active: 1,
                    turn: '',
                    hard_cut: ''
                };
            } else {
                e.additional = {
                    ...e.additional,
                    active: e.additional?.hasOwnProperty('active') ? e.additional.active : 1
                }
            }
        }
        if (Number(e.band === 1)) {
            if (!e.hasOwnProperty('additional') || Array.isArray(e?.additional)) {
                e.additional = {
                    per: '',
                    rest: '',
                    min: ''
                };
            }
        }
        return e;
    });
}

export class MaterialSelectedAPI extends MainApi {
    constructor() {
        super();
    }

    postFiltered = async (data) => {
        let res = await this._send('/searchmaterial/', "POST", data)
        if (res) {
            return transformPostFiltered({...res, text: data?.text})
        }
        return false
    }
    getByIdMaterial = async (id) => {
        let res = await this._send('/materialsn', "POST", {
            firm: localStorage.getItem(LS_FIRM),
            goods_id: id
        }, {
            'Point-Action': 'filter',
            // 'Language': 'ru'
        })
        if (res && res.data) {
            let goods = transformMaterials(res.data.data)
            return goods?.[0] || null
        }
        return null
    }

}

