import React from 'react';
import useUserAddressesMapper from "../hook/useUserAddressesMapper";
import {IUserAddress} from "../../../types/UserAddressesForms/FormFieldTypes";
import AddressCard from "../card/AddressCard";
import "./user-address-view.css"
import {useDispatch} from "react-redux";
import {userAddressDeleteThunk} from "../../../store/thunks/userAddresses/case-lk-command-thunk";
import {editUserAddressModalActions} from "../../../store/actions/user-address-action";

interface IUserAddressView {
    addresses: IUserAddress[] | null,
    // renderProps: (address: IUserAddressMapView) => React.ReactNode,
}

const UserAddressView = ({addresses}: IUserAddressView) => {
    const {addressesMapView} = useUserAddressesMapper({addresses});
    const dispatch = useDispatch();
    if (!addressesMapView) return null;
    const onDelete = (addressId: number) => {
        dispatch(userAddressDeleteThunk(addressId))
    }
    const onEdit = (addressId: number) => {
        const deliveryForm = addresses!.find(address => address.id === addressId);
        if (!deliveryForm) {
            return;
        }
        dispatch(editUserAddressModalActions(deliveryForm, true))
    }
    return (
        <div className={"user-address-views"}>

            {addressesMapView.map(item => {
                return <div className="item-address-view" key={item.id}>
                    <AddressCard item={item} key={item.id}
                                 onDelete={onDelete}
                                 onEdit={onEdit}/>
                </div>
            })}
        </div>
    );
};

export default UserAddressView;