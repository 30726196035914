import {instances} from "../api_axios_settings";
import {URI_API_GIBLAB} from "../uri_api_action";
import {rejectTransform, resolveTransform} from "../utils_api";

export const GiblabApi = {
    getIfpProject: (order: any) => {
        return instances.post(URI_API_GIBLAB, {
            print_array: [],
            action: "get_ifp_project",
            order: order
        }).then(res => resolveTransform(res))
            .catch(error => rejectTransform(error))
    }
}