import React, {useEffect, useState} from 'react';
import ImgCard3D from "../../../images/card3d.jpg";
import {LS_LINK_T0_3D_CARD} from "../../../constants";
import ApiService from "../../../api/api";

interface IProps {
    saveProjectAndRedirect: any;
}

const Card3DMode = ({saveProjectAndRedirect}: IProps) => {
    const [isShowCard, toggleShowCard] = useState(false);
    const settingUrl = localStorage.getItem(LS_LINK_T0_3D_CARD);

    useEffect(() => {

        const subscriber = (event: KeyboardEvent) => {
            if (event.altKey && event.key === "d") {
                event.preventDefault(); // Предотвращаем возможные стандартные действия
                toggleShowCard(prev => !prev);
            }
        }

        document.addEventListener('keydown', subscriber);
        return () => document.removeEventListener('keydown', subscriber);

    }, []);

    if (!settingUrl || !isShowCard) {
        return null;
    }
    const handleOpen = (e: any) => {
        const redirectUrl = new ApiService()._baseIfurn + '/cad/?id=' + settingUrl;
        saveProjectAndRedirect(redirectUrl)()
    }


    return (
        <div className="card3d-wrap" onClick={handleOpen}>
            <img src={ImgCard3D} alt="" height={30}/>
        </div>
    );
};

export default Card3DMode;