import {
    EditOperationFrezMoveModalType,
    FrezMoveAddModalT,
    FrezMoveTypesModalT
} from "../reducers/extendedFrezOperations/interfaceExtendedFrezStore";

export const FREZ_OPERATION_CREATE_MODAL = "EXTENDED_FREZ_MOVE/CREATE_MODAL"
export const FREZ_MOVE_EDIT_MODAL = "EXTENDED_FREZ_MOVE/EDIT_MODAL"
export const FREZ_MOVE_TYPES_MODAL = "EXTENDED_FREZ_MOVE/TYPES_MODAL"
export const FREZ_MOVE_ADD_MODAL = "EXTENDED_FREZ_MOVE/ADD_MODAL"

export const frezOperationCreateModalAC = (isOpen: boolean) => {
    return {
        type: FREZ_OPERATION_CREATE_MODAL,
        payload: {isOpen: isOpen}
    }
}


export const frezMoveEditModalAC = (payload: EditOperationFrezMoveModalType) => {
    return {
        type: FREZ_MOVE_EDIT_MODAL,
        payload: payload
    }
}


export const frezMoveTypesModalAC = ({
                                         isOpen,
                                         frez, insertionIndex
                                     }: FrezMoveTypesModalT) => {
    return {
        type: FREZ_MOVE_TYPES_MODAL,
        payload: {isOpen: isOpen, frez: frez, insertionIndex: insertionIndex}
    }
}

/**
 *  {initialPrevForm} -  индекса вставки в массив frez_move
 * */
export const frezMoveAddModalAC = ({
                                       isOpen,
                                       frez, type, initialPrevForm, insertionIndex
                                   }: FrezMoveAddModalT) => {
    return {
        type: FREZ_MOVE_ADD_MODAL,
        payload: {
            isOpen: isOpen,
            frez: frez,
            type: type,
            initialPrevForm: initialPrevForm,
            insertionIndex: insertionIndex
        }
    }
}