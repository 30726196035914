import React from 'react';
import {useDispatch, useSelector} from "react-redux";
import {helpArticleGetByIdToggleModalAC} from "../../../store/actions/help-article-action";
import Loader from "../../Loader/Loader";
import {helpArticleModalSelected, helpArticleSendCommentMailSelected} from "../../../selectors/help-article-selector";
import Modal from "../BasicModal";
import parse from "html-react-parser";
import Comment from "./Comment";
import {helpArticleSendCommentMailThunk} from "../../../store/thunks/help-article-thunk";
import {useLang} from "../../../context/langProvider";

const HelpArticleModal = React.memo(() => {
    const help_article_modal = useSelector(helpArticleModalSelected);
    const send_comment_mail = useSelector(helpArticleSendCommentMailSelected)
    const dispatch = useDispatch();
    const {getT} = useLang()
    const onClose = () => {
        dispatch(helpArticleGetByIdToggleModalAC({
            is_open: false,
            content: null,
            isError: null,
            error: null,
            isLoading: false
        }));
    };
    const handlerSubmit = async (value, resetFieldCb) => {
        let text = `Комментрарий пользователя к статье №. ${help_article_modal.content.id}. 
        ${value}
        `
        let res = await dispatch(helpArticleSendCommentMailThunk(text))
        if (res && resetFieldCb) resetFieldCb()


    }
    if (help_article_modal.isLoading || send_comment_mail.isLoading) return <Loader/>;
    if (!help_article_modal.is_open) return null;

    return (
        <Modal title={help_article_modal?.content?.title} onClose={onClose} open={help_article_modal.is_open}>
            <div className="modal-body help-article-modal">
                <div className="">
                    {parse(`${help_article_modal?.content?.description}`)}
                    {help_article_modal.content.url && <a href={help_article_modal.content.url} target={"_blank"} className="">{getT("Открыть статью")}</a>}
                </div>


                {(help_article_modal.content?.related_articles && Array.isArray(help_article_modal.content?.related_articles) && !!help_article_modal.content?.related_articles?.length)
                    && <div className={"my-3 border-bottom border-top py-3"}>
                        <label className={"comment-accordion-toggle"}>{getT("Связанные статьи")}</label>
                        <ul style={{paddingLeft: '15px'}}>
                            {help_article_modal.content?.related_articles?.map(item => {
                                return <li><a href={item?.url} target={"_blank"}>{item?.title}</a></li>
                            })}
                        </ul>
                    </div>
                }
                <Comment handlerSubmit={handlerSubmit}/>
            </div>

        </Modal>
    );
});

export default HelpArticleModal;