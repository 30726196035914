import {instances} from "../api_axios_settings";
import {URI_API_FORM, URI_API_USER_ADDRESSES} from "../uri_api_action";
import {rejectTransform, resolveTransform} from "../utils_api";
import {IExternalErrorAddress} from "../../types/UserAddressesForms/UserAddressesInputOutput";


export const UserAddressesApi = {
    getUserAddresses: async (clientId: string) => {
        return instances.get(`${URI_API_USER_ADDRESSES}?client_id=${clientId}`)
            .then((response) => resolveTransform(response))
            .catch((error) => rejectTransform(error));
    },
    postUserAddress: async (values: any, clientId: string) => {
        const formData = new FormData();
        Object.keys(values).forEach(key => {
            const value = values[key];
            if (value) {
                formData.append(key, value.toString());
            }
        });
        return instances.post(URI_API_USER_ADDRESSES+"?client_id="+clientId, formData)
            .then((response) => resolveTransform(response))
            .catch((error) => rejectTransform(error));
    },
    putUserAddress: async (values: { [key: string]: string }, clientId: string) => {
        const {id, ...resetV} = values;
        const formData = new FormData();
        Object.keys(resetV).forEach(key => {
            const value = resetV[key];
            if (value) {
                formData.append(key, value.toString());
            }
        });
        return instances.post(URI_API_USER_ADDRESSES + '/update?id=' + id+"&client_id="+clientId, formData)
            .then((response) => response.data)
            .catch((error) => rejectTransform(error));
    },
    deleteUserAddress: async (addressId: number, clientId: string) => {
        const url = URI_API_USER_ADDRESSES + '?id=' + addressId + "&client_id="+clientId;
        return instances.delete(url)
            .then((response) => resolveTransform(response))
            .catch((error) => rejectTransform(error));
    },
    getForms: async () => {
        return instances.get(URI_API_USER_ADDRESSES + '/forms')
            .then((response) => {
                return resolveTransform(response);
            })
            .catch((error) => rejectTransform(error));
    },

    getLookupDirection: async (model: string, filters: { key: string, value: string }[], value: string) => {
        const bodyFormData = new FormData();
        bodyFormData.append("model", model);
        bodyFormData.append("value", value);
        filters.forEach(function (item) {
            bodyFormData.append(`${item.key}`, item.value) // you have to add array symbol after the key name
        })
        return instances.post(URI_API_FORM + '/lookup', bodyFormData)
            .then((response) => {
                return resolveTransform(response);
            })
            .catch((error) => rejectTransform(error));
    },
    postValidator: async (values: any): Promise<{
        errors?: IExternalErrorAddress,
        error?: any
    }> => {
        const formData = new FormData();
        Object.keys(values).forEach(key => {
            const value = values[key];
            if (value) {
                formData.append(key, value.toString());
            }
        });
        return instances.post(URI_API_USER_ADDRESSES + '/validate', formData)
            .then((response) => response.data)
            .catch((error) => rejectTransform(error));
    }

}