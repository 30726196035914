import {changeAlertStatusAC, changeLoaderMessageAC, changeLoaderStatusAC} from "../reducers/CommonReducer";
import {BASE_PATH_URL_PACKAGE_BOX} from "../../components/PackageIframe/package_const";
import {getTranslateMessageAPI} from "../../helpers/helpers";
import {generateDataForPackedWithoutSizes} from "../../helpers/generateDataForPacked";
import {PackedType} from "../../HOOK/useSubscribeIrfamePackage";
import {LS_FIRM} from "../../constants";
import {isAccessEdit} from "../../pages/SettingsFirm/help";
import {changeMaterialsModalValue} from "../reducers/ModalReducer";
import {searchGoodsToggleOpenPageAC} from "../actions/search-goods-action";
import {materialSelectedFilterTogglePageAC} from "../actions/material-selected-filter-actions";

/**
 *  отображать версию расширенного поиска.
 *  IS_VISIBLE_SEARCH_GOODS_PAGE = 1 -> extended version; 0 -> short version
 *
 * @method {controlMaterialGoodsSearchVersionPage} - with IS_VISIBLE_SEARCH_GOODS_PAGE display need  version page
 * @method {openMaterialSearchPageThunk} - open material/goods page
 */
const IS_VISIBLE_SEARCH_GOODS_PAGE = 1;
export const controlMaterialGoodsSearchVersionPage = (isOpen) => dispatch => {
    if (!!Number(IS_VISIBLE_SEARCH_GOODS_PAGE)) {
        dispatch(searchGoodsToggleOpenPageAC(isOpen))
        return;
    }
    dispatch(materialSelectedFilterTogglePageAC({isOpen: isOpen}));
}

export const openMaterialSearchPageThunk = (isOpen) => dispatch => {
    let firm = localStorage.getItem(LS_FIRM);
    if (isAccessEdit(firm)) {
        dispatch(changeMaterialsModalValue('isOpen', isOpen));
        return
    }
    dispatch(controlMaterialGoodsSearchVersionPage(isOpen))

}

/** end material search */

function dispatcherCatch(error, dispatch) {
    dispatch(changeLoaderStatusAC(false));
    let msg = error.hasOwnProperty('error') ? error.error : error.message;
    dispatch(changeAlertStatusAC(true, msg, false));
}

export const dispatcherErrorThunk = (error) => async dispatch => {
    dispatcherCatch(error, dispatch)
}


// let FLAG_IS_LOAD_IFRAME = false;
export async function generationOfPackagingDispatched(order, dispatch) {
    try {
        let response_post_message = null
        let is_valid_link = await fetch(BASE_PATH_URL_PACKAGE_BOX);
        if (!is_valid_link.ok) {
            throw new Error(`${getTranslateMessageAPI('Произошла ошибка при создании упаковки!')}  Not valid link`);
        }
        dispatch(changeLoaderStatusAC(true));
        dispatch(changeLoaderMessageAC(getTranslateMessageAPI('Генерируем данные упаковки...')));
        const getMessageFromApi = (e) => {
            switch (e.data.type) {
                case PackedType.answerResultData: {
                    if (e.data.mes) {
                        response_post_message = JSON.parse(e.data.mes);
                    }
                    break
                }
                default: {
                }
            }


            return false;

        }
        window.addEventListener("message", getMessageFromApi);
        let data = await generateDataForPackedWithoutSizes({
                order: order,
                settingsProduction: order.production_constants
            }
        );
        const iframe = document.createElement('iframe');
        iframe.style.display = 'none'; // Hide the iframe
        iframe.src = BASE_PATH_URL_PACKAGE_BOX;
        document.body.appendChild(iframe);

        await new Promise((resolve, reject) => {
            iframe.onload = () => {
                iframe.contentWindow.postMessage({
                    'mes': JSON.stringify(data),
                    'type': PackedType.sendDataNoPaint
                }, '*');
                resolve()
            };
            iframe.onerror = () => {
                reject({message: `${getTranslateMessageAPI('Произошла ошибка при создании упаковки!')}  Not valid link`})
            };
        });
        if (data?.part?.length && data?.product?.length) {
            return new Promise((resolve, reject) => {
                dispatch(changeLoaderMessageAC(getTranslateMessageAPI('Генерируем данные упаковки...')));
                let count_timer = 0;
                const interval = setInterval(() => {
                    count_timer++;
                    let box3d_result = response_post_message
                    let result = box3d_result ? Object.keys(box3d_result)?.length : 0;
                    // Check the main condition
                    // return resolve({})
                    if (result && count_timer <= 100) {
                        clearInterval(interval);
                        resolve(box3d_result);
                        localStorage.removeItem('box3d_result')
                        dispatch(changeLoaderStatusAC(false));
                    }

                    // If the counter reaches 5 without meeting the condition, reject the promise
                    if (count_timer > 100) {
                        clearInterval(interval);
                        dispatch(changeLoaderStatusAC(false));
                        dispatch(changeAlertStatusAC(true, `${getTranslateMessageAPI('Произошла ошибка при создании упаковки!')}`));
                        resolve(null)
                        // ({message: "Произошла ошибка при создании упаковки!"});
                    }
                }, 1000);

                // response_package = await sendMessageAsyncToApi(JSON.stringify(data), PackedType.sendDataNoPaint);
            })
        } else {
            dispatch(changeLoaderStatusAC(false));
            return Promise.resolve(null)
        }
    } catch (e) {
        let msg = e?.message;
        dispatch(changeLoaderStatusAC(false));
        dispatch(changeAlertStatusAC(true, msg));
    }
}

