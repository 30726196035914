import {UserAddressFormFieldConfigType} from "../../../types/UserAddressesForms/FormFieldTypes";
import {useFormik} from "formik";
import {IUserAddressesFormSendRequestResult} from "../../../types/UserAddressesForms/UserAddressesInputOutput";
import useUserAddressFormBuilder from "./useUserAddressFormBuilder";
import {utilsTypeof} from "../../../helpers/utils-typeof";

interface ICustomAddressesForm {
    typeDeliveryForm: UserAddressFormFieldConfigType,
    sendRequest: (values: { [key: string]: any }) => Promise<IUserAddressesFormSendRequestResult>
}

function useCustomAddressesForm({typeDeliveryForm, sendRequest}: ICustomAddressesForm) {
    const {fields, initialValues} = useUserAddressFormBuilder(typeDeliveryForm);
    const formik = useFormik<{ [key: string]: any }>({
        initialValues: initialValues,
        enableReinitialize: true,
        onSubmit: async (values, {setErrors, resetForm, setSubmitting}) => {
            try {
                let dto = {...values}

                const result = await sendRequest(dto)
                if (result.isOk) {
                    resetForm({
                        values: initialValues,
                        errors: {}
                    });
                }

                if (utilsTypeof.isObject(result.errors) && result.errors !== null) {
                    setErrors(result.errors)
                }

            } finally {
                setSubmitting(false);
            }


        }
    });
    return {formik, fields}

}

export default useCustomAddressesForm;