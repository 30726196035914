export const getRuleSettingsProject = state => state.commons?.ruleSettingsProject;

export const getCurrencies = state=> state?.commons?.currencies || [];

export const getUnits = state=> state?.commons?.units || [];
export const getDrop = state=> state?.commons?.drops || [];

export const getCommonsStores = state=> state?.commons?.stores || [];
export const getCommonsRegions = state=> state?.commons?.regions || [];

export const getLang = state => state?.translation?.localLang;
export const getCollectionWordLang = state => state?.translation?.collectionWord || [];
export const getErrorServiceParts = (state) => state?.commons?.errors_service?.parts;
// export const getSelectedMaterialOfDetailing = state => state?.commons?.selectedMaterialGoodsIdOfDetailing;

export const isAllowRequestCheckProject = state=> state?.commons?.isAllowRequestCheckProject ?? true;
export const getExportLibraryMaterial = state=> state?.commons?.materialExport || null

export const  getProjectVersionCode = state =>{
    const version = state.commons.data.version;
    const code = state.commons.data.code;

    return {version, code}
}
export const getNewsSelected = state=> state?.commons?.news;
export const getAlertsProject = state=> state?.commons?.alertsProject;
