import {utilsTypeof} from "../utils-typeof";

const {v4: uuidv4} = require('uuid');


export const getUiIdForPart = () => {
    return {
        uuid: uuidv4()
    }
}


export const filteredPartsByMaterialId = (parts, materialId) => {
    if (!Array.isArray(parts)) return []
    if (!utilsTypeof.isValidNumber(materialId)) return parts;

    return parts.filter(part => Number(part.material) === Number(materialId))
}