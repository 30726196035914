import {
    isAddressDropdownField,
    isAddressLookupField,
    IUserAddress
} from "../../../types/UserAddressesForms/FormFieldTypes";
import {useMemo} from "react";

export interface IUserAddressesMapper {
    addresses: IUserAddress[] | null
}

export interface IUserAddressMapView {
    id: number;
    plugin: string;
    default: number;
    name: string;
    address: {label: string; value: string}[]

}

type Result = {
    addressesMapView: IUserAddressMapView[] | null
}
export default function useUserAddressesMapper({addresses}: IUserAddressesMapper): Result {

    const addressesMapView = useMemo(() => {
        if (!addresses || !addresses.length || !Array.isArray(addresses)) return null;

        return addresses.map((curr) => {
            const form = curr.form;
            let mappedAddress: IUserAddressMapView = {
                id: curr.id,
                name: curr.name,
                plugin: curr.plugin,
                default: curr.default,
                address: []
            };
            let entries = []

            for (let i = 0; i < form.length; i++) {
                let item = form[i];
                let value = item.value
                if (item.value) {
                    if(isAddressDropdownField(item) || isAddressLookupField(item)) {
                        const findValue = item.options.option?.[item.value];
                        value = findValue;
                    }

                    entries.push({label: item.label, value: value})
                }
            }

            return {
                ...mappedAddress,
                address: entries,
            };
        });
    }, [addresses]);

    return {addressesMapView: addressesMapView}
}