import {mappingPartialPart} from "./transformPartialsProject/mappingPartialPart";
import {mappingPartialMaterial} from "./transformPartialsProject/mappingPartialMaterial";
import {mappingPartialPlaneOperaion} from "./transformPartialsProject/mappingPartialPlaneOperaion";
import {utilsTypeof} from "../../helpers/utils-typeof";




export const transformInitProject = (data, processing_edge_point) => {
    const checkIsArrayAndReturn = (value) => {
        // Возвращает переданное значение, если оно массив, иначе возвращает пустой массив
        return Array.isArray(value) ? value : [];
    }
    try {
        const help = data?.help || null;
        const productions = utilsTypeof.checkObject(data?.production_constants) ? data.production_constants : {};
        const materials = Array.isArray(data.material) ? data.material : [];

        const bands_material = materials
            ?.filter(item => !!Number(item.band_only_connected) && Array.isArray(item?.connected_band))
            ?.map(item => item.connected_band)
            ?.flat();

        const un_bands = bands_material?.reduce((acc, item) => {
            let is_has_band = data?.band.find(band => Number(band.goods_id) === Number(item.goods_id));
            if (!is_has_band) {
                acc.push(item)
            }
            return acc
        }, [])?.flat()?.filter(item => !!item);

        const band = data?.band && Array.isArray(data.band) ? [...data.band, ...un_bands] : un_bands;
        return {
            help: help,
            goods_service_added_part_firm: checkIsArrayAndReturn(data?.goods_service_added_part_firm),
            goods_service_added_order_firm: checkIsArrayAndReturn(data?.goods_service_added_order_firm),
            gr:checkIsArrayAndReturn(data?.gr),
            packing: data?.packing ?? null,
            api_error_calc: data?.api_error_calc ?? '',
            firm_contact: data?.firm_contact ?? null,
            firm_name: data?.firm_name,
            user_name: data?.user_name,
            confirm_data: data?.confirm_data || {},
            message_calculate: data?.message_calculate ?? "",
            save_versions: checkIsArrayAndReturn(data?.save_versions),
            versions: checkIsArrayAndReturn(data?.versions),
            client_name: data.hasOwnProperty("client_name") ? data.client_name : "",
            client_phone_code: data?.client_phone_code ? `${data.client_phone_code}`.replace(" ", "") : false,
            client_email: data?.client_email ?? "",
            client_phone: data.hasOwnProperty("client_phone") && data?.client_phone ? `${data.client_phone}`?.replace(/\D/g, "") : "",
            calculate_vals: data.hasOwnProperty("calculate_vals") ? data.calculate_vals : [],
            id: data.id,
            title: data.title ? data.title : "Новый (Untitled) проект",
            lang: data.lang ? data.lang : "ru",
            date: data.date ? data.date : "2021-08-19 15:00:00",
            client: data.client ? data.client : null,
            firm: data.firm ? data.firm : localStorage.getItem("ifurn_service_main_user_firm"),
            currency: data.currency ? data.currency : 2,
            code: data.code ? data.code : null,
            goods: checkIsArrayAndReturn(data?.goods),
            boxes: checkIsArrayAndReturn(data?.boxes),
            cutting_card: data.cutting_card ?? null,
            cutting_card_in: data?.cutting_card_in,
            part: data.part ? data.part.map(partItem => {
                return mappingPartialPart(partItem, band, productions, processing_edge_point)
            }) : [],
            material: materials.map(mappingPartialMaterial),
            band: band,
            bands_type: data.bands_type ? data.bands_type.map(e => {
                if (!e.glue) {
                    e.glue = "transparent";
                }

                return e;
            }) : [],
            product: data.product ? data.product : [],
            extra: data.extra ? data.extra : [],
            plane_operations: data.plane_operations ? data.plane_operations.map(mappingPartialPlaneOperaion) : [],
            production_constants: data?.production_constants ? data.production_constants : {},
            startMessage: data.startMessage ? data.startMessage : true,
            calculate: data.calculate ? data.calculate : [],
            calculate_types: data.calculate_types ? data.calculate_types : [],
            calc: data.calc ? data.calc : null,
            calculate_discounts: {
                material: data.calculate_discounts && data.calculate_discounts.material ? data.calculate_discounts.material : [],
                service:
                    data.calculate_discounts && data.calculate_discounts.service ? data.calculate_discounts.service : [],
                goods:
                    data.calculate_discounts && data.calculate_discounts.goods ? data.calculate_discounts.goods : []
            },
            partSizesType: data.partSizesType ? data.partSizesType : "saw",
            universal_calculate: 1,
            urgent: 0,
            status: data.status
        }
            ;
    } catch (e) {
        console.log(
            'catch get'
        )
        console.log(e)
        throw new Error(e.message);
    }
}


// if(typeof e?.edge === "object") {
//     for (const side in e.edge) {
//         const bandById = band?.find(b => b?.goods_id === e.edge[side]?.db_id);
//         e.edge[side].color = bandById ? bandById?.color : null;
//     }
// }
// if (!e.srez) {
//     e.srez = {
//         t: {
//             otstup: "",
//             ugol: "",
//             db_id: ""
//         },
//         b: {
//             otstup: "",
//             ugol: "",
//             db_id: ""
//         },
//         l: {
//             otstup: "",
//             ugol: "",
//             db_id: ""
//         },
//         r: {
//             otstup: "",
//             ugol: "",
//             db_id: ""
//         }
//     };
// }
// ["t", "b", "l", "t"].forEach(side => {
//     if (Number(e.edge[side].db_id) === -1) {
//         const band = {...e.edge[side]};
//         e.srez[side].ugol = band.radius;
//         e.edge[side].db_id = null;
//         e.edge[side].cut = null;
//         e.edge[side].radius = null;
//     }
// });
//
// if (e?.operations?.bore?.length) {
//     let isOnXncZenk = data?.production_constants?.['production.xnc_bore_zenk_on'] || 0
//     e?.operations?.bore?.forEach(item => {
//         if (!item.hasOwnProperty("start_point")) {
//             item["start_point"] = "ld";
//         }
//         if (['f', 'bb'].includes(item.side) && item.hasOwnProperty('zenkovanie') && !Number(isOnXncZenk)) {
//             item['zenkovanie'] = 'no'
//         }
//     });
// }
//
// if (e.operations) {
//     let bore = e.operations?.bore?.length ? e.operations.bore?.filter(item => (typeof item === "object" && item !== null && !Array.isArray(item)))
//         : [];
//     e.operations = {
//         ...e.operations,
//         bore: bore,
//         hem: e.operations?.hem?.length ? e.operations?.hem?.filter(item => (typeof item === "object" && item !== null && !Array.isArray(item))) : [],
//         cut_to: e.operations?.cut_to?.length ? e.operations?.cut_to?.filter(item => (typeof item === "object" && item !== null && !Array.isArray(item))) : [],
//         frez: e.operations?.frez?.length ? e.operations?.frez?.filter(item => (typeof item === "object" && item !== null && !Array.isArray(item))) : [],
//         trough: e.operations?.trough?.length ? e.operations?.trough?.filter(item => (typeof item === "object" && item !== null && !Array.isArray(item))) : [],
//         paz: e.operations?.paz?.length ? e.operations?.paz?.filter(item => (typeof item === "object" && item !== null && !Array.isArray(item)))
//             : [],
//
//     }
// }
// let payload_edge_edit = initialProcessingEdgeEditProjectForPart(e, data.production_constants, processing_edge_point)
// let uuid = {}
// if (!e?.hasOwnProperty('uuid') || !(typeof e?.uuid === "string")) {
//     uuid = getUiIdForPart()
// }
//
// return {
//     ...e, ...payload_edge_edit,
//     ...uuid
// };


// material: data.material ? data.material.filter(e => {
//     if (e) {
//         if (!e.hasOwnProperty("additional") || Array.isArray(e?.additional)) {
//             e.additional = {
//                 trimL: "",
//                 trimW: "",
//                 rez_napr: "",
//                 active: 1,
//                 turn: "",
//                 hard_cut: ""
//             };
//         } else {
//             e.additional = {
//                 ...e.additional,
//                 active: e.additional?.hasOwnProperty("active") ? e.additional.active : 1
//             };
//         }
//
//         if (!e.z) {
//             e.z = 16;
//             e.haveError = true;
//         }
//
//         e.band_only_connected = e?.hasOwnProperty("band_only_connected") ? e?.band_only_connected : 0;
//         return e;
//     }
//
// }) : [],



// return {
//     ...e,
//     id: e.id,
//     add_to_detail: e?.add_to_detail ?? null,
//     name: e.name ? e.name : "Название не указано",
//     code_1c: e.code_1c ? e.code_1c : 0,
//     layer_z: e.layer_z ? e.layer_z : 0,
//     expense: e.expense ? e.expense : 0,
//     unit: e.unit ? e.unit : 0,
//     type: e.type ? e.type : "",
//     type_name: e.type_name ? e.type_name : "Название не указано",
//     image: e.image ? e.image : "",
// };