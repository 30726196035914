import {IPazTemplateGrType} from "../../../types/order/paz-template-gr-type";

/*
*  Фильтрация чпу операции для шаблона позоавания
*  внутри part_giblab происходит фильтрация,
* где исключаются элементы, чьи id совпадают с любым элементом из partIds.
* **/


export const pazTemplateCleanByPartIdMapping = (partIds: number[] | string[], grTemplate: IPazTemplateGrType[] | null) => {
    // Проверка на корректность grTemplate
    if (!Array.isArray(grTemplate)) {
        console.info("grTemplate must be an array");
        return []
    }
    // Проверка на корректность partIds
    if (!Array.isArray(partIds)) {
        console.info("Part id must be an array");
        return grTemplate
    }
    // Если нет элементов для очистки, возвращаем оригинальный массив
    if (!partIds.length) {
        console.info("partIds not has id for clean gr");
        return grTemplate;
    }

    // Очищаем part_giblab, фильтруя элементы с id в partIds
    return grTemplate.map(item => {

        const partGiblab = item.part_giblab.filter(part => {
            // Проверяем, если part.id в partIds, то исключаем этот элемент
            return !partIds.some((id: string | number) => Number(id) === Number(part.id))

        });
      
        return {
            ...item,
            part_giblab: partGiblab
        }
    });


}

