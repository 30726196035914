import {FrezeMakeApi} from "../../api/Frez/FrezeMakeApi";
import {dispatcherErrorThunk} from "./common-thunk";
import {modalToggleFrezErrorsProjectModalAc} from "../actions/modal-action";
import {changeLoaderStatusAC, getCheckProjectErrorsThunk} from "../reducers/CommonReducer";
import ApiService from "../../api/api";
import {utilsTypeof} from "../../helpers/utils-typeof";

const apiService = new ApiService();
type ErrorsProjectManagerType = "common-error" | "part-common-error" | "frez-error" | "part-frez-error";
type ErrorsProjectArgs = {
    type: ErrorsProjectManagerType;
    partId?: string;
    part?: any
}
export const getErrorsProjectManagerThunk = ({type, partId, part}: ErrorsProjectArgs) => async (dispatch: any) => {
    try {
        if (type === "part-common-error") {
            if (!utilsTypeof.checkObject(part)) {
                throw new Error("part should be object");

            }
            //@ts-ignore
            dispatch(getCheckProjectErrorsThunk(true, true, [part]))
            return;
        }
        if (type === "common-error") {
            dispatch(getCheckProjectErrorsThunk(true, true));
            return;
        }
        if (type === "frez-error") {
            dispatch(getFrezErrorsProjectThunk());
            return;
        }
        if (type === "part-frez-error") {
            if (!utilsTypeof.isValidNumber(partId)) {
                throw new Error("PartId  should be number");

            }
            //@ts-ignore
            dispatch(getFrezErrorsForPartThunk(partId))
            return;
        }
    } catch (err) {
        dispatch(dispatcherErrorThunk(err));
    }
}


export const getFrezErrorsProjectThunk = () => async (dispatch: any, getState: any) => {
    const version = getState().commons.data.version;
    const order = getState().order.order;
    try {
        dispatch(changeLoaderStatusAC(true));
        await apiService.saveProject(version, order);
        if (apiService._errors) {
            dispatch(dispatcherErrorThunk({error: apiService._getErrors()}))
            return;
        }
        const frezMakeCheckResult = await FrezeMakeApi.checkAll(order.id);
        if (frezMakeCheckResult?.error) {
            dispatch(dispatcherErrorThunk({error: frezMakeCheckResult.error}));
            return
        }
        if (frezMakeCheckResult.status === "error") {
            const errors = frezMakeCheckResult.res;
            dispatch(modalToggleFrezErrorsProjectModalAc(true, errors));
            dispatch(changeLoaderStatusAC(false));
            return;
        }

        dispatch(modalToggleFrezErrorsProjectModalAc(false, null));
        dispatch(changeLoaderStatusAC(false));

    } catch (err) {
        dispatch(dispatcherErrorThunk(err));
    }
}

export const getFrezErrorsForPartThunk = (partId: number) => async (dispatch: any, getState: any) => {
    const store = getState();
    const version = store.commons.data.version;
    const order = store.order.order;
    try {
        dispatch(changeLoaderStatusAC(true));
        await apiService.saveProject(version, order);

        if (apiService._errors) {
            dispatch(dispatcherErrorThunk({error: apiService._getErrors()}))
            return;
        }
        const frezMakeCheckResult = await FrezeMakeApi.checkByPartId(order.id, partId);

        if (frezMakeCheckResult?.error) {
            dispatch(dispatcherErrorThunk({error: frezMakeCheckResult.error}));
            return
        }
        if (frezMakeCheckResult.status === "error") {
            const errors = frezMakeCheckResult.res;
            dispatch(modalToggleFrezErrorsProjectModalAc(true, errors));
            dispatch(changeLoaderStatusAC(false));
            return;
        }

        dispatch(modalToggleFrezErrorsProjectModalAc(false, null));
        dispatch(changeLoaderStatusAC(false));

    } catch (err) {
        dispatch(dispatcherErrorThunk(err));
    }
}